import React from 'react';
import { LinkIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Spinner } from '@chakra-ui/react';

export interface Transaction {
    description: string;
    hash?: string;
    status: string;
    tx?: any;
    item?: any;
}

interface TransactionTableProps {
    transactions: Transaction[];
    isLoading?: boolean;
    onRetry?: (tx: Transaction) => void;
}

const TransactionTable: React.FC<TransactionTableProps> = ({
    transactions,
    isLoading,
    onRetry,
}) => {
    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'success':
                return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
            case 'failed':
                return <XCircleIcon className="h-5 w-5 text-red-500" />;
            case 'broadcast':
            case 'pending':
                return <Spinner size="sm" className="text-blue-500" />;
            default:
                return null;
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'success':
                return 'Success';
            case 'failed':
                return 'Failed';
            case 'broadcast':
                return 'Processing';
            case 'pending':
                return 'Pending';
            default:
                return status;
        }
    };

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'success':
                return 'text-green-500 dark:text-green-400';
            case 'failed':
                return 'text-red-500 dark:text-red-400';
            case 'broadcast':
            case 'pending':
                return 'text-blue-500 dark:text-blue-400';
            default:
                return 'text-gray-500 dark:text-gray-400';
        }
    };

    if (isLoading) {
        return (
            <div className="animate-pulse">
                <div className="space-y-3">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="h-12 bg-gray-200 dark:bg-gray-700 rounded-lg" />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                    <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Action
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Transaction
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {transactions.map((tx, index) => (
                        <tr key={tx.hash || index} className="hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors">
                            <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-100">
                                {tx.description}
                            </td>
                            <td className="px-4 py-3 text-sm">
                                {tx.hash ? (
                                    <a
                                        href={`https://solscan.io/tx/${tx.hash}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center space-x-1 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
                                    >
                                        <span>{tx.hash.slice(0, 4)}...{tx.hash.slice(-4)}</span>
                                        <LinkIcon className="h-4 w-4" />
                                    </a>
                                ) : (
                                    <span className="text-gray-500 dark:text-gray-400">Pending...</span>
                                )}
                            </td>
                            <td className="px-4 py-3 text-sm">
                                <div className="flex items-center space-x-2">
                                    {getStatusIcon(tx.status)}
                                    <span className={getStatusClass(tx.status)}>
                                        {getStatusText(tx.status)}
                                    </span>
                                    {tx.status === 'failed' && onRetry && (
                                        <button
                                            onClick={() => onRetry(tx)}
                                            className="ml-2 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 text-sm font-medium"
                                        >
                                            Retry
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionTable; 