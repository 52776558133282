import { useToast } from "@chakra-ui/react";
import { DateTime, DurationObjectUnits } from "luxon";
import { collection, doc, query, where } from "firebase/firestore";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";
import Firebase from "../services/firebase";
import {
  getNftsAvailableForBid,
  getNftsAvailableToList,
} from "../services/nfts.service";

import {
  getPassed,
  getPreferredItemsInRoom,
  getRoomSwapState,
  getViewed,
  joinRoom,
  saveProgress,
  setPassed,
  setViewed,
  timeInfoFromServer,
} from "../services/room.service";
import { filterCompleted, getTokenInfo } from "../utils";
import { Email, Twitter, Discord, useAppContext } from "./appContext";
import { Chain, useChain } from "./chainsContext";
import { useUA } from "./userTracking";

type MarketPrice = {
  marketplace_id: string;
  marketplace_name: string;
  value: number;
  payment_token: {
    payment_token_id: string;
    name: string;
    symbol: string;
    address: string;
    decimals: number;
  };
  valueConverted: number;
  message: string;
  decimals: number;
  symbol: string;
};

type Trait = {
  trait_type: string;
  value: string;
};

export type Lock = {
  lockedRoom_id: string;
  lockedQuantity: number;
  lockedReason: string;
  lockedSwapId: string;
};

export type Nft = {
  reservePrice: any;
  address: string;
  description: string;
  userDescription: string;
  image: string;
  imageOriginal: string;
  assetType: string;
  itemId: string;
  marketplace: {
    name: string;
    url: string;
  };
  missingData: boolean;
  name: string;
  thumbnail: string;
  attributes: Trait[];
  locks: Lock[];
  blockchain: string;
  royalty: {
    rate: number;
  }
  collection: Collection;
};

export type Collection = {
  image: string;
  twitter?: string;
  collectionId: string;
  name: string;
  volume: number;
  totalItems: string;
  attributes: string;
  owners: string;
  floorPrice: {
    decimals: number;
    floorPrice: MarketPrice & { message: string };
    allPrices: MarketPrice[];
  };
  verified: {
    marketplaceVerified: {
      verified: boolean; // Fixed to boolean type instead of false for general use
    };
    isSpam: boolean;
    spamScore: null | number; // Assuming spamScore can be a number or null
  };
  stats?: CollectionStats;
  metaplexMint?: string;
};

export type CollectionStats = {
  buy: string;
  collectionId: string;
  floor1h: number
  floor7d: number
  floor24h: number
  id: string;
  name: string;
  numListed: number
  numMints: number
  sales1h: number
  sales7d: number
  sales24h: number
  salesAll: number
  sell: string
  slug: string
  volume1h: string
  volume7d: string
  volume24h: string
  volumeAll: string
};

export type BiddableNft = Nft & {
  hasViewed?: boolean;
  reservePrice?: { value: number; symbol: string; decimals: number };
  startingBid?: number;
};

export type Room = {
  createdAt: any;
  gifUrl?: string | undefined;
  info: {
    isOrdinals: any;
    adminId: string;
    name: string;
    roomType: string;
    description: string;
    startAt: number;
    incrementToken: number;
    incrementPct: number;
    listingSeconds?: number;
    biddingSeconds?: number;
    markupPercent: number;
    minReservePrice: number;
    maxReservePrice: number;
    maxUsers: number;
    numNftsToList: number;
    chain: string;
    suggestedPrice: string;
    partyDuration?: number;
    allowedCollections: string[];
    accessPolicy: {
      policy: string;
      collections: string[];
    };
    isTicketGated: boolean;
    itemsPreference: {
      preferAdmin: boolean;
      preferredItems: string[];
      preferredUsers: string[];
    };
    secretReserve: boolean;
    token?: string;
  };
  status: string;
  blockchain: {
    name: string;
  };
  users: string[];
  tradeFound: boolean;
  lockForSolver: boolean;
  swapId?: {
    swapId: string;
  };
  userSwaps: { [uid: string]: any };
  serverTime: number;
  secondsToEnd: number;
  secondsToPreview: number;
};

type Results = {
  [userId: string]: { get: string[]; give: string[]; token: number };
};

type Result = {
  admin: { adminId: string; adminRevenuePct: number };
  gifData: {
    nodes: any;
    edges: any;
  };
  gifUrl: string;
  results: Results;
  revenue: {
    neoswap: number;
    [key: string]: number;
  };
  swapId: {
    swapId: string;
    blockchain: string;
  };
  tradeFound: boolean;
};

type JoinRoomRes = {
  data: {
    result: string;
    allowed: boolean;
    joined: boolean;
    detail: string;
  };
};

type Swap = {
  contractName: string;
  blockchain: string;
  programId: string;
  results: Results;
  roomId: string;
  solutionId: string;
  state: string;
  swapData: string;
  swapDataAccount: string;
  swapDataAccount_bump: number;
  swapDataAccount_preSeed: string;
  swapDataAccount_seed: string;
  tokenProgramId: string;
  txInitialize: {
    txInit: string;
    txValidate: string;
    txsAdded: string[];
  };
  users: string[];
  usersPaying: string[];
};

type Progress = {
  [key: string]: boolean;
};

export type HighestBids = {
  [nftId: string]: { [userId: string]: number };
};

export type Listings = {
  [key: string]: {
    reservePrice: number;
    listingPrice: number;
    startingBid: number;
    userDescription?: string;
  };
};

type Bids = {
  [key: string]: {
    bidPrice: number;
    timestamp: number;
  };
};

type Viewed = string[];

type Passed = string[];

enum ActionType {
  UPDATE,
  RESET,
}

interface Props {
  children?: ReactNode;
  isPreview?: boolean;
}

export type Budget = {
  budget: number;
  numItemsLimitForAsks: number;
  numItemsLimitForBids: number;
  revenueLimitForAsks: number;
  expenseLimitForBids: number;
  skipLandingPage: boolean;
};


interface CanBid {
  canBid: boolean;
  errorTitle: string;
  reason: string;
}

interface State extends Chain {
  units: number;
  abbr: string;
  initializing: boolean;
  room: Room;
  result?: Result;
  swap?: Swap;
  roomId: string;
  isAdmin: boolean;
  canBid: CanBid;
  connected: Progress;
  stepProgress: Progress;
  listings: Listings;
  bids: Bids;
  roomState: string;
  budgets: Budget;
  availableToList: Nft[];
  availableForBid: BiddableNft[];
  nftsLoading: boolean;
  biddingDiff: boolean;
  canDeposit: boolean;
  canCancel: boolean;
  modalId?: string;
  preferredIds: string[];
  availableForBidLoading: boolean;
  viewed: Viewed;
  passed: Passed;
  swapState?: {
    message: string;
    swapStates: { deployed: number };
    userStatus: {
      blockchain: string;
      swapId: string;
      userState: string;
      swapState: string;
      user: string;
    }[];
  };
  timeDiff: number;
}

interface RoomsInterface extends State {
  getAvailableToList: () => void;
  getAvailableForBid: () => void;
  setModalId: (id?: string) => void;
  roomTimeInfo: () => void;
  handleNext: (set: any) => void;
  handlePrev: (set: any) => void;
  canList: boolean;
  isSilentAuction: boolean;
  handleInView: (id: string) => void;
  handlePass: (id: string) => void;
  highestBids: HighestBids;
  getSwapState: () => Promise<void>;
  removePass: (id: string) => void;
  preview: Result;
  showContactModal: boolean;
  setShowContactModal: (show: boolean) => void;
  isPassing: boolean;
  setIsPassing: (isPassingValue: boolean) => void;
}

type Action =
  | { type: ActionType.UPDATE; payload: any }
  | { type: ActionType.RESET; payload: any };

const Room = createContext<RoomsInterface>({} as RoomsInterface);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE:
      return { ...state, ...action.payload };
    case ActionType.RESET:
      return {} as State;
    default:
      return state;
  }
};

export const createPlaceLabel = (place: number, items: number) => {
  if (place > items) return "Out of running";
  if (place === 1) {
    return "Currently 1st";
  }
  if (place === 2) {
    return "Currently 2nd";
  }
  if (place === 3) {
    return "Currently 3rd";
  }
  return `Currently ${place}th`;
};

export const getHighestBid = (
  highestBids: HighestBids,
  nftId?: string,
  uid?: string
) => {
  const highestBidsForNft = highestBids?.[nftId || ""] || {};
  const userBid = highestBidsForNft?.[uid || ""];
  const bidVals = Object.values(highestBidsForNft).sort((a, b) => b - a);
  const place = bidVals.indexOf(userBid) + 1;
  const highestBid = bidVals.reduce((acc: number[], ele: number) => {
    const max = Math.max(...acc);
    if (ele > max) {
      return [ele];
    }
    if (ele === max) {
      return [...acc, ele];
    }
    return acc;
  }, []);

  const highestBidAmount = Math.max(...highestBid);
  const isHighBidder = highestBidAmount === userBid;
  const isTied = isHighBidder && highestBid.length > 1;

  return { isHighBidder, highestBidAmount, isTied, place };
};

type genericObject = {
  [key: string]: boolean;
};

const toObject = (arr: string[]) => {
  return arr.reduce((acc: genericObject, ele: string) => {
    acc[ele as keyof genericObject] = true;
    return acc;
  }, {});
};

const omit = (obj: any, prop: string) => {
  let { [prop]: omit, ...res } = obj;
  return res;
};

export default function RoomProvider({ children, isPreview }: Props) {
  const prevRoomRef = useRef<Room>();
  const { gaJoinParty } = useUA();
  const toast = useToast();
  const { uid, updateState, tokenBalances } = useAppContext();
  const { roomId } = useParams();
  const { getChainDetails } = useChain();
  const isMountedRef = useRef(false);
  const [state, dispatch] = useReducer(reducer, {
    passed: [],
    viewed: [],
    roomId,
    initializing: true,
    timeDiff: 0,
    isPassing: false,
  } as Partial<State>);

  const returnProgress = (status: string) => {
    if (status === "listing") return "listings";
    if (status === "bidding") return "bidding";
    if (status === "finalize") return "deposit";
    return "not-found";
  };

  const setIsPassing = (isPassingValue: boolean) => {
    dispatch({
      type: ActionType.UPDATE,
      payload: { isPassing: isPassingValue }
    });
  };

  const db = Firebase.getDBApp();
  const [data] = useDocument(doc(db, "rooms", roomId || "not_found"));

  const [previewData] = useDocument(
    doc(db, "rooms", roomId || "not_found", "solutionsPreview", "0")
  );

  const preview = useMemo(() => {
    return previewData?.data() as Result;
  }, [previewData]);

  const [resultData] = useDocument(
    doc(db, "rooms", roomId || "not_found", "solutions", "0")
  );

  const room = useMemo(() => {
    const fbRoom = data?.data() as Room;
    prevRoomRef.current = fbRoom;
    if (fbRoom?.info?.token) {
      updateState({ activeChainBalance: fbRoom.info.token });
    }
    return fbRoom;
  }, [data]);

  useEffect(() => {
    // Reset display to native chain balance
    return () => {
      updateState({ activeChainBalance: "native" });
    };
  }, []);

  const chain = useMemo(() => {
    if (!room || !tokenBalances) return {};
    if (!room?.info?.token) return getChainDetails(room?.blockchain?.name);
    return getTokenInfo(tokenBalances, room.info.token);
  }, [room, tokenBalances]);

  const [biddingProgressData] = useDocument(
    doc(db, "rooms", roomId!, "progress", "bidding")
  );
  const [stepProgressData] = useDocument(
    doc(db, "rooms", roomId!, "progress", returnProgress(room?.status))
  );

  const stepProgress = useMemo(() => {
    return stepProgressData?.data() as Progress;
  }, [stepProgressData]);

  const biddingProgress = useMemo(() => {
    return biddingProgressData?.data() as Progress;
  }, [biddingProgressData]);

  const [connectedData] = useDocument(
    doc(db, "rooms", roomId!, "progress", "connected")
  );

  const connected = useMemo(() => {
    return connectedData?.data() as Progress;
  }, [connectedData]);

  const result = useMemo(() => {
    return resultData?.data() as Result;
  }, [resultData]);

  const [swapData] = useDocument(
    doc(
      db,
      "blockchain",
      room?.blockchain?.name?.toLowerCase() || "not_found",
      "swaps",
      result?.swapId?.swapId || "not_found"
    )
  );

  const [bidsData] = useDocument(
    doc(db, "rooms", roomId!, "bids", uid || "not_found")
  );

  const [listingsData] = useDocument(
    doc(db, "rooms", roomId!, "listings", uid || "not_found")
  );

  const listings = useMemo(() => {
    return listingsData?.data() as Listings;
  }, [listingsData]);

  const swap = useMemo(() => {
    return swapData?.data() as Swap;
  }, [swapData]);

  const bids = useMemo(() => {
    return bidsData?.data() as Bids;
  }, [bidsData]);

  const [budgetData] = useDocument(
    doc(db, "rooms", roomId!, "budget", uid || "not_found")
  );

  const [depositedData] = useDocument(
    doc(db, "rooms", roomId!, "progress", "deposit")
  );

  const deposited = useMemo(() => {
    return depositedData?.data() as Progress;
  }, [depositedData]);

  const [cancelledData] = useDocument(
    doc(db, "rooms", roomId!, "progress", "cancel")
  );

  const canceled = useMemo(() => {
    return cancelledData?.data() as Progress;
  }, [cancelledData]);

  const [highestBidsData] = useCollection(
    collection(db, "rooms", roomId!, "bids")
  );

  const highestBids = useMemo(() => {
    const highestBidsStruct: HighestBids = {};
    highestBidsData?.docs.forEach((doc) => {
      const userId = doc.id;
      const bids = doc.data() as Bids;

      Object.keys(bids).forEach((nftId) => {
        const bidAmount = bids?.[nftId].bidPrice;
        highestBidsStruct[nftId] = {
          ...(highestBidsStruct?.[nftId] || {}),
          [userId]: bidAmount,
        };
      });
    });

    return highestBidsStruct;
  }, [highestBidsData]);

  const inTrade = swap?.usersPaying?.includes(uid!);

  const canDeposit =
    swap?.state === "deployed" && !deposited?.[uid!] && inTrade;

  const canCancel = swap?.state === "deployed" && !canceled?.[uid!] && inTrade;

  const [partyParticipantListingsData] = useCollection(
    query(
      collection(db, "rooms", roomId!, "listings"),
      where("__name__", "!=", uid || "not_found")
    )
  );

  const partyParticipantListings = useMemo(() => {
    const docs =
      (partyParticipantListingsData?.docs?.reduce((acc, doc) => {
        return { ...acc, ...doc.data() };
      }, {}) as Listings) || {};

    // Moving the dispatch out of useMemo to prevent setState during render
    return docs;
  }, [partyParticipantListingsData]);

  // Handle the dispatch in a useEffect instead
  useEffect(() => {
    if (!state?.availableForBid) return;

    dispatch({
      type: ActionType.UPDATE,
      payload: {
        availableForBid: state?.availableForBid?.map((ele: BiddableNft) => {
          const val = partyParticipantListings?.[ele?.itemId];
          if (val) {
            return {
              ...ele,
              reservePrice: {
                ...ele?.reservePrice,
                value: val.reservePrice,
              },
            };
          }
          return ele;
        }),
      },
    });
  }, [partyParticipantListings, state?.availableForBid]);

  useEffect(() => {
    if (!room) return;
    let hasDiff = false;
    if (
      state?.availableForBid?.filter(
        (ele: BiddableNft) => !partyParticipantListings[ele.itemId]
      ).length > 0
    ) {
      hasDiff = true;
    }
    for (const key in partyParticipantListings) {
      const found = state.availableForBid?.find((ele: BiddableNft) => {
        return ele.itemId === key;
      });

      if (!found) {
        hasDiff = true;
        break;
      }

      let listPrice = partyParticipantListings[key].reservePrice;

      if (found?.reservePrice?.originalListPrice !== listPrice) {
        hasDiff = true;
        break;
      }
    }
    dispatch({
      type: ActionType.UPDATE,
      payload: {
        biddingDiff: hasDiff,
      },
    });
  }, [
    partyParticipantListings,
    state?.availableForBid,
    room?.info?.markupPercent,
  ]);

  const budgets = useMemo(() => {
    return budgetData?.data();
  }, [budgetData]);

  const getAvailableToList = async () => {
    dispatch({
      type: ActionType.UPDATE,
      payload: { nftsLoading: true },
    });
    try {
      const res = await getNftsAvailableToList(roomId!, uid!);
      dispatch({
        type: ActionType.UPDATE,
        payload: { availableToList: res.data },
      });
    } catch (e) {
      console.log("Err getAvailbleToList", e);
    } finally {
      dispatch({
        type: ActionType.UPDATE,
        payload: { nftsLoading: false },
      });
    }
  };

  const getPreferredIds = async () => {
    const roomChain = room?.blockchain?.name?.toLowerCase();
    try {
      let uids: string[] = [];
      const adminId = room.info.adminId;
      if (room?.info?.itemsPreference?.preferAdmin && adminId !== uid) {
        uids.push(adminId);
      }
      if (room?.info?.itemsPreference?.preferredUsers?.length > 0) {
        uids = uids.concat(
          room.info?.itemsPreference?.preferredUsers
            ?.filter((ele) => !uid?.includes(ele))
            .map((ele: string) => {
              if (ele.includes(roomChain)) return ele;
              return `${roomChain}-${ele}`;
            })
        );
      }

      if (uids.length < 1) return [];
      const res = await getPreferredItemsInRoom(roomId!, uids);
      return res.reduce((acc, ele) => {
        acc.push(...Object.keys(ele));
        return acc;
      }, []);
    } catch (e) {
      console.error("ERROR GETTING PREFERRED IDS", e);
    }
  };

  const getAvailableForBid = async () => {
    try {
      dispatch({
        type: ActionType.UPDATE,
        payload: { availableForBidLoading: true },
      });

      const [res, preferredIds, viewedData, passedData] = await Promise.all([
        getNftsAvailableForBid(roomId!),
        getPreferredIds(),
        getViewed(roomId!, uid || "not_found"),
        getPassed(roomId!, uid || "not_found"),
      ]);

      const viewed = Object.keys(viewedData?.data() || {});
      const passed = Object.keys(passedData?.data() || {});
      const availableForBid =
        res?.data?.map((ele: BiddableNft) => {
          const hasViewed = !viewed?.length || viewed?.includes(ele.itemId);
          return {
            ...ele,
            hasViewed,
          };
        }) || [];

      dispatch({
        type: ActionType.UPDATE,
        payload: {
          availableForBid: availableForBid,
          preferredIds: preferredIds,
          viewed,
          passed,
        },
      });
    } catch (e) {
      console.log("Err getAvailbleToBid", e);
    } finally {
      dispatch({
        type: ActionType.UPDATE,
        payload: { availableForBidLoading: false },
      });
    }
  };

  const isAdmin = uid === room?.info?.adminId;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const [emailData] = useDocument(
    doc(db, "users", uid || "not_found", "contact", "email")
  );
  const email = useMemo(() => {
    return emailData?.data() as Email;
  }, [emailData]);

  const [twitterData] = useDocument(
    doc(db, "users", uid || "not_found", "contact", "twitter")
  );
  const twitter = useMemo(() => {
    return twitterData?.data() as Twitter;
  }, [twitterData]);

  const [discordData] = useDocument(
    doc(db, "users", uid || "not_found", "contact", "discord")
  );
  const discord = useMemo(() => {
    return discordData?.data() as Discord;
  }, [discordData]);

  const capitalizeFirstLetter = (string: string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const canBid = useMemo(() => {
    const rules = [
      {
        validate: () => !uid,
        result: {
          canBid: false,
          errorTitle: "Connect Wallet",
          reason: `Please switch to ${capitalizeFirstLetter(
            roomChain
          )} and connect your wallet to bid on this item.`,
        },
      },
      {
        validate: () => !(email?.value || discord?.value || twitter?.value),
        result: {
          canBid: false,
          errorTitle: "No Contact Method",
          reason: "You must have a contact method to bid",
        },
      },
      {
        validate: () => !uid?.includes(roomChain),
        result: {
          canBid: false,
          errorTitle: "Wrong Chain",
          reason: `Please switch to ${capitalizeFirstLetter(
            roomChain
          )} and connect your wallet to bid on this item.`,
        },
      },
    ];

    for (const rule of rules) {
      if (rule.validate()) {
        return rule.result;
      }
    }

    return { canBid: true, reason: "Looks Good!" };
  }, [email, discord, twitter, uid, roomChain]);

  useEffect(() => {
    const initRoom = async () => {
      try {
        let roomTimeInfo;
        try {
          roomTimeInfo = await timeInfoFromServer(roomId!);
        } catch (e) { }

        const currentTime = Date.now() / 1000;
        const timeDiff = Math.round(
          (roomTimeInfo?.serverTime || currentTime) - currentTime
        );

        saveProgress(roomId!, "connected", uid!, true);
        dispatch({
          type: ActionType.UPDATE,
          payload: { isAdmin, canBid, roomTimeInfo, timeDiff },
        });
      } catch (e: any) {
        toast({
          title: "Error",
          description: "Something went wrong connecting to the room",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        isMountedRef.current = true;
        dispatch({
          type: ActionType.UPDATE,
          payload: { initializing: false },
        });
      }
    };

    if (!room) return;
    if (isMountedRef.current === true) return;

    initRoom();
    return () => {
      if (uid && roomId && !isMountedRef.current) {
        saveProgress(roomId, "connected", uid, false);
      }
    };
  }, [roomId, room]);

  // useEffect(() => {
  //   if (!room) return;
  //   getAvailableForBid();
  // }, [uid, room]);

  const handleJoin = async () => {
    const inRoom = room?.users?.includes(uid || "");
    try {
      if (uid) {
        const { data } = (await joinRoom(roomId!, true)) as JoinRoomRes;
        console.log(data);
        if (!data.allowed) throw new Error(data.detail);
        if (!inRoom) {
          gaJoinParty(roomId!);
        }
      }
    } catch (e: any) {
      console.log("ERROR JOINING", e);
    }
  };

  useEffect(() => {
    if (!roomId) return;
    if (!uid) return;
    handleJoin();
  }, [uid, roomId]);

  let canList = uid ? room?.users?.includes(uid) : false;
  let isSilentAuction = room?.info?.roomType === "smartSilentAuction";

  // if (!isSilentAuction && Object.keys(listings || {}).length >= room?.info?.numNftsToList) {
  //   canList = false;
  // }

  if (room?.info?.startAt > Date.now() / 1000) {
    canList = false;
  }

  if (uid && isSilentAuction) {
    canList = room?.info?.itemsPreference?.preferredUsers?.some((ele) =>
      uid.includes(ele)
    );
    // if (!canList && room?.info?.numNftsToList === 0) {
    //   isSilentAuction = true;
    // }
  }
  const roomState = () => {
    if (room?.status === "archived") return "archived";
    if (room?.status === "solving") return "solving";
    if (room?.status === "listing") {
      const part = filterCompleted(stepProgress);
      if (part.includes(uid!)) return "bidding";
      if (isSilentAuction) {
        return canList ? "listing" : "bidding";
      }
      if (room?.info?.itemsPreference?.preferredUsers.length > 0) {
        if (!canList && Object.keys(listings || {}).length < 1)
          return "bidding";
      }
      return canList ? "listing" : "bidding";
    }
    if (room?.status === "bidding") return "bidding";
    if (room?.status === "finalize") {
      if (room.info.roomType === "ai") {
        return "signing";
      }
      if (!room.hasOwnProperty("tradeFound")) {
        return "solving";
      }
      if (!room.tradeFound) {
        return "trade_not_found";
      }

      const uSwaps = state?.swapState?.userStatus?.filter(
        (ele: any) => ele.user === uid
      );

      const allCanceled =
        uSwaps?.length &&
        uSwaps?.every((ele: any) => {
          return ele?.swapState === "canceled";
        });

      if (allCanceled) {
        return "canceled";
      }
      if (room.tradeFound) {
        if (!room.hasOwnProperty("userSwaps")) {
          return "solving";
        }
        if (!Object.keys(room?.userSwaps).includes(uid!)) {
          return "no_trade_for_user";
        }
        return "signing";
      }
    }
  };

  const [showContactModal, setShowContactModal] = useState<boolean>();

  const value = useMemo(() => {
    // console.log("chain", chain);
    const roomStateStr = roomState();
    return {
      ...state,
      ...chain,
      roomState: roomStateStr,
      room,
      result,
      swap,
      connected,
      stepProgress: roomStateStr === "bidding" ? biddingProgress : stepProgress,
      listings,
      bids,
      budgets,
      canDeposit,
      canCancel,
      canList,
      isSilentAuction,
      highestBids,
      preview,
      isAdmin,
      canBid,
      showContactModal,
      setShowContactModal,
    };
  }, [
    state,
    room,
    result,
    swap,
    connected,
    stepProgress,
    biddingProgress,
    listings,
    bids,
    budgets,
    canDeposit,
    canCancel,
    canList,
    isSilentAuction,
    highestBids,
    preview,
    isAdmin,
    canBid,
    showContactModal,
    setShowContactModal,
    chain,
  ]);

  useEffect(() => {
    const prevDuration = prevRoomRef?.current?.info?.partyDuration;
    const currDuration = value?.room?.info?.partyDuration;
    if (!prevDuration || !currDuration || prevDuration === currDuration) return;
    toast({
      title: "New bid!",
      description: "Room duration is extended to 5 more minutes.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
  }, [value?.room?.info?.partyDuration]);

  const setModalId = useCallback((modalId?: string) => {
    dispatch({
      type: ActionType.UPDATE,
      payload: { modalId },
    });
  }, []);

  const handlePrev = useCallback(
    (set: any) => {
      const idx = set.findIndex((nft: Nft) => nft.itemId === value.modalId);
      if (idx > -1) {
        setModalId(set[idx === 0 ? set.length - 1 : idx - 1].itemId);
      }
    },
    [value.modalId, value.roomState]
  );

  const handleNext = useCallback(
    (set: any) => {
      const idx = set.findIndex((nft: Nft) => nft.itemId === value.modalId);
      if (idx > -1) {
        setModalId(set[idx >= set.length - 1 ? 0 : idx + 1].itemId);
      }
    },
    [value.modalId, value.roomState]
  );

  const handleInView = async (id: string) => {
    if (state.viewed.includes(id)) return;
    const viewedUpdates = [...state.viewed, id];
    await setViewed(roomId!, uid!, toObject(viewedUpdates));
    dispatch({
      type: ActionType.UPDATE,
      payload: { viewed: viewedUpdates },
    });
  };

  const handlePass = async (id: string) => {
    try {
      if (state.passed.includes(id)) return;
      const passedUpdates = [...state.passed, id];
      await setPassed(roomId!, uid!, toObject(passedUpdates), true);
      handleNext(
        state.availableForBid.filter(
          (ele: BiddableNft) => !state.passed.includes(ele.itemId)
        )
      );
      dispatch({
        type: ActionType.UPDATE,
        payload: { passed: passedUpdates },
      });

      return toast({
        title: "Item hidden!",
        description:
          "That Item has been hidden, you will find it in hidden Items.",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    } catch (e) {
      console.log("error passing", e);
    }
  };

  const removePass = async (id: string) => {
    try {
      if (!state.passed.includes(id)) return;
      const passedUpdates = state.passed.filter((x: string) => x !== id);
      await setPassed(roomId!, uid!, toObject(passedUpdates));
      dispatch({
        type: ActionType.UPDATE,
        payload: { passed: passedUpdates },
      });
    } catch (e) {
      console.log("error passing", e);
    }
  };

  const getSwapState = async () => {
    try {
      const { data } = (await getRoomSwapState(roomId!, uid!)) as any;
      if (data) {
        dispatch({
          type: ActionType.UPDATE,
          payload: { swapState: data },
        });
      }
    } catch (e) {
      console.log("error getting swap state", e);
    }
  };

  return (
    <Room.Provider
      value={{
        ...value,
        getAvailableToList,
        getAvailableForBid,
        setModalId,
        handleNext,
        handlePrev,
        handleInView,
        handlePass,
        getSwapState,
        removePass,
        setIsPassing,
      }}
    >
      {children}
    </Room.Provider>
  );
}

export function useRoom(): RoomsInterface {
  const ctx = useContext(Room);
  if (ctx === undefined) {
    throw new Error("useRoom requires Room.Provider");
  }
  return ctx;
}

// const {roomState} = useRoom()
// Room Status
// -listing
// -bidding
// -signing
// -completed
// -cancelled
// -solving
// -trade_not_found -> for the whole room
// -no_trade_for_user -> for the user
// -archived

// const {room, swap} = useRoom()
// room.users -> all users that have been accepted to the room
// swap.usersPaying -> all users that are in the trade and have to sign
// swap.users -> all users involved in the swap

// const {audioParticipants, toggleMute, audioOutputOptions} = useRoom()
