import React, { cloneElement, ReactElement } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { Nft, useRoom, BiddableNft } from "../contexts/roomContext";
import { useChain } from "../contexts/chainsContext";
import PendingImg from "../assets/pending.jpg";
import IconLink from "./Icons/IconLink";
import HTMLBlock from "./HTMLBlock";
import TxtBlock from "./TxtBlock";
import Modal from "./ui/Modal";
import { createFloor, getVolumeLabel } from "../utils";

interface NFTModalProps {
  children: ReactElement;
  nft: Nft;
}

const createImage = (nft?: BiddableNft) => {
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW="450px"
        fontSize="12px"
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "450px" }} />;
  }
  return (
    <img
      className="object-cover w-full h-full"
      src={nft?.image || nft?.thumbnail}
      alt={nft?.name}
      onError={(e) => {
        e.currentTarget.src = PendingImg;
      }}
    />
  );
};

export const NFTModal: React.FC<NFTModalProps> = ({ nft, children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getChainDetails } = useChain();
  const { decimals, abbr, truncate } = useRoom();
  const units = 10 ** decimals;

  const formatCompactNumber = (number: number): string => {
    const units = ["", "K", "M", "B", "T"];
    let magnitude = 0;

    while (number >= 1000 && magnitude < units.length - 1) {
      number /= 1000;
      magnitude++;
    }

    return number.toFixed(1) + units[magnitude];
  };

  const vol =
    nft?.collection?.volume / 10 ** getChainDetails(nft.blockchain)?.decimals;

  const formattedVolume = formatCompactNumber(vol);

  const modifiedAttributes = nft.attributes.map((attr) => {
    const modifiedValue = attr.value
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { ...attr, value: modifiedValue };
  });

  return (
    <>
      {cloneElement(children, { onClick: onOpen })}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        title={
          <div className="flex items-center justify-between w-full">
            <h3 className="text-xl font-bold">Details</h3>
            {nft?.collection?.name && (
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {nft.collection.name}
              </span>
            )}
          </div>
        }
      >
        <div className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div className="aspect-square bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden">
              {createImage(nft)}
            </div>

            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                  {nft.name}
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  {nft.description || "No description available"}
                </p>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Floor</p>
                  <p className="text-lg font-semibold text-gray-900 dark:text-white">
                    {createFloor(nft)}
                  </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Volume</p>
                  <p className="text-lg font-semibold text-gray-900 dark:text-white">
                    {nft &&
                      getVolumeLabel(
                        nft.collection.volume,
                        nft.collection?.floorPrice?.floorPrice?.decimals || 0,
                        abbr,
                        truncate
                      )}
                  </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Owners</p>
                  <p className="text-lg font-semibold text-gray-900 dark:text-white">
                    {nft?.collection.owners}
                  </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Total</p>
                  <p className="text-lg font-semibold text-gray-900 dark:text-white">
                    {nft?.collection.totalItems}
                  </p>
                </div>
              </div>

              {nft?.marketplace.url && (
                <a
                  href={nft.marketplace.url}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex items-center text-blue-600 dark:text-blue-400 hover:underline"
                >
                  See on Marketplace
                  <IconLink className="ml-2 h-4 w-4" />
                </a>
              )}
            </div>
          </div>

          {modifiedAttributes.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                Attributes
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {modifiedAttributes.map((attr) => (
                  <div
                    key={attr.trait_type}
                    className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {attr.trait_type}
                    </p>
                    <p className="text-base font-medium text-gray-900 dark:text-white">
                      {attr.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
