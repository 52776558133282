export const TheoThinking = () => (
    <div className="flex justify-start">
        <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg max-w-[80%]">
            <div className="flex space-x-2">
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
            </div>
        </div>
    </div>
); 