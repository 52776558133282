import "../css/components/homepage.scss";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import { BsTwitter, BsDiscord, BsMedium } from "react-icons/bs";
import PartnerGroup from "../components/PartnerGroup";
import SmartAuctions from "../components/SmartAuctions";
import backgroundImage from '../assets/bg-sa.png';
import DAppsCards from "../components/DAppsCards";
import { useTheme } from '../contexts/ThemeContext';

function isMobile() {
  return (
    "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0
  );
}

export default function Home() {
  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    }

    document.body.classList.add("home-page");

    if (isMobile()) document.body.classList.add("is-mobile");

    const scrollElem = document.getElementById("scroll-to");

    if (scrollElem) {
      scrollElem.onclick = function () {
        document.getElementById("app-links-list")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      };
    }

    return () => {
      document.body.classList.remove("home-page");
      document.body.classList.remove("is-mobile");
    };
  }, []);

  type CustomArrowProps = {
    onClick: () => void; // assuming onClick is a function
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -right-10 top-44 z-10  bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 2L13.5 14L1.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -left-10 top-44 z-10 bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2L2.5 14L14.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const settings = {
    customPaging: function (i: number) {
      return <div className="w-2 h-2 rounded-full opacity-30 bg-white" />;
    },
    dots: true,
    dotsClass:
      "justify-center items-center gap-2.5 !flex w-full mt-6 aitradeslider",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: (
      <CustomNextArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
    prevArrow: (
      <CustomPrevArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
  };

  const navigate = useNavigate();
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`No element with ID ${sectionId} found`);
    }
  };

  const { isDarkMode } = useTheme();

  return (
    <>
      <Helmet>
        <title>
          Tulle - Explore Apps
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="title"
          content="Tulle"
        />
        <meta
          name="description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://Tulle.ai" />
        <meta property="og:title" content="Tulle" />
        <meta
          property="og:description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="og:image" content="" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://Tulle.ai" />
        <meta property="twitter:title" content="Tulle" />
        <meta
          property="twitter:description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="twitter:image" content="" />
      </Helmet>

      <div className="min-h-screen flex flex-col dark:bg-gray-900">
        <div
          id="container"
          className="relative flex-grow"
        >
          {/* {window.location.pathname == '/star-atlas-skins' ? (
            <div
              style={{
                backgroundImage: `url(${backgroundImage})`,
                zIndex: -1,
              }}
              className="fixed w-full h-full bg-no-repeat bg-cover bg-center"
            />
          ) : (
            <div className="fixed w-full h-full pattern-bg" />
          )} */}

          <div className="inner-content">
            <div id="header" className="main-menu-content">
              <Navbar />
            </div>

            <div className="mt-16">
              <div
                id="howauctionswork"
                className="flex flex-col justify-center items-center gap-4 px-10 mb-10"
              >
                <h2 className="text-4xl font-bold my-5 text-center text-gray-900 dark:text-white">
                  Explore Apps Powered by Tulle
                </h2>

                <DAppsCards />
              </div>
            </div>
          </div>
        </div>

        <footer className={`w-full mt-auto bg-white dark:bg-gray-900 ${isDarkMode ? 'dark' : 'light'}`}>
          <div className="homefooter">
            <div className="copyright">
              <h1>
                <span className="text-gray-900 dark:text-white">
                  <span>Copyright &copy; </span> {new Date().getFullYear()}{" "}
                  Tulle
                </span>
                <span id="line" className="text-gray-900 dark:text-white">|</span>
                <Link to="/privacy-policy" className="text-gray-900 dark:text-white hover:opacity-80">Privacy Policy</Link>
                <span id="line" className="text-gray-900 dark:text-white">|</span>
                <Link to="/terms-of-service" className="text-gray-900 dark:text-white hover:opacity-80">Terms of Service</Link>
              </h1>
            </div>

            <div className="footerlist">
              <button className="text-gray-900 dark:text-white">
                <a
                  href="https://twitter.com/tulle_ai"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-80 transition-opacity"
                >
                  <BsTwitter />
                </a>
              </button>
              <button className="text-gray-900 dark:text-white">
                <a
                  href="https://discord.gg/4mfqbBkGRS"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-80 transition-opacity"
                >
                  <BsDiscord />
                </a>
              </button>
              <button className="text-gray-900 dark:text-white">
                <a
                  href="https://medium.com/neoswap"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-80 transition-opacity"
                >
                  <BsMedium />
                </a>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
