import FinalizeLoading from "../../components/FinalizeProgress";
import TradeGif from "../../components/TradeGif";
import TradeResult from "../../components/TradeResult";
import { useRoom } from "../../contexts/roomContext";
import dateFormat from "dateformat";
import RoomContractButtons from "../../components/RoomContractButtons";
import { getFormattedId } from "../../utils";
import { Alert, AlertIcon, Box, HStack } from "@chakra-ui/react";
import SmartAuctions from "../../components/SmartAuctions";

interface Props {
  room: any;
  uid: string;
  isAdmin: boolean;
  roomId: string;
  adminId: string;
}

const createUserRevenue = (
  userRevenue: number | undefined,
  units: number,
  abbr: string
) => {
  if (!userRevenue || userRevenue === 0) return;
  return `${userRevenue / units} ${abbr}`;
};

export default function Finalize({ room, uid, isAdmin, roomId }: Props) {
  const { abbr, units, result, roomState, canList } = useRoom();
  const swapId = room?.swapId?.swapId;
  const gifUrl = room?.gifUrl;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const gifData = result?.gifData;

  const userRevenue: string | undefined = createUserRevenue(
    result?.revenue?.[uid],
    units!,
    abbr!
  );

  const hasSwaps = room.info.name === "AI trade" || !!room?.userSwaps?.[uid!];

  if (roomState === "solving") return <FinalizeLoading />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6">
          {/* Left Column - Trade Details */}
          <div className="space-y-6">
            {room?.info?.roomType !== "ai" && (
              <div className="bg-gray-100 dark:bg-gray-700/50 rounded-lg overflow-hidden">
                <TradeGif gifUrl={gifUrl} gifData={gifData} />
              </div>
            )}

            <div className="bg-gray-100 dark:bg-gray-700/50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  Trade Details
                </h3>
                <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${roomState === "signing"
                  ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-300"
                  : "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300"
                  }`}>
                  {roomState === "signing" ? "Waiting" : "Complete"}
                </span>
              </div>

              <dl className="space-y-3">
                <div className="flex justify-between">
                  <dt className="text-sm text-gray-500 dark:text-gray-400">Chain</dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-white capitalize">{roomChain}</dd>
                </div>
                <div className="flex justify-between">
                  <dt className="text-sm text-gray-500 dark:text-gray-400">Status</dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-white">
                    {roomState === "signing" ? "Waiting on all to sign" : "Complete"}
                  </dd>
                </div>
                <div className="flex justify-between">
                  <dt className="text-sm text-gray-500 dark:text-gray-400">Created</dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-white">
                    {dateFormat(room.createdAt.seconds * 1000, "mmmm dS yyyy")}
                  </dd>
                </div>
              </dl>

              {room?.info?.roomType === "ai" && (
                <Alert
                  status="info"
                  className="mt-4 bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg"
                >
                  <AlertIcon className="text-blue-600 dark:text-blue-400" />
                  <span className="text-sm text-blue-700 dark:text-blue-300">
                    AI Trades, human-signed. Signed within 24 hours, often faster. Please be patient.
                  </span>
                </Alert>
              )}
            </div>
          </div>

          {/* Right Column - Trade Result & Smart Auctions */}
          <div className="space-y-6">
            <div className="bg-gray-100 dark:bg-gray-700/50 rounded-lg p-4">
              <TradeResult
                resultData={result}
                uid={uid}
                userRevenue={userRevenue}
                roomChain={roomChain}
              />
            </div>

            <div className="bg-gray-100 dark:bg-gray-700/50 rounded-lg p-4">
              <SmartAuctions
                chain={"solana"}
                onlyLive={true}
                exclude={[roomId]}
                hideHeader={true}
                customHeader={"Related Events"}
                isRelated={true}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Contract Buttons */}
      {(hasSwaps || isAdmin || canList) && (
        <div className="mt-6">
          <RoomContractButtons />
        </div>
      )}
    </div>
  );
}
