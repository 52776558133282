import { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/appContext";
import { Nft } from "../../contexts/roomContext";
import { updateUser } from "../../services/user.service";
import PendingImg from "../../assets/pending.jpg";
import MYNFTModal from "../MYNFTmodal";
import { NFTModal } from "../NFTModal";
import { FaLock, FaUnlockAlt } from "react-icons/fa";
import { getLockStatus } from "../../services/room.service";
import { LockNftModal } from "../LockNftModal";

interface Props {
  nft: Nft;
  viewMode: 'grid' | 'list';
}

export default function MyNftCard({ nft, viewMode }: Props) {
  const { name, thumbnail, itemId } = nft;
  const { uid } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState(false);

  const handlePfp = async () => {
    try {
      await updateUser(uid!, { avatar: thumbnail });
      // Replace toast with your preferred notification method
      alert("User Avatar Updated");
    } catch (error) {
      alert("Error Updating Avatar!");
    }
  };

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const checkLockStatus = async () => {
      const lockStatus = await getLockStatus(uid!, itemId);
      setIsLocked(!!lockStatus);
    };
    checkLockStatus();
  });

  if (viewMode === 'list') {
    return (
      <div className="flex items-center gap-4 p-3 
        bg-gray-50 dark:bg-gray-700/50 
        rounded-xl shadow-sm hover:shadow-md transition-shadow"
      >
        <div className="relative w-16 h-16">
          {isLocked && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 rounded-lg">
              <FaLock className="w-4 h-4 text-white" />
            </div>
          )}
          <NFTModal nft={nft}>
            <div className="w-16 h-16 overflow-hidden rounded-lg">
              <img
                src={thumbnail}
                alt={name}
                draggable="false"
                onError={(e) => {
                  e.currentTarget.src = PendingImg;
                }}
                className={`w-full h-full object-cover cursor-pointer
                  ${isLocked ? 'opacity-30' : isHovering ? 'opacity-75' : 'opacity-100'}
                  transition-all duration-200`}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              />
            </div>
          </NFTModal>
        </div>

        <div className="flex-1">
          <h3 className="font-medium text-gray-900 dark:text-white">
            {name || "Name not found"}
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {nft.collection?.name || "Unknown Collection"}
          </p>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={handleButtonClick}
            className="p-2 rounded-lg bg-gray-100 dark:bg-gray-700 
              hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
          >
            {isLocked ? (
              <FaUnlockAlt className="w-4 h-4 text-gray-700 dark:text-gray-300" />
            ) : (
              <FaLock className="w-4 h-4 text-gray-700 dark:text-gray-300" />
            )}
          </button>
          <button
            onClick={handlePfp}
            className="px-3 py-1.5 text-sm rounded-lg bg-primary hover:bg-primary-dark
              text-white transition-colors whitespace-nowrap"
          >
            Make PFP
          </button>
          <button
            onClick={() => window.open(nft.marketplace.url, "_blank")}
            className="px-3 py-1.5 text-sm rounded-lg bg-gray-100 dark:bg-gray-700
              text-gray-700 dark:text-gray-300 hover:bg-gray-200 
              dark:hover:bg-gray-600 transition-colors"
          >
            View Info
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col 
      bg-gray-50 dark:bg-gray-700/50 
      rounded-xl shadow-sm hover:shadow-md transition-shadow"
    >
      <div className="relative w-full pt-[100%]">
        {isLocked && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <FaLock className="w-6 h-6 text-white" />
          </div>
        )}
        <NFTModal nft={nft}>
          <div className="absolute inset-0 overflow-hidden">
            <img
              src={thumbnail}
              alt={name}
              draggable="false"
              onError={(e) => {
                e.currentTarget.src = PendingImg;
              }}
              className={`w-full h-full object-cover cursor-pointer rounded-t-xl
                ${isLocked ? 'opacity-30' : isHovering ? 'opacity-75' : 'opacity-100'}
                transition-all duration-200`}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            />
          </div>
        </NFTModal>
      </div>

      <div className="p-3">
        <div className="flex flex-col gap-2">
          <div>
            <h3 className="font-medium text-gray-900 dark:text-white truncate">
              {name || "Name not found"}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
              {nft.collection?.name || "Unknown Collection"}
            </p>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={handleButtonClick}
              className="p-1.5 rounded-lg bg-gray-100 dark:bg-gray-700 
                hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
            >
              {isLocked ? (
                <FaUnlockAlt className="w-4 h-4 text-gray-700 dark:text-gray-300" />
              ) : (
                <FaLock className="w-4 h-4 text-gray-700 dark:text-gray-300" />
              )}
            </button>

            <div className="flex gap-1.5 flex-1">
              <button
                onClick={handlePfp}
                className="px-2.5 py-1.5 text-xs rounded-lg bg-primary hover:bg-primary-dark
                  text-white transition-colors flex-1"
              >
                Make PFP
              </button>

              <button
                onClick={() => window.open(nft.marketplace.url, "_blank")}
                className="px-2.5 py-1.5 text-xs rounded-lg bg-gray-100 dark:bg-gray-700
                  text-gray-700 dark:text-gray-300 hover:bg-gray-200 
                  dark:hover:bg-gray-600 transition-colors flex-1"
              >
                View Info
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
