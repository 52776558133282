import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { collection, query, orderBy, addDoc, deleteDoc, doc, getDocs, where, setDoc, serverTimestamp } from 'firebase/firestore';
import Firebase from '../services/firebase';
import { useAppContext } from '../contexts/appContext';
import { IoSend } from "react-icons/io5";
import { BsTrash, BsChatLeft } from 'react-icons/bs';
import axios from 'axios';
import { format, formatDistanceToNow } from 'date-fns';
import { TheoThinking } from '../components/TheoThinking';
import { Timestamp } from 'firebase/firestore';

interface Message {
    role: 'user' | 'assistant';
    content: string;
    timestamp: Timestamp;
}

interface Chat {
    id: string;
    title: string;
    lastMessage: string;
    timestamp: Timestamp;
}

export default function TheoChat() {
    const { chatId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { uid, chatHistory, setChatHistory } = useAppContext();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [chats, setChats] = useState<Chat[]>([]);
    const [loading, setLoading] = useState(true);
    const db = Firebase.getDBApp();
    const [isThinking, setIsThinking] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // Load chats
    useEffect(() => {
        if (!uid) return;

        const loadChats = async () => {
            try {
                const chatsRef = collection(db, 'theo', uid, 'chats');
                const q = query(chatsRef, orderBy('timestamp', 'desc'));
                const snapshot = await getDocs(q);
                const loadedChats = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                } as Chat));
                setChats(loadedChats);
            } catch (error) {
                console.error("Error loading chats:", error);
            }
        };

        loadChats();
    }, [uid]);

    // Handle initial message from landing page
    useEffect(() => {
        const initialMessage = location.state?.initialMessage;
        if (initialMessage && chatId && uid) {
            sendMessage(initialMessage);
        }
    }, [chatId, uid]);

    // Load messages for current chat
    useEffect(() => {
        if (!uid || !chatId) return;

        const loadMessages = async () => {
            try {
                const messagesRef = collection(db, 'theo', uid, 'chats', chatId, 'messages');
                const q = query(messagesRef, orderBy('timestamp', 'asc'));
                const snapshot = await getDocs(q);
                const loadedMessages = snapshot.docs.map(doc => ({
                    ...doc.data()
                } as Message));
                setMessages(loadedMessages);
                setLoading(false);
            } catch (error) {
                console.error("Error loading messages:", error);
                setLoading(false);
            }
        };

        loadMessages();
    }, [uid, chatId]);

    useEffect(() => {
        if (chatHistory.length > 0) {
            const messagesWithTimestamp = chatHistory.map(msg => ({
                ...msg,
                role: msg.role as 'user' | 'assistant',
                timestamp: Timestamp.now()
            }));
            setMessages(messagesWithTimestamp);
        }
    }, []);

    const sendMessage = async (content: string = message) => {
        if (!content.trim() || !uid || !chatId) return;

        try {
            const newMessage = {
                role: 'user' as const,
                content: content.trim(),
                timestamp: Timestamp.now(),
            };

            // Create chat document first if it doesn't exist
            const chatDocRef = doc(db, 'theo', uid, 'chats', chatId);
            await setDoc(chatDocRef, {
                title: content.slice(0, 50) + (content.length > 50 ? '...' : ''),
                lastMessage: content,
                timestamp: Timestamp.now(),
            }, { merge: true });

            // Add user message
            const messagesRef = collection(chatDocRef, 'messages');
            await addDoc(messagesRef, {
                ...newMessage,
                timestamp: serverTimestamp()
            });
            setMessage('');
            setMessages(prev => [...prev, newMessage]);

            // Show thinking indicator
            setIsThinking(true);

            // Make API call to THEO
            try {
                const response = await axios.post(process.env.REACT_APP_THEO_API_URL || '', {
                    message: content,
                    chatId,
                    userId: uid
                });

                // Add THEO's response to chat
                const theoMessage = {
                    role: 'assistant' as const,
                    content: response.data.text || "I apologize, but I'm having trouble processing your request. Please try again later.",
                    timestamp: Timestamp.now(),
                };

                await addDoc(messagesRef, {
                    ...theoMessage,
                    timestamp: serverTimestamp()
                });
                setMessages(prev => [...prev, theoMessage]);

                // Update chat history here where response is in scope
                setChatHistory([...chatHistory,
                { role: 'user', content: content, timestamp: Timestamp.now() },
                { role: 'assistant', content: response.data.text, timestamp: Timestamp.now() }
                ]);

            } catch (error) {
                console.error("Error getting THEO response:", error);
                // Add error message to chat
                const errorMessage = {
                    role: 'assistant' as const,
                    content: "I apologize, but I'm having trouble processing your request. Please try again later.",
                    timestamp: Timestamp.now(),
                };

                await addDoc(messagesRef, {
                    ...errorMessage,
                    timestamp: serverTimestamp()
                });
                setMessages(prev => [...prev, errorMessage]);
            } finally {
                setIsThinking(false);
            }

        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const deleteChat = async (chatId: string, e: React.MouseEvent) => {
        e.stopPropagation();
        if (!uid) return;

        try {
            await deleteDoc(doc(db, 'theo', uid, 'chats', chatId));
            setChats(prev => prev.filter(chat => chat.id !== chatId));
            navigate('/theo');
        } catch (error) {
            console.error("Error deleting chat:", error);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, isThinking]);

    return (
        <div className="flex h-[calc(100vh-64px)]">
            {/* Sidebar */}
            <div className="hidden md:block w-80 border-r border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 overflow-y-auto">
                <div className="p-4">
                    <h2 className="text-xl font-semibold mb-6 text-gray-900 dark:text-white flex items-center gap-2">
                        <BsChatLeft className="w-4 h-4" />
                        Recent chats
                    </h2>
                    <div className="space-y-2">
                        {chats.map((chat) => (
                            <div
                                key={chat.id}
                                onClick={() => navigate(`/theo/chat/${chat.id}`)}
                                className={`group p-4 rounded-lg cursor-pointer transition-all hover:shadow-md ${chat.id === chatId
                                    ? 'bg-blue-50 dark:bg-blue-900/50'
                                    : 'hover:bg-white dark:hover:bg-gray-800'
                                    }`}
                            >
                                <div className="flex justify-between items-start mb-1">
                                    <p className="font-medium text-gray-900 dark:text-white truncate flex-1">
                                        {chat.title}
                                    </p>
                                    <button
                                        onClick={(e) => deleteChat(chat.id, e)}
                                        className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-red-100 dark:hover:bg-red-900/50 rounded"
                                    >
                                        <BsTrash className="w-4 h-4 text-red-500" />
                                    </button>
                                </div>
                                <p className="text-sm text-gray-500 dark:text-gray-400 truncate mb-1">
                                    {chat.lastMessage}
                                </p>
                                <p className="text-xs text-gray-400">
                                    {formatDistanceToNow(chat.timestamp.toDate(), { addSuffix: true })}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Main chat area */}
            <div className="flex-1 flex flex-col bg-white dark:bg-gray-900">
                {/* Messages area */}
                <div className="flex-1 p-4 overflow-y-auto">
                    <div className="max-w-3xl mx-auto space-y-4">
                        {messages.map((msg, idx) => (
                            <div key={idx} className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                                <div className={`max-w-[80%] p-4 rounded-lg ${msg.role === 'user'
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white'
                                    }`}>
                                    {msg.content}
                                </div>
                            </div>
                        ))}
                        {isThinking && (
                            <div className="flex justify-start">
                                <div className="max-w-[80%] p-4 rounded-lg bg-gray-100 dark:bg-gray-800 shadow-sm">
                                    <TheoThinking />
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                </div>

                {/* Input area */}
                <div className="border-t border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-900 p-4">
                    <div className="max-w-3xl mx-auto relative">
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Message THEO..."
                            className="w-full min-h-[60px] max-h-[200px] p-4 pr-24 rounded-xl border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white resize-none"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    if (message.trim()) sendMessage();
                                }
                            }}
                        />
                        <button
                            onClick={() => sendMessage()}
                            className={`absolute right-4 bottom-4 h-8 flex items-center justify-center bg-blue-500 hover:bg-blue-600 rounded-full transition-all duration-200 hover:scale-110 px-4 gap-2 ${message.trim() ? 'opacity-100 visible' : 'opacity-0 invisible'
                                }`}
                        >
                            <span className="text-white text-sm font-medium">Send</span>
                            <IoSend className="text-white w-4 h-4 -rotate-45 -translate-x-px -translate-y-px" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
} 