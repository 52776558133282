import React, { useCallback, useEffect, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import { formatToTwoSignificantDigitsAfterDecimal } from '../../../../utils';
import useSolana from '../../hooks/useSolana';
import { SalePack, SaleItem } from '../../types/packs';
import TransactionModal from '../../../ui/TransactionModal';
import ItemGrid, { GridItem } from '../../../ui/ItemGrid';
import TransactionTable from '../../../ui/TransactionTable';
import { CurrencyDollarIcon, ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import PendingImg from '../../../../assets/pending.jpg';
import modalMachine from '../BuyingModal/modalMachine';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import toast, { Toaster } from 'react-hot-toast';

interface SellModalProps {
    isOpen: boolean;
    onClose: () => void;
    salePack: SalePack;
    partnerToken: string;
}

const SellModal: React.FC<SellModalProps> = ({
    isOpen,
    onClose,
    salePack,
    partnerToken,
}) => {
    const [state, send] = useMachine(modalMachine, {
        input: {
            partnerToken,
            mode: 'sell',
            salePack,
            packDetails: {
                id: salePack.id,
                name: salePack.name,
                description: salePack.description,
                items: salePack.items.map(item => ({
                    details: {
                        id: item.details.id,
                        name: item.details.name,
                        onChainId: item.details.onChainId,
                        type: item.details.type,
                        sourceMarketplace: item.details.sourceMarketplace,
                        image: item.details.image,
                        url: item.details.url,
                        description: item.details.description,
                        collection: item.details.collection,
                        additionalInfo: item.details.additionalInfo
                    },
                    quantity: item.quantity,
                    price: {
                        amount: Math.floor(item.pricePerUnit * (item.currency === 'USDC' ? 1e6 : item.currency === 'ATLAS' ? 1e8 : 1e9)),
                        token: {
                            id: item.currency.toLowerCase(),
                            name: item.currency,
                            mint: item.currency === 'USDC' ? 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v' :
                                item.currency === 'ATLAS' ? 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx' :
                                    'So11111111111111111111111111111111111111112',
                            symbol: item.currency,
                            decimals: item.currency === 'USDC' ? 6 : item.currency === 'ATLAS' ? 8 : 9
                        }
                    }
                }))
            }
        }
    });

    const { connected, connect, walletAddress, signAndSendTransactions, checkTransactions } = useSolana();

    // Debug current state
    useEffect(() => {
        console.log('Current State:', state.value);
        console.log('Context:', state.context);
    }, [state]);

    // Show toast on message
    useEffect(() => {
        if (state.context.message) {
            toast(state.context.message, {
                duration: 9000,
            });
        }
    }, [state.context.message]);

    // Show toast on error
    useEffect(() => {
        if (state.context.error) {
            const error = state.context.error as Error;
            toast.error(error.message, {
                duration: 9000,
            });
        }
    }, [state.context.error]);

    // Connect wallet
    useEffect(() => {
        if (state.matches('connect')) {
            if (connected) {
                send({ type: 'CONNECT', walletAddress });
            } else {
                connect();
            }
        }
    }, [connect, connected, state.value, walletAddress]);

    // Sign and send transactions
    useEffect(() => {
        if (state.matches('signTxs')) {
            signAndSendTransactions(state.context.txs)
                .then((results) => {
                    send({ type: 'SIGN', txs: results });
                })
                .catch((error) => {
                    send({ type: 'REJECT', error });
                });
        }
    }, [signAndSendTransactions, state.value, state.context.txs]);

    // Check transactions
    useEffect(() => {
        const checkTxs = async () => {
            if (state.matches('checkTxs')) {
                try {
                    const updatedTransactions = await checkTransactions(state.context.txs, true);
                    send({ type: 'UPDATE', txs: updatedTransactions });
                } catch (error) {
                    send({ type: 'ERROR', error });
                }
            }
        };

        let intervalId: NodeJS.Timer;
        if (state.matches('checkTxs')) {
            intervalId = setInterval(checkTxs, 2000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [checkTransactions, state.value, state.context.txs]);

    const uiPrice = useMemo(() => {
        const prices = [];
        if (salePack.totalValueUsdc > 0) {
            prices.push(`${formatToTwoSignificantDigitsAfterDecimal(salePack.totalValueUsdc)} USDC`);
        }
        if (salePack.totalValueAtlas > 0) {
            prices.push(`${formatToTwoSignificantDigitsAfterDecimal(salePack.totalValueAtlas)} ATLAS`);
        }
        return prices.join(' + ');
    }, [salePack]);

    const canSell = useMemo(() => {
        return salePack.items.every(item => item.quantityInWallet >= item.quantity);
    }, [salePack]);

    const handleRetry = useCallback((tx: any) => {
        send({ type: 'RETRY' });
    }, [send]);

    const handleSellClick = useCallback(() => {
        console.log('Sell button clicked, current state:', state.value);
        if (state.matches('success')) {
            send({ type: 'SHARE' });
        } else if (state.matches('notConnected')) {
            send({ type: 'NEXT' }); // This will trigger connection
        } else if (state.matches('accept')) {
            send({ type: 'NEXT' }); // This will trigger fetchTxs for sell mode
        } else if (state.matches('errorInit')) {
            send({ type: 'RETRY' });
        } else if (state.matches('init')) {
            // Wait for initialization to complete
            console.log('Waiting for initialization to complete...');
        } else {
            console.log('Invalid state for sell action:', state.value);
            toast.error('Invalid state for sell action. Please try again.', {
                duration: 9000
            });
        }
    }, [state.value, send, toast]);

    const renderContent = () => {
        if (state.matches('init')) {
            return <LoadingSpinner className="h-32" />;
        }

        const items: GridItem[] = salePack.items.map(item => ({
            name: item.details.name || 'Unnamed Item',
            image: item.details.image || PendingImg,
            quantity: item.quantity,
            available: item.quantityInWallet
        }));

        const hasTransactions = state.context.txs.length > 0;

        return (
            <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
                    <div className="flex items-center justify-between mb-3">
                        <h3 className="text-base font-medium text-gray-900 dark:text-white">
                            Items to Sell
                        </h3>
                        <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400">
                            <CurrencyDollarIcon className="h-4 w-4" />
                            <span>{uiPrice}</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {items.map((item, index) => (
                            <div
                                key={item.name}
                                className={`${index === 0 ? 'col-span-1 sm:col-span-2 md:col-span-2' : 'col-span-1'}`}
                            >
                                <div className="bg-gray-100 dark:bg-gray-700/50 rounded-lg overflow-hidden">
                                    <div className="p-2">
                                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">{item.name}</h4>
                                    </div>
                                    <div className="relative h-32">
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                            className="w-full h-full object-cover"
                                        />
                                        {item.quantity && item.quantity > 0 && (
                                            <div className="absolute bottom-0 right-0 p-2 bg-black/75">
                                                <span className="text-sm font-bold text-[#ffbe4d]">
                                                    x {item.quantity}
                                                </span>
                                            </div>
                                        )}
                                        {'available' in item && item.available !== undefined && (
                                            <div className="absolute top-0 right-0 p-2 bg-black/75">
                                                <span className="text-xs text-white">
                                                    Available: {item.available}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {hasTransactions && (
                    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
                        <div className="flex items-center justify-between mb-3">
                            <h3 className="text-base font-medium text-gray-900 dark:text-white">
                                Transaction Status
                            </h3>
                            {state.matches('checkTxs') && (
                                <button
                                    onClick={() => send({ type: 'UPDATE_BALANCES' })}
                                    className="inline-flex items-center space-x-1 text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
                                >
                                    <ArrowPathIcon className="h-4 w-4" />
                                    <span>Refresh</span>
                                </button>
                            )}
                        </div>
                        <TransactionTable
                            transactions={state.context.txs}
                            isLoading={state.matches('fetchTxs')}
                            onRetry={handleRetry}
                        />
                    </div>
                )}

                {!canSell && !state.matches('init') && (
                    <div className="bg-yellow-50 dark:bg-yellow-900/20 rounded-lg p-3">
                        <div className="flex items-start space-x-2">
                            <div className="flex-shrink-0">
                                <svg className="h-4 w-4 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8.485 2.495c.873-1.512 3.157-1.512 4.03 0l4.933 8.545c.873 1.512-.218 3.41-2.015 3.41H5.567c-1.797 0-2.888-1.898-2.015-3.41l4.933-8.545zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                                    Insufficient Items
                                </h3>
                                <p className="mt-1 text-sm text-yellow-700 dark:text-yellow-300">
                                    You don't have enough items in your wallet to complete this sale.
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {state.matches('success') && (
                    <div className="bg-green-50 dark:bg-green-900/20 rounded-lg p-3">
                        <div className="flex items-start space-x-2">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-4 w-4 text-green-400" />
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-green-800 dark:text-green-200">
                                    Sale Successful
                                </h3>
                                <p className="mt-1 text-sm text-green-700 dark:text-green-300">
                                    Your sale has been completed successfully. You can view the transaction details above.
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderFooter = () => {
        if (state.matches('init')) return null;

        const isProcessing = state.matches('signTxs') || state.matches('checkTxs') || state.matches('fetchTxs');
        const buttonDisabled = !canSell || isProcessing;

        return (
            <div className="flex justify-end space-x-3">
                <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={handleSellClick}
                    disabled={buttonDisabled}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {state.matches('success')
                        ? 'Share'
                        : isProcessing
                            ? 'Processing...'
                            : 'Sell Now'}
                </button>
            </div>
        );
    };

    return (
        <>
            <Toaster position="top-right" />
            <TransactionModal
                isOpen={isOpen}
                onClose={onClose}
                title={salePack.name}
                subtitle={salePack.description}
                size="2xl"
                footer={renderFooter()}
                className="dark:bg-gray-900"
                headerClassName="dark:bg-gray-800/50"
                contentClassName="dark:bg-gray-900/50"
            >
                {renderContent()}
            </TransactionModal>
        </>
    );
};

export default SellModal; 