import React from 'react';

interface LoadingSpinnerProps {
    text?: string;
    className?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ text = 'Loading...', className = 'h-[200px]' }) => {
    return (
        <div className={`flex flex-col items-center justify-center w-full ${className} space-y-4`}>
            <div className="w-12 h-12 relative">
                <div className="absolute inset-0 rounded-full border-4 border-t-[#ffbe4d] border-b-[#ffbe4d] border-r-transparent border-l-transparent animate-spin"></div>
                <div className="absolute inset-2 rounded-full border-4 border-t-[#ffbe4d/40] border-b-[#ffbe4d/40] border-r-transparent border-l-transparent animate-spin-slow"></div>
            </div>
            <p className="buying-modal-header mt-2">{text}</p>
        </div>
    );
};

export default LoadingSpinner; 