import { starAtlasFont } from "./utils";
import React from 'react';

const HowItWorksCard: React.FC = () => {
    return (
        <div className="w-full p-4 space-y-6">
            <div className="grid gap-6">
                {/* Introduction */}
                <div className="bg-[#202024] rounded-xl p-6 transform hover:scale-[1.02] transition-transform duration-300">
                    <h2 className="text-[#ffbe4d] text-3xl mb-3" style={starAtlasFont}>How it works?</h2>
                    <p className="text-white text-lg" style={starAtlasFont}>
                        Tulle Star Atlas packs are designed to help with onboarding and optimizing your playtime in Star Atlas. We support both new and experienced players in acquiring game data and items.
                    </p>
                </div>

                {/* Starter Packs */}
                <div className="bg-[#202024] rounded-xl p-6 transform hover:scale-[1.02] transition-transform duration-300">
                    <h2 className="text-[#ffbe4d] text-3xl mb-3" style={starAtlasFont}>Starter packs</h2>
                    <p className="text-white text-lg" style={starAtlasFont}>
                        Starter packs are designed to help onboard new players to the game. They contain essential items for your first space missions.
                        After buying a pack you can go directly to{' '}
                        <a href="https://based.staratlas.com/" target="_blank" rel="noopener noreferrer" className="text-[#ffbe4d] hover:underline">
                            STAR ATLAS
                        </a>
                        , deposit items from your wallet in LOCAL INVENTORY and PORT OF ENTRY and start playing.
                    </p>
                </div>

                {/* Crafting Helper */}
                <div className="bg-[#202024] rounded-xl p-6 transform hover:scale-[1.02] transition-transform duration-300">
                    <h2 className="text-[#ffbe4d] text-3xl mb-3" style={starAtlasFont}>Crafting helper</h2>
                    <p className="text-white text-lg" style={starAtlasFont}>
                        Whether you're an experienced player or just starting and want to explore the "Play to Earn" concept in Star Atlas, you can use our crafting helper. This tool is designed to provide essential game data and help you find the most profitable items to craft, which you can later sell on the Star Atlas{' '}
                        <a href="https://play.staratlas.com/market/" target="_blank" rel="noopener noreferrer" className="text-[#ffbe4d] hover:underline">
                            MARKETPLACE
                        </a>.
                    </p>
                </div>

                {/* Sales Assistant */}
                <div className="bg-[#202024] rounded-xl p-6 transform hover:scale-[1.02] transition-transform duration-300 border-2 border-[#ffbe4d]/20">
                    <div className="absolute -top-2 -right-2 bg-[#ffbe4d] text-black px-3 py-1 rounded-full text-sm font-bold">New!</div>
                    <h2 className="text-[#ffbe4d] text-3xl mb-3" style={starAtlasFont}>Sales Assistant</h2>
                    <p className="text-white text-lg" style={starAtlasFont}>
                        Want to sell your items efficiently? Ask Theo to analyze current market prices and create optimized sale packs. He can help you:
                        <ul className="list-disc list-inside mt-2 space-y-1">
                            <li>Create custom sale packs based on market demand</li>
                            <li>Execute sales automatically using open orders</li>
                        </ul>
                    </p>
                </div>

                {/* Theo */}
                <div className="bg-[#202024] rounded-xl p-6 transform hover:scale-[1.02] transition-transform duration-300">
                    <h2 className="text-[#ffbe4d] text-3xl mb-3" style={starAtlasFont}>Theo</h2>
                    <p className="text-white text-lg" style={starAtlasFont}>
                        If you need a customized item pack, want to explore game lore, check current prices, or learn about specific game mechanics, you can ask our chatbot, Theo, for help. Theo is still learning, but he's quite knowledgeable about the game!
                    </p>
                </div>

                {/* More Content */}
                <div className="bg-[#202024] rounded-xl p-6 transform hover:scale-[1.02] transition-transform duration-300">
                    <h2 className="text-[#ffbe4d] text-3xl mb-3" style={starAtlasFont}>More content</h2>
                    <p className="text-white text-lg" style={starAtlasFont}>
                        More Star Atlas content is coming soon! If you have any ideas for features to add next, feel free to share them with us using the feedback button on the left.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksCard;
