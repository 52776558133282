import { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Connection,
  PublicKey,
  Transaction,
  VersionedTransaction,
  TransactionInstruction,
  LAMPORTS_PER_SOL,
  SystemProgram,
  ComputeBudgetProgram,
} from '@solana/web3.js'
import {
  SingleListingAnchor,
  TensorSwapSDK,
  findSingleListingPDA,
} from '@tensor-oss/tensorswap-sdk'
import bs58 from 'bs58'
import { useAppContext } from '../../contexts/appContext'
import { useChain } from '../../contexts/chainsContext'
import { AnchorProvider, Provider } from '@coral-xyz/anchor'

import {
  neoColTypes as nCT,
  CREATE_INSTRUCTIONS as colIx,
  Act,
  EnvOpts,
  MakeSArg,
  TakeSArg,
  ClaimSArg,
  SwapData,
  BTv,
  TYPES,
  UTILS,
} from '@neoswap/solana-collection-swap'
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useUA } from '../../contexts/userTracking'
import { neoSwap as nS, neoConst as nC, neoTypes as nT } from '@neoswap/solana'

import { useWalletModal } from '@solana/wallet-adapter-react-ui'

import { isNil } from 'lodash'

import { Client, Member } from '@ladderlabs/buddy-sdk'

import { getMessageToSign, verifyMessageToGetURL } from '../../components/blockchains/utils'

import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  //@ts-ignore
  createTransferInstruction,
  //@ts-ignore
  createAssociatedTokenAccountIdempotentInstruction,
} from '@solana/spl-token'
import { GmClientService, Order } from '@staratlas/factory'
import { sleep } from '../../utils'
import {
  BundleSpec,
  ItemSpec,
  CurrencyDetails,
  NeoswapFee,
  BundleError,
  TensorBundle,
  MintData,
  JupSwap,
  InOutAtlasBundle,
  ItemOrder,
  BuySkinFeedback,
  AcceptedSkin,
  PriceFeedback,
  AcceptedCurrency,
  SkinDetailsType,
  BundleTransaction,
  PaymentMethod,
  Payment,
  PaymentCurrencies,
  BundleDetails,
  ExchangeRates,
  ExchangeRatesToCurrency,
  PaymentOption,
  BuddyDetails,
} from '../../components/Bundles/types'
import { bundlesList, tokens, itemsList } from '../../components/Bundles/consts'
import { isVersionedTransaction } from '@solana/wallet-adapter-base'
import { useWalletPassThrough } from '../../components/PacksWidget/contexts/WalletPassthroughContext'
import toast from 'react-hot-toast'
import { WalletAdapter } from '@solana/wallet-adapter-base'

const chainName: string = 'solana'
const network = process.env.REACT_APP_SOLANA_NETWORK

const NO_WALLET_ERR = 'noWalletErr'
const CONNECT_WALLET_ERR = 'connectWalletErr'
const NO_ADDRESS_ERR = 'noAddressErr'
const PRIMARY_WALLET_ERR = 'primaryWalletErr'
const LEDGER_SIGN_MESSAGE_ERR = 'WalletSignMessageError'

const NS_FEE_RATE = 0.05
const NS_FEE_ADDRESS = 'FjecsBcSXQh4rjPSksh2eBiXUswcMpAwU25ykcr842j8'

const SLIPPAGE = 0.05 // between 0 and 1

let PRIORITIZATION_FEE: number | undefined = undefined
PRIORITIZATION_FEE = 1e6

// const tokens = {
//     // 11111111111111111111111111111111: {
//     //     abbr: "SOL",
//     //     name: "Solana",
//     //     decimals: 9,
//     // },
//     So11111111111111111111111111111111111111112: {
//         abbr: "SOL",
//         name: "Solana",
//         decimals: 9,
//     },
//     EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: {
//         abbr: "USDC",
//         name: "USD Coin",
//         decimals: 6,
//     },
//     ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx: {
//         abbr: "ATLAS",
//         name: "Star Atlas",
//         decimals: 8,
//     },
//     JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN: {
//         abbr: "JUP",
//         name: "Jupiter",
//         decimals: 6,
//     },
//     CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB: {
//         abbr: "PURI",
//         name: "Pur",
//         decimals: 9,
//     },
// };

const mintToItem = (mint: string) => {
  return Object.values(itemsList).find((value) => value.mint === mint)
}

const opts = {
  preflightCommitment: 'confirmed' as any,
}

export default function useSolana() {
  const anchorWallet = useAnchorWallet()
  const { chain } = useChain()
  const currentChain = chain?.name?.toLowerCase() ?? ''

  const [autoConnect, setautoConnect] = useState(false)
  const [refireSignin, setRefireSignIn] = useState(false)
  const [ledgerSignMessageError, setLedgerSignMessageError] = useState(false)
  const {
    getEncodedNonce,
    getToken,
    getTokenFromSignedTransaction,
    signInWithToken,
    signOut,
    signingIn,
    updateState,
    signedIn,
  } = useAppContext()
  const { gaSignWallet } = useUA()
  const solanaConnection = useConnection()
  const solanaWallet = useWalletPassThrough()
  const solanaWalletModal = useWalletModal()

  const address = useMemo(() => {
    return solanaWallet?.publicKey?.toBase58()
  }, [solanaWallet.publicKey])
  const connected = solanaWallet.connected
  // neoConstSwap.VERSION
  useEffect(() => {
    if (!refireSignin || !currentChain) return
    if (currentChain === 'solana' && !solanaWallet.wallet) {
      return
    }
    signIn()
    setRefireSignIn(false)
  }, [currentChain, refireSignin, solanaWallet.wallet])

  useEffect(() => {
    if (!solanaWalletModal.visible) {
      setautoConnect(false);
      updateState({ signingIn: false });
    }
  }, [solanaWalletModal.visible]);

  const handleConnect = async () => {
    try {
      if (!connected && !solanaWallet.wallet) {
        solanaWalletModal.setVisible(true);
        setautoConnect(true);
        return false;
      }

      try {
        await solanaWallet.connect();
        return true;
      } catch (error: any) {
        setautoConnect(false);
        updateState({ signingIn: false });
        
        // Check for both the error name and message since different wallets may format the error differently
        if (error.name === 'WalletConnectionError' || 
            error.message?.includes('User rejected') ||
            error.message?.includes('cancelled')) {
          toast('You cancelled the wallet connection request', {
            duration: 9000,
            icon: 'ℹ️',
          });
        } else {
          toast.error(error.message || 'Failed to connect wallet', {
            duration: 9000,
          });
        }
        
        solanaWalletModal.setVisible(false);
        return false;
      }
    } catch (e: any) {
      setautoConnect(false);
      updateState({ signingIn: false });
      solanaWalletModal.setVisible(false);
      return false;
    }
  };

  useEffect(() => {
    if (!connected) {
      setautoConnect(false);
      updateState({ signingIn: false });
    }
  }, [connected]);

  useEffect(() => {
    if (autoConnect && solanaWallet.wallet) {
      handleConnect();
    }
  }, [solanaWallet.wallet, autoConnect]);

  const signIn = async () => {
    try {
      updateState({ signingIn: true })

      if (!connected && !solanaWallet.wallet) {
        solanaWalletModal.setVisible(true)
        setautoConnect(true);
        return;
      }

      if (!connected || !address) {
        const connected = await handleConnect().catch(() => false);
        if (!connected) {
          updateState({ signingIn: false });
          return;
        }
      }

      // Ensure address is defined after connection
      if (!address) {
        toast.error('Wallet address not found');
        updateState({ signingIn: false });
        return;
      }

      let token = ''
      if (ledgerSignMessageError) {
        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: new PublicKey(address),
            toPubkey: new PublicKey(address),
            lamports: 0,
          })
        )

        const connection = new Connection(network!, 'confirmed')
        const { blockhash } = await connection.getLatestBlockhash()
        transaction.recentBlockhash = blockhash
        transaction.feePayer = new PublicKey(address)

        if (!solanaWallet.signTransaction)
          throw new Error("Error Ledger doesn't have signTransaction")

        const signedTransaction = await solanaWallet.signTransaction(transaction)
        const serializedTransaction = signedTransaction.serialize()
        const transactionBase64 = Buffer.from(serializedTransaction).toString('base64')
        token = await getTokenFromSignedTransaction(transactionBase64, 'solana')
      } else {
        const message = (await getEncodedNonce(address, chainName)) as string
        const encoded = new TextEncoder().encode(message)

        if (!solanaWallet.signMessage) throw new Error(NO_WALLET_ERR)

        const resp = await solanaWallet.signMessage(encoded)
        const signature = Buffer.from(resp).toString('base64')
        token = await getToken(address, chainName, signature)
      }
      if (!token) throw new Error('No token')

      await signInWithToken(token)

      gaSignWallet(solanaWallet?.wallet?.adapter?.name)
      updateState({ signingIn: false })
    } catch (e: any) {
      const refireErrors = [CONNECT_WALLET_ERR, NO_WALLET_ERR, PRIMARY_WALLET_ERR, NO_ADDRESS_ERR]
      let refire = refireErrors.includes(e.message)

      if (e.message === NO_WALLET_ERR && currentChain === 'solana') {
        solanaWalletModal.setVisible(true)
      }

      if (e.message === CONNECT_WALLET_ERR && currentChain === 'solana') {
        await solanaWallet.connect()
      }

      if (
        e.name === 'WalletSignMessageError' &&
        (e.message.includes('unsupportedOperation') || e.message.includes('rejected'))
      ) {
        setLedgerSignMessageError(true)
        refire = true
      }

      if (refire) {
        setRefireSignIn(true)
      } else {
        updateState({ signingIn: false })
        if (e.message?.includes('User rejected')) {
          toast('You cancelled the wallet connection request', {
            duration: 9000,
          });
        } else if (!refireErrors.includes(e.message)) {
          toast.error(e.message || 'Failed to connect wallet', {
            duration: 9000,
          });
        }
      }
    }
  }

  const handleSignout = async () => {
    if (connected) solanaWallet.disconnect()
    await signOut()
  }

  const getProvider = async (): Promise<Provider> => {
    // console.log("Primary wallet object:", primaryWallet);
    // console.log("Wallet connected status:", primaryWallet?.connected);
    if (!address || !connected) {
      throw new Error('Wallet not connected on useSolana')
    } else {
      return new AnchorProvider(
        new Connection(network!, 'confirmed'),
        anchorWallet as any,
        opts.preflightCommitment // this is something we have added manually
      )
    }
  }

  const getStatementUrl = async () => {
    await handleConnect()
    if (!solanaWallet.publicKey) throw new Error('No public key')
    const { nonce } = await getMessageToSign('solana', solanaWallet.publicKey.toString())
    if (!nonce) throw new Error('No nonce')
    const encoded = new TextEncoder().encode(nonce)
    if (!solanaWallet.signMessage) throw new Error(NO_WALLET_ERR)
    const resp = await solanaWallet.signMessage(encoded)
    const signature = Buffer.from(resp).toString('base64')
    if (!signature) throw new Error('No signature')
    const { statementUrl } = await verifyMessageToGetURL(
      'solana',
      solanaWallet.publicKey.toString(),
      signature
    )
    return statementUrl
  }

  const deposit = async (
    config:
      | nT.ApiProcessorConfigType[]
      | { type: 'atlasBundle'; inOutAtlasBundle: InOutAtlasBundle }
      | { type: 'tensorBundle'; tensorBundle: TensorBundle }
      | { type: 'jupSwap'; jupSwap: JupSwap }
  ) => {
    if (!connected) await solanaWallet.connect()

    if (Array.isArray(config)) {
      let processedTransactions = await nS.CREATE_INSTRUCTIONS.apiProcessorTranscript({
        config,
        clusterOrUrl: process.env.REACT_APP_SOLANA_NETWORK!,
      })

      let provider = await getProvider()
      if (!provider.sendAll) throw 'No sendAll function in provider'

      let hashes = await provider.sendAll(processedTransactions, {
        skipPreflight: true,
        maxRetries: 3,
      })
      console.log('hashes', hashes)

      return { hashes, programId: nC.SWAP_PROGRAM_ID.toString() }
    } else
      switch (config.type) {
        case 'atlasBundle':
          return await getStarAtlasBundle(config.inOutAtlasBundle)
          break
        case 'tensorBundle':
          return await buyAllTensor(config.tensorBundle)
          break
        case 'jupSwap':
          return await buyTokenFromJupiter(config.jupSwap)
          break
        default:
          throw new Error('no type')
          break
      }
  }

  ///
  /// STAR ATLAS BUNDLE CODE
  ///

  const starAtlasBundleError = {
    lackfundsJup:
      'Insufficient USDC balance. Please top up your account before retrying the purchase.',
    maxAllRetry: 'Unable to complete the transaction after 3 attempts. Please report this issue.',
    lackFundsBundle: `Insufficient funds to purchase the bundle. Please top up your account and try again.`,
    priceBecameTooHigh:
      'The price has exceeded your initial estimate. Please generate a new offer.',
    failledBuyUsdcJup: 'Attempt to purchase from Jupiter failed. Please retry',
    failedBuyGm:
      'Unable to complete purchase on Galactic Market. Retrying automatically. Please do not refresh the page.',
    userRejects: 'WalletSignTransactionError',
    allRetryWarningMessage: `All transactions have failed. No funds have been deducted. Please authorize the new transaction to continue.`,
    retryFailed: `A transaction has failed. We are attempting another transaction to finalize your bundle. Please authorize the subsequent transactions.`,
    lackfundsfees: `Insufficient SOL to cover transaction fees. Please top up your account (minimum requirement: 0.025 SOL).`,
    noRoutes: `No available route to complete purchase on Jupiter. Please try again later.`,
    jupPriceTooHigh: `The exchange price has exceeded your initial estimate. Please try again.`,
  }

  const errorThatShoudGenerateAgain = (e: any) => {
    return (
      e.message === starAtlasBundleError.lackfundsJup ||
      e.message === starAtlasBundleError.lackFundsBundle ||
      e.message === starAtlasBundleError.priceBecameTooHigh ||
      e.message === starAtlasBundleError.failledBuyUsdcJup ||
      e.message === starAtlasBundleError.failedBuyGm ||
      e.message === starAtlasBundleError.lackfundsfees ||
      e.message === starAtlasBundleError.noRoutes
    )
  }

  const getERs = async (): Promise<ExchangeRates> => {
    let [atlasToUsdc, atlasToNative, usdcToNative] = await Promise.all([
      getJupExchangeRate(tokens.atlas.mint, tokens.usdc.mint),
      getJupExchangeRate(tokens.atlas.mint, tokens.native.mint),
      getJupExchangeRate(tokens.usdc.mint, tokens.native.mint),
    ])
    atlasToUsdc = atlasToUsdc * 10 ** (tokens.usdc.decimals - tokens.atlas.decimals)
    atlasToNative = atlasToNative * 10 ** (tokens.native.decimals - tokens.atlas.decimals)
    usdcToNative = usdcToNative * 10 ** (tokens.native.decimals - tokens.usdc.decimals)

    let native: ExchangeRatesToCurrency = {
      usdc: 1 / usdcToNative,
      atlas: 1 / atlasToNative,
      native: 1,
    }
    let usdc: ExchangeRatesToCurrency = {
      atlas: 1 / atlasToUsdc,
      native: usdcToNative,
      usdc: 1,
    }
    let atlas: ExchangeRatesToCurrency = {
      usdc: atlasToUsdc,
      native: atlasToNative,
      atlas: 1,
    }
    return { native, usdc, atlas } as ExchangeRates
  }

  const sumToSingleCurrency = (
    currencies: CurrencyDetails[],
    paymentCurrency: AcceptedCurrency,
    ers: ExchangeRates
  ): Payment => {
    let paymentCurrencyMint = tokens[paymentCurrency].mint
    let paymentDetails = {
      mint: paymentCurrencyMint,
      price: 0,
      maxPrice: 0,
    } as CurrencyDetails

    currencies.forEach((cur) => {
      let name = currencyToName(cur.mint)
      let er = ers[name][paymentCurrency]
      let slippage = name === paymentCurrency ? 0 : SLIPPAGE
      paymentDetails.price += cur.price * er
      paymentDetails.maxPrice = paymentDetails.maxPrice || 0
      paymentDetails.maxPrice += cur.price * er * (1 + slippage)
    })

    paymentDetails.price = Math.ceil(paymentDetails.price)
    if (paymentDetails.maxPrice) paymentDetails.maxPrice = Math.ceil(paymentDetails.maxPrice)

    let payment = {
      id: paymentCurrency,
      method: 'singleCurrency',
      details: [paymentDetails],
    } as Payment

    return payment
  }

  const getOffers = async (
    paymentCurrencies?: PaymentOption[], // which currencies to consider for single token payment
    bundleIds?: string[]
  ): Promise<{ bundle: BundleDetails; paymentOptions: Payment[] }[]> => {
    let exchangeRates = await getERs()
    let sellingOrders = await getAllSellingOrders()

    // console.log(bundlesList);
    if (!paymentCurrencies || paymentCurrencies.length === 0)
      paymentCurrencies = ['usdc', 'native', 'listingCurrency']
    // paymentCurrencies = ["listingCurrency", "usdc", "atlas", "native"];

    let allBundles = await Promise.all(
      Object.values(bundlesList)
        .filter((bundle) => !bundleIds || bundleIds.length == 0 || bundleIds.includes(bundle.id))
        .map(async (bundle) => {
          let { trs, currencies } = await getStarAtlasOrdersV2(
            bundle.items,
            exchangeRates.atlas.usdc,
            sellingOrders
          )

          // set currencies for itmes
          trs.map((tr) => {
            let idx = bundle.items.findIndex((item) => item.mint === tr.mint)
            bundle.items[idx].currency = tr.order.currencyMint
          })

          let paymentOptions: Payment[] = []

          if (paymentCurrencies) {
            if (paymentCurrencies.includes('listingCurrency')) {
              // add maxPrice to each currency
              let details = JSON.parse(JSON.stringify(currencies)) as CurrencyDetails[]
              details.forEach((d) => {
                d.maxPrice = d.price
              })

              // format payment
              paymentOptions.push({
                id: 'listingCurrency',
                method: 'listingCurrency',
                details,
              } as Payment)
            }

            paymentCurrencies.forEach((pc) => {
              if (pc !== 'listingCurrency') {
                paymentOptions.push(sumToSingleCurrency(currencies, pc, exchangeRates))
              }
            })
          }

          // set uniqueId for analytics
          bundle.uniqueId = `bundle-${bundle.id}-${Date.now()}`

          return { bundle, paymentOptions }
        })
    )

    console.log('allBundles', allBundles)

    return allBundles
  }

  const getBuddyLinkBtx = async (connection: Connection): Promise<BundleTransaction[]> => {
    if (!solanaWallet.publicKey) await solanaWallet.connect()
    if (!solanaWallet.publicKey) throw new Error('no wallet')

    let bdlIxs = await createBuddyInstructionsV2(connection)

    if (bdlIxs) {
      let tx = new Transaction().add(...bdlIxs.instructions)
      tx = await addPriorityFee({ tx, connection, fee: PRIORITIZATION_FEE })
      tx.feePayer = solanaWallet.publicKey
      tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
      let vtx = new VersionedTransaction(tx.compileMessage())

      let btx = {
        tx: vtx,
        description: 'Create Buddy Link account',
        status: 'pending',
        details: bdlIxs.details,
        priority: 0,
      } as BundleTransaction

      return [btx]
    }

    return []
  }

  const getTransactions = async (
    Data: BundleSpec
    // is_getItemTx?: boolean
  ): Promise<BundleTransaction[] | BundleError> => {
    let connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let bundleTx: BundleTransaction[] = []

    let { trs: saData, currencies } = await getStarAtlasOrdersV2(Data.items)

    // console.log("saData", saData);
    // console.log("currencies", currencies);

    await Promise.all([
      getBuddyLinkBtx(connection),
      getPayBtx({
        connection,
        currencies,
        user: Data.user,
        payment: Data.payment,
      }),
      buyMintListFromGMBtx(saData, connection, Data.user),
    ]).then((res) => {
      res.forEach((r) => {
        bundleTx.push(...r)
      })
    })

    console.log('bundleTx', bundleTx)

    return bundleTx
  }
  const getRetryTransactions = async (
    bundleTransactionData: BundleTransaction[],
    user: string
  ): Promise<
    | {
        bundleTx: BundleTransaction[]
        warning?: { reason: 'price update'; paymentDetails: CurrencyDetails[] }
      }
    | BundleError
  > => {
    let connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let bundleTx: BundleTransaction[] = []

    let successful = bundleTransactionData.filter((tx) => tx.status === 'success')
    let failed = bundleTransactionData.filter((tx) => tx.status === 'failed')
    let other = bundleTransactionData.filter(
      (tx) => tx.status !== 'success' && tx.status !== 'failed'
    )
    let jupFailed = failed.filter((tx) => 'inToken' in tx.details)
    let atlasFailed = failed.filter((tx) => 'mint' in tx.details)
    let buddyFailed = failed.filter((tx) => 'buddy' in tx.details)

    bundleTx.push(...successful)
    bundleTx.push(...other)

    if (buddyFailed.length > 0) {
      let newBuddy = await getBuddyLinkBtx(connection)
      bundleTx.push(...newBuddy)
    }

    /// Filter out the failed JUP transactions and if there are some, returns this only array
    if (jupFailed.length > 0) {
      let newJup = await getFailedJupBtx(jupFailed, connection, user)

      bundleTx.push(...newJup)
    } else {
      /// Filter out the failed SA transactions and add them to the toBuy array or updates the amount to buy
      let toBuy: ItemSpec[] = await getToBuy(atlasFailed)

      /// Get the new orders from SA
      let { trs: oneSaOrder, currencies } = await getStarAtlasOrdersV2(toBuy)

      /// Check prices and add the new transactions to the bundleTx array if more currency is needed
      let { bundleTx: jupBundleTx, newPaymentDetails } = await checkPriceAndBuyCurrencies(
        currencies,
        successful,
        connection,
        user
      )
      if (jupBundleTx.length > 0) bundleTx.push(...jupBundleTx)

      /// Add the new SA transactions to the bundleTx array
      await buyMintListFromGMBtx(oneSaOrder, connection, user).then((saBundleTxs) =>
        bundleTx.push(...saBundleTxs)
      )

      if (newPaymentDetails.length > 0) {
        return {
          bundleTx,
          warning: { reason: 'price update', paymentDetails: newPaymentDetails },
        }
      }
    }

    return { bundleTx }
  }
  const checkPriceAndBuyCurrencies = async (
    currencies: CurrencyDetails[],
    successful: BundleTransaction[],
    connection: Connection,
    user: string
  ) => {
    let newPaymentDetails: CurrencyDetails[] = []
    let bundleTx: BundleTransaction[] = []

    await Promise.all(
      currencies.map(async (cur) => {
        let wasBought = successful.find(
          (tx) => 'inToken' in tx.details && tx.details.inToken.includes(cur.mint)
        )!

        if (!!wasBought && 'inToken' in wasBought.details) {
          let toBuy: JupSwap = {
            outToken: wasBought.details.outToken,
            outAmount: cur.price,
            inToken: wasBought.details.inToken,
            inAmountLimit: wasBought.details.inAmountLimit,
          }
          if (toBuy.outAmount > wasBought.details.outAmount) {
            toBuy.outAmount = toBuy.outAmount - wasBought.details.outAmount
            let newJup = await getJupSwap({ toBuy, connection, user })

            newPaymentDetails.push({
              mint: wasBought.details.inToken,
              maxPrice: wasBought.details.inAmountLimit,
              price: newJup.priceLamports,
            })

            bundleTx.push(
              returnJupSwapItem(newJup.transaction, newJup.priceLamports, toBuy).bundltxData
            )
          }
        } else console.log('no successful tx was found buying ', cur.mint)
      })
    )
    return { bundleTx, newPaymentDetails }
  }
  const getFailedJupBtx = async (
    jupFailed: BundleTransaction[],
    connection: Connection,
    user: string
  ) => {
    let bundleTx: BundleTransaction[] = []
    await Promise.all(
      jupFailed.map(async (tx, i) => {
        if (tx.status === 'failed' && 'inToken' in tx.details) {
          await sleep(400 * i)
          const jupSwap = await getJupSwap({
            toBuy: tx.details,
            connection,
            user,
          })

          bundleTx.push(
            returnJupSwapItem(jupSwap.transaction, jupSwap.price, tx.details).bundltxData
          )
        }
      })
    )
    return bundleTx
  }
  const getToBuy = async (atlasFailed: BundleTransaction[]) => {
    let toBuy: ItemSpec[] = []
    await Promise.all(
      atlasFailed.map(async (bundleTxRaw, i) => {
        if ('mint' in bundleTxRaw.details) {
          let newToBuy = bundleTxRaw.details

          let thisIndex = toBuy.findIndex((item) => item.mint === newToBuy.mint)
          if (thisIndex === -1) toBuy.push(newToBuy)
          else {
            toBuy[thisIndex].quantity += newToBuy.quantity
          }
        }
      })
    )
    return toBuy
  }
  const getPayBtx = async (Data: {
    currencies: CurrencyDetails[]
    payment: Payment
    connection: Connection
    user: string
  }): Promise<BundleTransaction[]> => {
    let bundleTx: BundleTransaction[] = []
    let paymentCurrencies: CurrencyDetails[] = []

    if (Data.payment.method === 'listingCurrency') {
      paymentCurrencies = Data.currencies
    } else if (Data.payment.method === 'singleCurrency') {
      let currency: CurrencyDetails = {
        mint: Data.payment.details[0].mint,
        price: 0,
      }
      await Promise.all(
        Data.currencies
          .filter((cur) => Data.payment.details[0].mint !== cur.mint)
          .map(async (cur, i) => {
            await sleep(i * 350)

            let toBuy: JupSwap = {
              outToken: cur.mint,
              outAmount: cur.price,
              inToken: Data.payment.details[0].mint,
              inAmountLimit: Data.payment.details[0].maxPrice || 0,
            }
            console.log('singleCurrencies', toBuy)
            let jupSwap = await getJupSwap({
              toBuy,
              connection: Data.connection,
              user: Data.user,
            })

            let { bundltxData, inAmount } = returnJupSwapItem(
              jupSwap.transaction,
              jupSwap.priceLamports,
              toBuy
            )

            currency.price += inAmount
            bundleTx.push(bundltxData)
          })
      )
      paymentCurrencies.push(currency)
    } else {
      throw new Error('no payment method')
    }
    await checkGetPayBtxPrice(paymentCurrencies, Data.payment)
    return bundleTx
  }
  const checkGetPayBtxPrice = async (paymentCurrencies: CurrencyDetails[], payment: Payment) => {
    await Promise.all(
      paymentCurrencies.map(async (cur) => {
        let foundItem = payment.details.find((pd) => pd.mint === cur.mint)

        if (!foundItem) throw `No payment details found for ${cur.mint}`
        else if (foundItem.maxPrice && cur.price > foundItem.maxPrice)
          throw `Price for ${foundItem.mint} exceeded maxPrice (${cur.price} > ${foundItem.maxPrice})`
        else console.log('buying', cur.price, ' of ', cur.mint)
      })
    )
    return
  }
  const signTransactions = async (
    Data: BundleTransaction[],
    connection?: Connection,
    is_simulate?: boolean
  ): Promise<BundleTransaction[] | BundleError> => {
    if (!!!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    if (!connected) await solanaWallet.connect()
    if (!solanaWallet.publicKey)
      return {
        error: 'not connected',
      }
    if (!solanaWallet.signAllTransactions)
      return {
        error: 'wallet error',
        detail: 'no signAllTransactions',
      }

    let { vTxs: txs, blockheight } = await refreshVTxBlockhash(
      Data.map((tx) => tx.tx),
      connection
    )
    console.log('txs tosign', txs)
    ;(await solanaWallet.signAllTransactions(txs)).map((stx, i) => {
      Data[i].stx = stx
      Data[i].hash = bs58.encode(stx.signatures[0])

      return Data[i]
    })

    await Promise.all(
      Data.map(async (txData, i) => {
        await sleep(i * 150)
        txData.blockheight = blockheight
        try {
          if (is_simulate) {
            let simu = await connection!.simulateTransaction(txData.stx!)
            txData.status = 'broadcast'
            console.log('simu', simu)
            if (simu.value.err) simu.value.logs?.map(console.log)
          } else {
            let hash = await connection!.sendRawTransaction(txData.stx!.serialize(), {
              skipPreflight: true,
            })
            console.log(txData.description, 'hash', hash)
            txData.status = 'broadcast'
            txData.hash = hash
          }

          // Data[i].hash = hash;
        } catch (error) {
          txData.status = 'failed'
          txData.failedReason = 'failed to send' + String(error)
        }
      })
    )

    return Data
  }
  const checkTransactions = async (
    Data: BundleTransaction[],
    connection?: Connection
  ): Promise<BundleTransaction[] | BundleError> => {
    if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    let hashesToConfirm = Data.filter((tx) => tx.status === 'broadcast' && !!tx.hash).map(
      (tx) => tx.hash!
    )

    await connection
      .getTransactions(hashesToConfirm, {
        maxSupportedTransactionVersion: 0,
      })
      .then((resu) =>
        resu.map((tx) => {
          if (tx) {
            let hash = tx!.transaction.signatures[0]
            let txIndex = Data.findIndex((t) => t.hash === hash)

            if (txIndex != -1) {
              if (!!tx?.meta?.err) {
                Data[txIndex].status = 'failed'
                Data[txIndex].failedReason = JSON.stringify(tx.meta.logMessages) /// to be improved
              } else Data[txIndex].status = 'success'

              hashesToConfirm = hashesToConfirm.filter((h) => h !== hash)
            } else console.log('txIndex not found', Data, hash, txIndex)
          } else console.log('tx not found', tx)
        })
      )

    if (hashesToConfirm.length > 0) {
      let latestBheight = (await connection.getLatestBlockhash()).lastValidBlockHeight
      await Promise.all(
        hashesToConfirm.map(async (hash) => {
          let txIndex = Data.findIndex((t) => t.hash === hash)
          let txBcht = Data[txIndex].blockheight
          if (txBcht && latestBheight > txBcht + 151) {
            console.log(latestBheight, ' > ', txBcht + 151, 'unconfirmed failed', hash)
            Data[txIndex].status = 'failed'
          } else {
            console.log('too early for unconfirmed, broadcasting again', hash)
            await connection!.sendRawTransaction(Data[txIndex].stx!.serialize(), {
              skipPreflight: true,
            })
          }
          return
        })
      )
    }

    return Data
  }

  const atlasShips = [
    'HjFijcGWKgfDwGpFX2rqFwEU9jtEgFuRQAJe1ERXFsA3', //vzus solo
    '7Xs3yt9eJPuEexZrKSGVbQMXHwWUKHGeDZnM4ZksZmyS', //Fimbul Lowbie
    'FMHHwUB6amLWYhWxtiZHC2g5azy9usPTLMq46N3HEgFU', //Ogrika Mik
    '2XYd22LSFGxN7kWgoEeaXVZqgrsPeQLHLEgNhnS12Mny', //Calico ATS Enforcer
  ]
  const refreshVTxBlockhash = async (vTxs: VersionedTransaction[], connection?: Connection) => {
    if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let getLatestBlockhash = await connection.getLatestBlockhash()
    vTxs.map((vTx) => (vTx.message.recentBlockhash = getLatestBlockhash.blockhash))
    return { vTxs, blockheight: getLatestBlockhash.lastValidBlockHeight }
  }
  const refreshTxBlockhash = async (txs: Transaction[], connection?: Connection) => {
    if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let getLatestBlockhash = await connection.getLatestBlockhash()
    txs.map((tx) => (tx.recentBlockhash = getLatestBlockhash.blockhash))
    return { txs, blockheight: getLatestBlockhash.lastValidBlockHeight }
  }

  const buyTokenFromJupiter = async (toBuy: JupSwap) => {
    if (!connected) await solanaWallet.connect()

    if (!solanaWallet.publicKey) throw ' not connected'

    const connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    let { transaction: buyJupTx } = await getJupSwap({
      toBuy,
      connection,
      user: solanaWallet.publicKey.toString(),
    })

    let hash = await solanaWallet.sendTransaction(buyJupTx, connection, {
      skipPreflight: true,
    })
    console.log('hash', hash)

    const res = await validateHashV2([hash!], connection)
    if (res === true) return { hashes: [hash] }
    else {
      console.error(`could't buy atlas from jupiter, please retry`)
      throw new Error(starAtlasBundleError.failledBuyUsdcJup)
    }
  }

  const getJupSwap = async (Data: {
    toBuy: JupSwap
    connection: Connection
    user: string
    maxRetry?: number
  }): Promise<{ transaction: VersionedTransaction; price: number; priceLamports: number }> => {
    if (!!!Data.maxRetry) return await buyTokenFromJupiterIx(Data.toBuy, Data.user)
    let loop = Data.maxRetry
    let worked = false
    let txs: VersionedTransaction | undefined
    while (loop > 0)
      try {
        let buyAtlasTx = await buyTokenFromJupiterIx(Data.toBuy, Data.user)
        console.log('buyAtlas price', buyAtlasTx.price)
        // let latestBlockhash = await Data.connection.getLatestBlockhash();
        // buyAtlasTx.transaction.message.recentBlockhash = latestBlockhash.blockhash;

        let simulationData = await Data.connection.simulateTransaction(buyAtlasTx.transaction, {
          replaceRecentBlockhash: true,
        })

        if (!!simulationData.value.err) {
          console.log('simulationData', simulationData)

          if (
            (simulationData.value.err.toString().includes('AccountNotFound') ||
              String(simulationData.value.logs).includes('insufficient funds') ||
              String(simulationData.value.logs).includes('out of range for slice of length 0')) &&
            loop <= 1
          ) {
            throw new Error(starAtlasBundleError.lackfundsJup)
          } else {
            console.error(
              'retrying buy from jupiter after the following error \n',
              simulationData.value
            )
          }

          loop--
        } else {
          txs = buyAtlasTx.transaction
          loop = 0
          worked = true
          return buyAtlasTx
        }
      } catch (e) {
        loop--
      }

    throw new Error(starAtlasBundleError.failledBuyUsdcJup)
  }

  const buyTokenFromJupiterIx = async (
    toBuy: JupSwap,
    user: string
  ): Promise<{ transaction: VersionedTransaction; price: number; priceLamports: number }> => {
    console.log('toBuy', toBuy)
    if (toBuy.inToken === SystemProgram.programId.toString())
      toBuy.inToken = 'So11111111111111111111111111111111111111112'
    const quoteResponse = await (
      await fetch(
        `https://quote-api.jup.ag/v6/quote?inputMint=${toBuy.inToken}&outputMint=${
          toBuy.outToken
        }&amount=${toBuy.outAmount}&swapMode=ExactOut&slippageBps=${Math.floor(SLIPPAGE * 1e4)}`
      )
    ).json()

    if (!quoteResponse) {
      console.log('quoteResponse', quoteResponse)
      throw starAtlasBundleError.noRoutes
    }
    if (quoteResponse.inAmount > toBuy.inAmountLimit && toBuy.inAmountLimit > 0) {
      console.log('quoteResponse', quoteResponse)
      console.log(quoteResponse.inAmount, 'IN quoteResponse OUT', toBuy.inAmountLimit)
      throw {
        error: starAtlasBundleError.jupPriceTooHigh,
        priceLamports: quoteResponse.inAmount,
      }
    }
    console.log('quoteResponse', quoteResponse)

    const { swapTransaction } = await (
      await fetch('https://quote-api.jup.ag/v6/swap', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quoteResponse,
          userPublicKey: user,
          wrapAndUnwrapSol: true,
          prioritizationFeeLamports: 'auto',
        }),
      })
    ).json()

    if (!swapTransaction) {
      console.log('swapTransaction', swapTransaction)
      throw starAtlasBundleError.noRoutes
    }
    let vTransaction = VersionedTransaction.deserialize(Buffer.from(swapTransaction, 'base64'))

    return {
      transaction: vTransaction,
      priceLamports: parseInt(quoteResponse.inAmount),
      price:
        parseInt(quoteResponse.inAmount) /
        nameToCurrencyData(currencyToName(toBuy.inToken)).decimals ** 10,
    }
  }

  const buyMintListFromGMIx = async (
    toBuy: InOutAtlasBundle
  ): Promise<
    | {
        trs: { ixs: TransactionInstruction[]; priceBought: number; mint: string }[]
        items: { mint: string; nbTx: { number: number; amounts: number[] } }[]
      }
    | undefined
  > => {
    if (!connected) await solanaWallet.connect()

    const gmClientService = new GmClientService()

    const starAlasData = await getStarAtlasOrders(toBuy)
    if (!!!starAlasData) return
    const { trs: buyOrders, items } = starAlasData
    console.log('buyOrders', buyOrders)

    const clusterOrUrl = process.env.REACT_APP_SOLANA_NETWORK!
    let connection = new Connection(clusterOrUrl)
    let starAtlasProgram = new PublicKey(process.env.REACT_APP_SOLANA_STARATLAS_PROGRAM!)

    if (!solanaWallet.publicKey) throw 'error getting Publickey111'
    const userPublicKey = solanaWallet.publicKey
    const returnData = {
      trs: await Promise.all(
        buyOrders.map(async (orderData, i) => {
          // console.log("\nXXXX\n ", orderData.order, orderData);
          // await sleep(500 * i);
          const exchangeTx = await gmClientService.getCreateExchangeTransaction(
            connection,
            orderData.order,
            userPublicKey,
            orderData.quantity,
            starAtlasProgram
          )
          const buyPrice = orderData.order.priceForQuantity(orderData.quantity)

          let ixs = exchangeTx.transaction.instructions
          if (atlasShips.includes(orderData.mint) && toBuy.neoswapFee.amount > 0)
            ixs.push(
              addFeeIx({
                retryData: toBuy,
                user: userPublicKey,
              })
            )
          return {
            ixs: exchangeTx.transaction.instructions,
            priceBought: buyPrice,
            mint: orderData.mint,
          }
        })
      ),
      items,
    }
    if (returnData.trs.length === 0) throw new Error(starAtlasBundleError.failedBuyGm)
    return returnData
    // }
  }

  const buyMintListFromGMBtx = async (
    buyData: ItemOrder[],
    connection: Connection,
    user: string
  ): Promise<BundleTransaction[]> => {
    let gmClientService = new GmClientService()
    // let currencies: PaymentCurrencies[] = [];
    let latestbch = (await connection.getLatestBlockhash()).blockhash

    let returnData: BundleTransaction[] = await Promise.all(
      buyData.map(async (orderData, i) => {
        console.log('XXXX', orderData.order, orderData)
        await sleep(500 * i)
        const exchangeTx = await gmClientService.getCreateExchangeTransaction(
          connection,
          orderData.order,
          new PublicKey(user),
          orderData.quantity,
          new PublicKey(process.env.REACT_APP_SOLANA_STARATLAS_PROGRAM!)
        )
        exchangeTx.transaction = await updateIxToIdempotent({
          transaction: exchangeTx.transaction,
          order: orderData.order,
          user,
        })
        let currency = currencyToName(orderData.order.currencyMint)
        let buyPrice = nameToCurrencyData(
          currency,
          orderData.order.priceForQuantity(orderData.quantity)
        ).amountLamports

        // console.log('currencyData decimals', decimals)

        let ixs = exchangeTx.transaction.instructions

        let fee = Math.ceil(NS_FEE_RATE * buyPrice)
        ixs.push(
          addFeeIxV2({
            amount: fee,
            currency,
            nsRevenue: new PublicKey(NS_FEE_ADDRESS),
            user: new PublicKey(user),
          })
        )

        console.log(ixs.length, 'buyPrice ', buyPrice, ' + fee ', fee, ' = ', buyPrice + fee)
        // ixs.forEach((ix, i) => {
        //     console.log("ix", i, ix);
        // });

        let tx = new Transaction().add(...ixs)
        tx = await addPriorityFee({ tx, connection, fee: PRIORITIZATION_FEE })
        tx.feePayer = new PublicKey(user)
        tx.recentBlockhash = latestbch

        return returnAtlasItem(tx, orderData, buyPrice + fee, currency)
      })
    )
    return returnData
  }
  const returnJupSwapItem = (
    tx: VersionedTransaction,
    price: number,
    toBuy: JupSwap
  ): { bundltxData: BundleTransaction; inAmount: number } => {
    let inTokenAbbr = tokens[currencyToName(toBuy.inToken)].abbr
    let outTokenAbbr = tokens[currencyToName(toBuy.outToken)].abbr
    return {
      bundltxData: {
        tx,
        description: `Swap ${inTokenAbbr} to ${outTokenAbbr}`,
        priority: 0,
        status: 'pending',
        details: toBuy,
      },
      inAmount: price,
    }
  }
  const returnAtlasItem = (
    tx: Transaction,
    orderData: ItemOrder,
    priceBought: number,
    currency: AcceptedCurrency
  ): BundleTransaction => {
    let name = mintToItem(orderData.mint)?.name || orderData.mint
    return {
      description: `Buy ${name} (x${orderData.quantity})`,
      priority: 1,
      status: 'pending',
      details: {
        currency: orderData.order.currencyMint,
        mint: orderData.mint,
        quantity: orderData.quantity,
      } as ItemSpec,
      tx: new VersionedTransaction(tx.compileMessage()),
      priceBought,
      currency,
      item: orderData,
    } as BundleTransaction
  }

  const getStarAtlasOrders = async (
    toBuy: InOutAtlasBundle
  ): Promise<
    | {
        trs: ItemOrder[]
        items: { mint: string; nbTx: { number: number; amounts: number[] } }[]
      }
    | undefined
  > => {
    const gmClientService = new GmClientService()
    let starAtlasProgram = new PublicKey(process.env.REACT_APP_SOLANA_STARATLAS_PROGRAM!)
    let connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    const allOrders = (await gmClientService.getAllOpenOrders(connection, starAtlasProgram)).filter(
      (order) => {
        return order.uiPrice !== 0
      }
    )

    // console.log("allOrders", allOrders);

    const sellingOrders = allOrders.filter((order) => order.orderType === 'sell')

    let trs: ItemOrder[] = []
    let items: { mint: string; nbTx: { number: number; amounts: number[] } }[] = []

    for (let index = 0; index < toBuy.gMListToBuy.length; index++) {
      // let amount = 0;
      // let retrynbs: number[] = [];
      const item = toBuy.gMListToBuy[index]
      console.log('item', item)

      if (item.quantity > 0) {
        let iitem: { mint: string; nbTx: { number: number; amounts: number[] } } = {
          mint: item.mint,
          nbTx: { number: 0, amounts: [] },
        }

        //@ts-ignore
        let currencyPk = item.currencyMint

        let orders = sellingOrders
          .filter((order) => order.orderMint.includes(item.mint))
          .filter((order) => order.currencyMint.includes(currencyPk))
          .sort((orderA, orderB) => orderA.uiPrice - orderB.uiPrice)
        if (orders.length === 0) throw 'no orders found'

        let quantity = item.quantity
        for (let indexOrder = 0; indexOrder < orders.length; indexOrder++) {
          const order = orders[indexOrder]

          if (!!item.maxPrice) {
            let maxPriceUnit = item.maxPrice / item.quantity
            if (order.price.toNumber() > maxPriceUnit) {
              console.log(
                `item too pricy ${order.price.toNumber()} (front) > (back) ${maxPriceUnit}`
              )

              throw new Error(starAtlasBundleError.priceBecameTooHigh)
            }
          }
          console.log(
            indexOrder,
            item.mint,
            order.orderQtyRemaining,
            '(GM) QUANTITIES (rest)',
            quantity,
            ' paid in ',
            currencyPk
          )

          if (order.orderQtyRemaining >= quantity) {
            trs.push({
              order: order,
              mint: item.mint,
              quantity: quantity,
              maxPrice: item.maxPrice,
            })
            iitem.nbTx.number++
            iitem.nbTx.amounts.push(quantity)
            items.push(iitem)
            break
          } else {
            trs.push({
              order: order,
              mint: item.mint,
              quantity: order.orderQtyRemaining,
              maxPrice: item.maxPrice,
            })
            iitem.nbTx.number++
            iitem.nbTx.amounts.push(order.orderQtyRemaining)
            quantity -= order.orderQtyRemaining
          }
        }
      } else console.log('already has hash', item)
    }
    if (trs.length > 0) {
      return { trs, items }
    } else return
  }

  const getOrdersPrice = (trs: ItemOrder[], toBuy?: ItemSpec[]): CurrencyDetails[] => {
    let details: Record<string, CurrencyDetails> = {}
    trs.map((order) => {
      let currencyMint = order.order.currencyMint
      let { amountLamports: buyPrice } = nameToCurrencyData(
        currencyToName(currencyMint),
        order.order.priceForQuantity(order.quantity)
      )
      if (!details[currencyMint])
        details[currencyMint] = { mint: currencyMint, price: 0, maxPrice: 0 }
      details[currencyMint].price += buyPrice
    })

    if (toBuy) {
      toBuy
        .filter((item) => item.type === 'token')
        .map((item) => {
          let currencyMint = item.mint
          let decimals = nameToCurrencyData(currencyToName(currencyMint)).decimals
          if (!details[currencyMint])
            details[currencyMint] = { mint: currencyMint, price: 0, maxPrice: 0 }
          details[currencyMint].price += item.quantity * 10 ** decimals
        })
    }

    return Object.values(details)
  }

  const getItemOrdersForCurrency = (item: ItemSpec, sellingOrders: Order[]): ItemOrder[] => {
    let trs: ItemOrder[] = []
    let orders = sellingOrders
      .filter((order) => order.orderMint === item.mint && order.currencyMint === item.currency)
      .sort((orderA, orderB) => orderA.uiPrice - orderB.uiPrice)

    let quantityRequired = item.quantity
    while (quantityRequired > 0) {
      if (orders.length === 0) break

      let order = orders.shift()!
      let quantity = Math.min(order.orderQtyRemaining, quantityRequired)
      quantityRequired -= quantity

      trs.push({
        order: order,
        mint: item.mint,
        quantity: quantity,
      })
    }

    if (quantityRequired > 0) {
      // console.log("not enough quantity", item, quantityRequired);
      throw 'not enough quantity'
    }
    return trs
  }

  const getItemOrders = (
    itemOrg: ItemSpec,
    sellingOrders: Order[],
    atlasToUsdc?: number
  ): ItemOrder[] => {
    let trs: ItemOrder[]
    let item = JSON.parse(JSON.stringify(itemOrg)) as ItemSpec
    if (atlasToUsdc) {
      // pick the best currency
      let atlasOrders: ItemOrder[] = []
      let usdcOrders: ItemOrder[] = []
      let atlasPrice, usdcPrice
      try {
        atlasOrders = getItemOrdersForCurrency(
          { ...item, currency: tokens.atlas.mint },
          sellingOrders
        )
        atlasPrice = getOrdersPrice(atlasOrders)[0].price
      } catch (error) {
        // console.log("no atlas orders", error);
      }
      try {
        usdcOrders = getItemOrdersForCurrency(
          { ...item, currency: tokens.usdc.mint },
          sellingOrders
        )
        usdcPrice = getOrdersPrice(usdcOrders)[0].price
      } catch (error) {
        // console.log("no usdc orders", error);
      }

      if (!atlasPrice && !usdcPrice) throw 'no orders'
      else if (!atlasPrice) trs = usdcOrders
      else if (!usdcPrice) trs = atlasOrders
      else if (atlasPrice * atlasToUsdc < usdcPrice) {
        trs = atlasOrders
      } else {
        trs = usdcOrders
      }
    } else if (item.currency) {
      trs = getItemOrdersForCurrency(item, sellingOrders)
    } else {
      throw 'no currency or atlasToUsdc'
    }

    return trs
  }

  const getAllSellingOrders = async (): Promise<Order[]> => {
    const gmClientService = new GmClientService()
    let starAtlasProgram = new PublicKey(process.env.REACT_APP_SOLANA_STARATLAS_PROGRAM!)
    let connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    return (await gmClientService.getAllOpenOrders(connection, starAtlasProgram)).filter(
      (order) => order.uiPrice !== 0 && order.orderType === 'sell'
    )
  }

  const getStarAtlasOrdersV2 = async (
    toBuy: ItemSpec[],
    atlasToUsdc?: number,
    sellingOrders?: Order[]
  ): Promise<{
    trs: ItemOrder[]
    currencies: CurrencyDetails[]
  }> => {
    if (!sellingOrders) sellingOrders = await getAllSellingOrders()
    // console.log("toBuy", toBuy)

    let trs: ItemOrder[] = toBuy
      .filter((item) => item.type !== 'token')
      .map((item) => {
        return getItemOrders(item, sellingOrders || [], atlasToUsdc)
      })
      .flat()
    console.log('trs', trs)

    let currencies: CurrencyDetails[] = getOrdersPrice(trs, toBuy)

    currencies = currencies.map((cur) => {
      cur.price = Math.ceil(cur.price * (1 + NS_FEE_RATE))
      if (cur.maxPrice) cur.maxPrice = Math.ceil(cur.maxPrice * (1 + NS_FEE_RATE))
      return cur
    })

    return { trs, currencies }
  }

  const sendLamportsIx = async (Data: {
    from: PublicKey
    to: PublicKey
    mint?: PublicKey
    uiAmount: number
    connection?: Connection
    mintAta?: string[]
  }): Promise<{ ixs: TransactionInstruction[]; mintAta: string[] }> => {
    let mintAta: string[] = []
    // console.log(Data.mint.toBase58(), " Token to send ", Data.uiAmount);

    if (!!Data.mintAta) mintAta = Data.mintAta
    if (!solanaWallet.publicKey) throw 'solanaWallet.publicKey is undefined'
    if (!Data.connection) Data.connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let ixs: TransactionInstruction[] = []
    if (!Data.mint) Data.mint = SystemProgram.programId

    if (!Data.mint.equals(SystemProgram.programId)) {
      let { mintAta: fromRevenueAta, instruction: fromAtaIx } = await nS.UTILS.findOrCreateAta({
        mint: Data.mint,
        owner: Data.from,
        signer: solanaWallet.publicKey,
        connection: Data.connection,
      })
      if (!!fromAtaIx && !mintAta?.includes(fromRevenueAta.toBase58())) {
        console.log('destAta', fromRevenueAta.toBase58())
        ixs.push(fromAtaIx)
        mintAta.push(fromRevenueAta.toBase58())
      }
      let { mintAta: toRevenueAta, instruction: destAtaIx } = await nS.UTILS.findOrCreateAta({
        mint: Data.mint,
        owner: Data.to,
        signer: solanaWallet.publicKey,
        connection: Data.connection,
      })

      if (!!destAtaIx && !mintAta?.includes(toRevenueAta.toBase58())) {
        console.log('destAta', toRevenueAta.toBase58())
        ixs.push(destAtaIx)
        mintAta.push(toRevenueAta.toBase58())
      }
      ixs.push(
        createTransferInstruction(
          fromRevenueAta,
          toRevenueAta,
          solanaWallet.publicKey,
          Data.uiAmount,
          [],
          TOKEN_PROGRAM_ID
        )
      )
    } else {
      let iix = SystemProgram.transfer({
        fromPubkey: Data.from,
        toPubkey: Data.to,
        lamports: Data.uiAmount,
      })
      ixs.push(iix)
    }
    return { ixs, mintAta }
  }
  const uncheckedSendLamportsIx = (Data: {
    from: PublicKey
    to: PublicKey
    amountLamports: number
    mint?: PublicKey
  }): TransactionInstruction => {
    if (!Data.mint) Data.mint = SystemProgram.programId
    let splAta = new PublicKey(process.env.REACT_APP_SOLANA_SPL_ATA!)

    if (!Data.mint.equals(SystemProgram.programId)) {
      let fromRevenueAta = PublicKey.findProgramAddressSync(
        [Data.from.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), Data.mint.toBuffer()],
        splAta
      )[0]

      let toRevenueAta = PublicKey.findProgramAddressSync(
        [Data.to.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), Data.mint.toBuffer()],
        splAta
      )[0]

      return createTransferInstruction(
        fromRevenueAta,
        toRevenueAta,
        solanaWallet.publicKey!,
        Data.amountLamports,
        [],
        TOKEN_PROGRAM_ID
      )
    } else {
      return SystemProgram.transfer({
        fromPubkey: Data.from,
        toPubkey: Data.to,
        lamports: Data.amountLamports,
      })
    }
  }
  const createBuddyInstructionsV2 = async (
    connection?: Connection
  ): Promise<{ instructions: TransactionInstruction[]; details: BuddyDetails } | undefined> => {
    let userReferralLink: string | null | undefined = localStorage.getItem('buddyLink')

    if (isNil(userReferralLink)) return

    if (!connected) await solanaWallet.connect()

    if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    if (!solanaWallet.publicKey) throw ' not connected'

    const client = new Client(
      connection,
      solanaWallet.publicKey
      // process.env.BUDDYLINK_PROGRAM_ID // TODO: add to env
    )

    const ORGANIZATION_NAME = 'staratlas'

    let members = await client.member.getByWallet(ORGANIZATION_NAME, solanaWallet.publicKey)
    let member = members[0]
    console.log('members', members)

    if (member) {
      // return { member };
      return
    }

    let buddy = await client.buddy.getProfile()

    const memberName = Client.generateMemberName()
    console.log('memberName', memberName)

    const buddyName = buddy?.account.name || Client.generateProfileName()
    console.log('buddyName', buddyName)

    const buddyPDA = client.pda.getProfilePDA(buddyName)
    console.log('buddyPDA', buddyPDA)

    let instructions: TransactionInstruction[] = []

    instructions.push(
      ...(await client.initialize.createMember(
        ORGANIZATION_NAME,
        memberName,
        userReferralLink,
        null,
        buddyName
      ))
    )

    instructions.push(
      ...(await client.initialize.createMemberStatistics(ORGANIZATION_NAME, memberName))
    )

    let atlasMint = new PublicKey(process.env.REACT_APP_SOLANA_ATLASMINT!)

    if (buddy) {
      const treasuryPDA = client.pda.getTreasuryPDA([buddy.account.pda], [10_000], atlasMint)
      console.log('treasuryPDA', treasuryPDA)

      const treasury = await client.treasury.getByPDA(treasuryPDA)
      console.log('treasury', treasury)

      if (!treasury) {
        instructions.push(
          ...(await client.initialize.createTreasuryByBuddyPDA(buddyPDA, atlasMint))
        )
      }
    } else {
      instructions.push(...(await client.initialize.createTreasuryByBuddyPDA(buddyPDA, atlasMint)))
    }
    console.log('instructions', instructions)

    let details = {
      organizationName: ORGANIZATION_NAME,
      memberName,
      buddyName,
      referrerHash: userReferralLink,
    } as BuddyDetails

    return { instructions, details }
  }
  const getStarAtlasBundle = async (toBuy: InOutAtlasBundle) => {
    if (!connected) await solanaWallet.connect()

    if (!solanaWallet.publicKey) throw ' not connected'
    let atlasMint = new PublicKey(process.env.REACT_APP_SOLANA_ATLASMINT!)
    let usdcMint = new PublicKey(process.env.REACT_APP_SOLANA_USDCMINT!)

    const connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    const checkbal = (await connection.getBalance(solanaWallet.publicKey)) / LAMPORTS_PER_SOL
    let firstCheck = true
    console.log('sol balance', checkbal * LAMPORTS_PER_SOL)

    let allRetry = 10
    let retryData = toBuy
    let hashes: string[] = []

    while (allRetry > 0) {
      try {
        if (checkbal < 0.025 && !!firstCheck) {
          console.log('checkbal', checkbal)
          throw new Error(starAtlasBundleError.lackfundsfees)
        } else firstCheck = false

        let bdlIxs = await createBuddyInstructionsV2(connection)
        console.log('bdlIxs', bdlIxs)

        let sendallData = await getOneStarAtlasBundle({
          retryData,
          user: solanaWallet.publicKey,
          currencyMint:
            toBuy.currency === 'atlas'
              ? atlasMint
              : toBuy.currency === 'usdc'
              ? usdcMint
              : SystemProgram.programId,
          connection,
        })
        console.log('sendallData', sendallData)

        let preSendAllvTxs: VersionedTransaction | undefined
        let has_buddy = false
        let success_buddy = false
        if (!!bdlIxs && 'instructions' in bdlIxs && !!!success_buddy) {
          console.log('added buddyLink Ix')
          let tx = new Transaction().add(...bdlIxs.instructions)
          tx.feePayer = solanaWallet.publicKey
          tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
          preSendAllvTxs = new VersionedTransaction(tx.compileMessage())
          has_buddy = true
        }

        let sendAllvTxs = []
        if (!!preSendAllvTxs) sendAllvTxs.push(preSendAllvTxs)
        sendAllvTxs.push(...sendallData.txs)

        if (!solanaWallet.signAllTransactions) throw 'no solanaWallet.signAllTransactions'
        let signedTx = await solanaWallet.signAllTransactions(sendAllvTxs)
        console.log('get SA signedTx', signedTx)

        let delaytime = 0
        let hashs = await Promise.all(
          signedTx.map(async (tx) => {
            // await sleep((delaytime += 150));
            return await connection.sendRawTransaction(tx.serialize(), {
              skipPreflight: true,
            })
          })
        )
        console.log('hashs', hashs)

        if (!retryData.neoswapFee.hash) retryData.neoswapFee.hash = []

        if (!success_buddy && has_buddy) {
          let tempHash = hashs[0]
          hashs = hashs.slice(1)
          console.log('BuddyLink tempHash', tempHash)
          hashes.push(tempHash)

          const bdRes = await validateHashV2([tempHash], connection)

          if (bdRes !== true) {
            tempHash = 'RETRY-//-' + tempHash
            console.log('buddyLink Failed', bdRes, tempHash)
            has_buddy = false
          } else {
            success_buddy = true
            console.log('buddyLink Success')
          }
        }

        for (let hashIndex = retryData.gMListToBuy.length - 1; hashIndex >= 0; hashIndex--) {
          if (retryData.gMListToBuy[hashIndex].quantity > 0) {
            let nbtxData = sendallData.items.filter(
              (item) => item.mint === retryData.gMListToBuy[hashIndex].mint
            )[0]
            let nbtx = nbtxData.nbTx.number ? nbtxData.nbTx.number : 1

            while (nbtx > 0) {
              let tempHash = hashs.pop()!
              hashes.push(tempHash)

              console.log('validating hash : ', tempHash, retryData.gMListToBuy[hashIndex])
              const res = await validateHashV2([tempHash], connection)
              console.log(res, tempHash, nbtx - 1, 'nbtxData.nbTx.amounts', nbtxData.nbTx.amounts)

              if (res !== true) {
                tempHash = 'RETRY-//-' + tempHash
              } else {
                retryData.gMListToBuy[hashIndex].quantity -= nbtxData.nbTx.amounts[nbtx - 1]
                if (
                  atlasShips.includes(nbtxData.mint) &&
                  nbtx == 1 &&
                  retryData.neoswapFee.amount > 0
                ) {
                  retryData.neoswapFee.hash.push(tempHash)
                  retryData.neoswapFee.amount = 0
                }
              }
              if (!retryData.gMListToBuy[hashIndex].hash)
                retryData.gMListToBuy[hashIndex].hash = [tempHash]
              // @ts-ignore
              else retryData.gMListToBuy[hashIndex].hash.push(tempHash)

              nbtx--
            }

            console.log('final item', retryData.gMListToBuy[hashIndex])
          }
        }

        console.log('NS fee hashs', retryData.neoswapFee.hash)
        if (retryData.neoswapFee.amount > 0) throw new Error(starAtlasBundleError.retryFailed)

        retryData.gMListToBuy.map((gmItem) => {
          if (gmItem.quantity > 0) {
            throw new Error(starAtlasBundleError.retryFailed)
          }
        })

        allRetry = 0

        return { hashes, allData: retryData }
      } catch (e) {
        if (!!errorThatShoudThrowAtlas(e)) {
          allRetry = 0
          throw e
        }
        toast(hashes.length > 0 
          ? starAtlasBundleError.retryFailed 
          : starAtlasBundleError.allRetryWarningMessage, {
            duration: 9000,
          });
        console.warn('retrying all after the following error \n', e)
        allRetry--
      }
    }
  }
  const getOneStarAtlasBundle = async (Data: {
    retryData: InOutAtlasBundle
    user: PublicKey
    currencyMint: PublicKey
    connection: Connection
  }): Promise<{
    txs: VersionedTransaction[]
    items: { mint: string; nbTx: { number: number; amounts: number[] } }[]
    isBuyJup: boolean
  }> => {
    console.log('getOneStarAtlasBundle Retry Data', Data.retryData)
    let retry = 5
    while (retry >= 0) {
      let items: { mint: string; nbTx: { number: number; amounts: number[] } }[] = []
      let broadcastVTx: TransactionInstruction[][] = []
      let sendTxs: VersionedTransaction[] = []
      let dummyRecentBlockhash = (await Data.connection.getLatestBlockhash()).blockhash
      let isBuyJup = false

      let currencyUsedInGm = 0

      if (Data.retryData.gMListToBuy.length > 0) {
        let buyFromGMData = await buyMintListFromGMIx(Data.retryData)
        if (!!buyFromGMData) {
          buyFromGMData.trs.map((acc) => {
            currencyUsedInGm += acc.priceBought
            broadcastVTx.push(acc.ixs)
          })

          items.push(...buyFromGMData.items)
        }
        console.log(
          'Atlas consumed in GM ',
          currencyUsedInGm,
          '\n for these mint\n',
          Data.retryData.gMListToBuy.map((item) => {
            return { mint: item.mint, amount: item.quantity }
          })
        )
      }
      let currencyFromSwap = 0

      sendTxs.push(
        ...broadcastVTx.map((tx) => {
          let sendAllTx = new Transaction().add(...tx)
          sendAllTx.feePayer = Data.user
          sendAllTx.recentBlockhash = dummyRecentBlockhash
          return new VersionedTransaction(sendAllTx.compileMessage())
        })
      )
      // let feeUiAmount = 0;

      // let mintAta: string[] = [];
      // if (Data.retryData.neoswapFee.amount > 0) {
      //     feeUiAmount = 0;
      //     switch (Data.retryData.currency) {
      //         case "usdc":
      //             feeUiAmount = Math.ceil(Data.retryData.neoswapFee.amount * 10 ** 6);
      //             break;
      //         case "atlas":
      //             feeUiAmount = Math.ceil(Data.retryData.neoswapFee.amount * 10 ** 8);
      //             break;
      //         case "native":
      //             feeUiAmount = Math.ceil(
      //                 Data.retryData.neoswapFee.amount * LAMPORTS_PER_SOL
      //             );
      //             break;

      //         default:
      //             feeUiAmount = Data.retryData.neoswapFee.amount;
      //             break;
      //     }

      //     let nsRevenue = new PublicKey(Data.retryData.neoswapFee.address);
      //     // // let nsFeeData = await sendLamportsIx({
      //     //     from: Data.user,
      //     //     to: nsRevenue,
      //     //     mint: Data.currencyMint,
      //     //     uiAmount: feeUiAmount,
      //     //     mintAta,
      //     //     connection: Data.connection,
      //     // });

      //     // mintAta = nsFeeData.mintAta;
      //     // const sendFeeTx = new Transaction().add(...nsFeeData.ixs);
      //     sendFeeTx.feePayer = Data.user;
      //     sendFeeTx.recentBlockhash = (await Data.connection.getLatestBlockhash()).blockhash;
      //     const sendFeevTx = new VersionedTransaction(sendFeeTx.compileMessage());
      //     sendTxs.push(sendFeevTx);
      //     console.log(
      //         "Added Fees for NeoSwap",
      //         Data.retryData.neoswapFee.amount,
      //         " to ",
      //         nsRevenue.toBase58()
      //     );
      // }

      let balance =
        Data.retryData.currency === 'native'
          ? await Data.connection.getBalance(Data.user)
          : (
              await Data.connection.getTokenAccountBalance(
                (
                  await nS.UTILS.findOrCreateAta({
                    connection: Data.connection,
                    owner: Data.user,
                    signer: Data.user,
                    mint: Data.currencyMint,
                  })
                ).mintAta
              )
            ).value.uiAmount

      // console.log(currencyFromSwap, Data.retryData.neoswapFee.amount, currencyUsedInGm);

      let toUse = currencyFromSwap + Data.retryData.neoswapFee.amount
      if (Data.retryData.currency === 'atlas') toUse += currencyUsedInGm
      console.log('to Use in Bundle:', toUse)

      if (!balance || balance < toUse) {
        console.log('balance', balance)
        throw new Error(starAtlasBundleError.lackFundsBundle)
      }
      console.log('number of transactions to simulate', sendTxs.length)

      if (!!(await validateSimulateTx(sendTxs, Data.connection))) {
        return { txs: sendTxs, items, isBuyJup }
      } else {
        retry--
      }
    }
    throw new Error(starAtlasBundleError.maxAllRetry)
  }

  const validateHash = async (
    hashs: string[],
    connection: Connection
  ): Promise<true | { hash: string; valid: boolean; error?: string }[]> => {
    let bcdata = await connection.getLatestBlockhash()

    const allDatas: { hash: string; valid: boolean; error?: string }[] = await Promise.all(
      hashs.map(async (hash) => {
        try {
          console.log('waiting blockchain for ', hash)
          const confTx = await connection.confirmTransaction({
            signature: hash,
            ...bcdata,
          })
          if (confTx.value.err) return { hash, error: String(confTx.value.err), valid: false }
          else return { hash, valid: true }
        } catch (error) {
          console.log(hash, 'error', error)
          return { hash, error: String(error), valid: false }
        }
      })
    )

    let returnBool = true
    allDatas.forEach(async (hashData) => {
      if (hashData.valid === false) returnBool = false
    })
    if (!returnBool) return allDatas
    return true
  }

  const validateHashV2 = async (
    hashs: string[],
    connection: Connection
  ): Promise<true | { hash: string; valid: boolean; error?: string }[]> => {
    let retry = 30
    let allDatas: { hash: string; valid: boolean; error?: string }[] = []
    while (retry > 0) {
      let txDatas = await connection.getTransactions(hashs, {
        maxSupportedTransactionVersion: 0,
        commitment: 'confirmed',
      })
      console.log('txDatas', txDatas)

      if (txDatas.filter((txResp) => txResp === null).length === 0) {
        allDatas = txDatas.map((txResp) => {
          if (txResp?.meta?.err)
            return {
              hash: txResp.transaction.signatures[0],
              error: JSON.stringify(txResp.meta.logMessages),
              valid: false,
            }
          // @ts-ignore
          else return { hash: txResp.transaction.signatures[0], valid: true }
        })
        retry = 0
      } else {
        // await sleep(1500);
        retry--
      }
    }

    let returnBool = true
    allDatas.forEach(async (hashData) => {
      if (hashData.valid === false || !hashData) returnBool = false
    })
    if (!returnBool) return allDatas
    return true
  }
  const validateSimulateTx = async (
    sendTxs: VersionedTransaction[],
    connection: Connection
  ): Promise<boolean> => {
    let validatedTx: { tx: VersionedTransaction; validated: boolean; error?: any }[] = []

    let recentBlockhash = (await connection.getLatestBlockhash()).blockhash
    sendTxs.map((tx) => {
      tx.message.recentBlockhash = recentBlockhash
      validatedTx.push({ tx, validated: false })
    })

    await Promise.all(
      validatedTx.map(async (txData, i) => {
        console.log('simulating transaction ', i, ' ...')

        let simulationData = await connection.simulateTransaction(txData.tx, {
          replaceRecentBlockhash: true,
        })
        if (simulationData.value.err) {
          console.log('simulationData', simulationData)
          txData.error += String(simulationData.value.logs)
          console.error(
            `Error sending transaction ${i} simulating transaction ${
              String(simulationData.value.err) + simulationData.value.logs
            }`
          )
        } else txData.validated = true
      })
    )
    validatedTx.map((txData) => {
      if (!txData.validated) {
        console.log('txData', txData)
        throw validatedTx
      }
    })

    return true
  }
  const errorThatShoudThrowAtlas = (error: any) => {
    if (
      String(error).includes(starAtlasBundleError.lackfundsJup) ||
      String(error).includes(starAtlasBundleError.maxAllRetry) ||
      String(error).includes(starAtlasBundleError.lackFundsBundle) ||
      String(error).includes(starAtlasBundleError.priceBecameTooHigh) ||
      String(error).includes(starAtlasBundleError.lackfundsfees) ||
      // String(error).includes(starAtlasBundleError.failledBuyUsdcJup) ||
      String(error).includes(starAtlasBundleError.userRejects)
    )
      return true
  }

  const buySkin = async (Data: {
    skinSelected: AcceptedSkin
    maxPaymentLamports?: number
    currencyMint?: string
    paymentMint?: string
    skipSwap?: boolean
  }): Promise<BuySkinFeedback> => {
    if (!connected) await solanaWallet.connect()

    if (!solanaWallet.publicKey) {
      return {
        error: 'not connected',
        success: false,
      }
    }
    const connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    const starAtlasProgram = new PublicKey(process.env.REACT_APP_SOLANA_STARATLAS_PROGRAM!)
    // const atlasMint = new PublicKey(process.env.REACT_APP_SOLANA_ATLASMINT!);
    const checkbal = (await connection.getBalance(solanaWallet.publicKey)) / LAMPORTS_PER_SOL
    if (checkbal < 0.0025) return { success: false, error: 'not enough sol', balance: checkbal }
    let swapTx: VersionedTransaction | undefined

    try {
      // while (retry > 0) {
      const skinData = mapName(Data.skinSelected)
      if (Data.currencyMint) skinData.currencyMint = Data.currencyMint
      if (Data.paymentMint) skinData.paidMint = Data.paymentMint

      if (!!Data.maxPaymentLamports && !Data.skipSwap) {
        let balanceData = await checkTokenBalance({
          owner: solanaWallet.publicKey,
          mint: new PublicKey(skinData.paidMint),
          maxPriceLamport: Data.maxPaymentLamports,
          connection,
        })

        if ('error' in balanceData) return balanceData
      }
      const mintData = await getMintData(skinData, connection)

      if (!mintData) {
        return {
          success: false,
          error: 'priceCheckFailed',
          errorLog: 'no mint data',
        }
      }

      let basePriceLamport = mintData.price.toNumber()
      let feeLamport = Math.ceil(basePriceLamport * NS_FEE_RATE)
      let priceCurrencyLamports = basePriceLamport + feeLamport

      if (!Data.skipSwap) {
        console.log(
          'maxPaymentLamports',
          Data.maxPaymentLamports,
          'priceCurrencyLamports',
          priceCurrencyLamports
        )
        try {
          swapTx = (
            await getJupSwap({
              connection,
              toBuy: {
                outToken: skinData.currencyMint,
                outAmount: priceCurrencyLamports,
                inToken: skinData.paidMint,
                inAmountLimit: Data.maxPaymentLamports || 0,
              },
              user: solanaWallet.publicKey.toString(),
              // maxRetry:3
            })
          ).transaction
          console.log('added Transaction to buy from Jup', swapTx)
        } catch (error: any) {
          console.log('error', error)
          if (error?.error && String(error.error).includes(starAtlasBundleError.jupPriceTooHigh)) {
            let newPriceLamports = ceilToSignificantDigits(error.priceLamports, 3)
            let newPriceCurrency = newPriceLamports / 10 ** skinData.paidDecimals
            return {
              error: 'price exceeded',
              success: false,
              price: newPriceCurrency,
              priceLamports: newPriceLamports,
            }
          }
          return {
            success: false,
            error: 'other',
            errorLog: String(error),
          }
        }
      } else {
        console.log('buying from Jupiter Skipped')
      }

      let buySkinTx = new Transaction()
        .add(
          ...(
            await new GmClientService().getCreateExchangeTransaction(
              connection,
              mintData,
              solanaWallet.publicKey,
              1,
              starAtlasProgram
            )
          ).transaction.instructions
        )
        .add(
          uncheckedSendLamportsIx({
            from: solanaWallet.publicKey,
            to: new PublicKey(NS_FEE_ADDRESS),
            mint: new PublicKey(skinData.currencyMint),
            amountLamports: feeLamport,
          })
        )

      buySkinTx = await addPriorityFee({ tx: buySkinTx, connection })

      let ltbc = await connection.getLatestBlockhash()
      console.log('ltbc', ltbc)
      let ltbch = ltbc.blockhash

      buySkinTx.feePayer = solanaWallet.publicKey
      buySkinTx.recentBlockhash = ltbch
      let buySkinVTx = new VersionedTransaction(buySkinTx.compileMessage())
      console.log('added Transaction to buy skin', buySkinVTx)

      let txs = []
      if (swapTx) txs.push(swapTx)
      txs.push(buySkinVTx)

      txs.forEach((tx) => {
        tx.message.recentBlockhash = ltbch
      })

      console.log(txs.length, ' transactions are to be signed and broadcast ...')

      if (!solanaWallet.signAllTransactions) throw 'no solanaWallet.signAllTransactions'
      let signedTx = await solanaWallet.signAllTransactions(txs)

      let jupTx = Data.skipSwap ? undefined : signedTx[0]
      let jupHash = Data.skipSwap ? undefined : ` bs58.encode(signedTx[0].signatures[0])`
      let BuySkinTx = Data.skipSwap ? signedTx[0] : signedTx[1]
      let skinHash = Data.skipSwap
        ? bs58.encode(signedTx[0].signatures[0])
        : bs58.encode(signedTx[1].signatures[0])
      console.log('jupHash', jupHash, 'BuySkinTx', skinHash)

      if (jupTx) {
        let tempExchangeHash = await connection.sendRawTransaction(jupTx.serialize(), {
          skipPreflight: true,
        })
        let confExchangeHash = await validateHashV2([tempExchangeHash], connection)

        if (confExchangeHash !== true) {
          console.log(jupHash, 'confExchangeHash', confExchangeHash)
          return {
            success: false,
            error: 'swapping token failed',
            jupHash,
            skinHash,
          } as BuySkinFeedback
        } else console.log('Buying on Jupiter Successfull')
      }

      let tempSkinHash = await connection.sendRawTransaction(BuySkinTx.serialize(), {
        skipPreflight: true,
      })

      let confBuySkinHash = await validateHashV2([tempSkinHash], connection)

      if (confBuySkinHash !== true) {
        console.log(skinHash, 'confBuySkinHash', confBuySkinHash)
        return {
          success: false,
          error: 'buying skin failed',
          jupHash,
          skinHash,
          amountSwapped: Data.skipSwap ? 0 : priceCurrencyLamports,
        } as BuySkinFeedback
      } else console.log('Buying skin Successfull')

      let hashes = []
      if (jupHash) hashes.push(jupHash)
      hashes.push(skinHash)

      return { hashes, success: true }
    } catch (error) {
      console.log('error', error)
      if (String(error).includes('User rejected the request'))
        return {
          success: false,
          error: 'User rejected the request',
        }
      return {
        success: false,
        error: 'other',
        errorLog: String(error),
      }
    }
  }

  const ceilToSignificantDigits = (x: number, digits: number): number => {
    let x_digits = Math.ceil(Math.log10(x))
    let x_rounded = Math.ceil(x / Math.pow(10, x_digits - digits))

    let x_out =
      x_digits - digits >= 0
        ? x_rounded * Math.pow(10, x_digits - digits)
        : x_rounded / Math.pow(10, digits - x_digits)

    // console.log({ x, digits, x_digits, x_rounded, x_out });
    return x_out
  }

  const getRecentPrioritizationFeesHM = async (
    writableAccounts: string[] = [],
    url: string
  ): Promise<number> => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: 1,
        method: 'getPrioritizationFee',
        params: {
          writableAccounts,
          percentiles: [0, 25, 50, 75, 100],
          lookbackSlots: 100,
        },
      }),
    })
    const data = await response.json()
    console.log('getPrioritizationFee:', data)
    return data.result.percentileToFee['50']
  }

  const getRecentPrioritizationFees = async (
    writableAccounts: string[] = [],
    connection: Connection
  ): Promise<number> => {
    let lockedWritableAccounts = writableAccounts.map((account) => new PublicKey(account))
    let fees = [0.001,0.002]//await connection?.getRecentPrioritizationFees({ lockedWritableAccounts })
    console.log('getRecentPrioritizationFees:', fees)
    let fee = fees.reduce((acc: number, f: any) => {
      return Math.max(acc, f.prioritizationFee)
    }, 0)

    return fee
  }

  const addPriorityFee = async (priorityFeeParams: {
    tx: Transaction
    connection?: Connection
    fee?: number | undefined
    units?: number | undefined
  }): Promise<Transaction> => {
    let { tx, connection, fee, units } = priorityFeeParams
    units = units || 6e5

    if (!fee) {
      let writableAccounts = tx.instructions
        .map((ix) => ix.keys.filter((key) => key.isWritable).map((key) => key.pubkey.toBase58()))
        .flat()

      if (
        process.env.REACT_APP_SOLANA_NETWORK &&
        process.env.REACT_APP_SOLANA_NETWORK.includes('rpc.hellomoon.io')
      ) {
        console.log('using getPrioritizationFee')
        fee = await getRecentPrioritizationFeesHM(
          writableAccounts,
          process.env.REACT_APP_SOLANA_NETWORK
        )
      } else {
        console.log('using getRecentPrioritizationFees', process.env.REACT_APP_SOLANA_NETWORK)
        if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
        fee = await getRecentPrioritizationFees(writableAccounts, connection)
      }
    }

    if (fee > 0) {
      tx = new Transaction()
        .add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: fee }))
        .add(ComputeBudgetProgram.setComputeUnitLimit({ units: 6e5 }))
        .add(...tx.instructions)
    }

    return tx
  }

  const getJupExchangeRate = async (inToken: string, outToken: string): Promise<number> => {
    let url = `https://price.jup.ag/v4/price?ids=${inToken}&vsToken=${outToken}`
    let response = await fetch(url)
    let data = await response.json()
    // console.log("data", data);
    return data.data[inToken].price
  }

  const checkPrices = async (skins: AcceptedSkin[]): Promise<PriceFeedback[]> => {
    let details = skins.map(mapName)
    let exchangeRates: any = {}
    details.forEach((skin) => {
      if (!exchangeRates[skin.currencyMint]) exchangeRates[skin.currencyMint] = {}
      exchangeRates[skin.currencyMint][skin.paidMint] = 0
    })

    await Promise.all(
      Object.keys(exchangeRates).map(async (currencyMint) => {
        await Promise.all(
          Object.keys(exchangeRates[currencyMint]).map(async (paidMint) => {
            exchangeRates[currencyMint][paidMint] = await getJupExchangeRate(currencyMint, paidMint)
          })
        )
      })
    )
    // console.log("exchangeRates", exchangeRates);

    let prices = await Promise.all(
      details.map(async (skin) => {
        let rate = exchangeRates[skin.currencyMint][skin.paidMint]
        let price = ceilToSignificantDigits(
          skin.primaryPrice * rate * (1 + NS_FEE_RATE) * (1 + 0.01),
          3
        )
        let priceLamports = Math.ceil(price * 10 ** skin.paidDecimals)

        return {
          success: true,
          price,
          priceLamports,
        } as PriceFeedback
      })
    )

    return prices
  }
  const addFeeIx = (Data: { user: PublicKey; retryData: InOutAtlasBundle }) => {
    let nsRevenue = new PublicKey(Data.retryData.neoswapFee.address)

    let { mint, amountLamports } = nameToCurrencyData(
      Data.retryData.currency,
      Data.retryData.neoswapFee.amount
    )
    console.log('Added Fees for NeoSwap', amountLamports, ' to ', nsRevenue.toBase58())

    return uncheckedSendLamportsIx({
      from: Data.user,
      to: nsRevenue,
      mint,
      amountLamports,
    })
  }
  const addFeeIxV2 = (Data: {
    user: PublicKey
    nsRevenue: PublicKey
    currency: AcceptedCurrency
    amount: number
  }) => {
    let { mint } = nameToCurrencyData(Data.currency)
    console.log(
      'Added Fees for NeoSwap',
      Data.amount,
      ' to ',
      Data.nsRevenue.toBase58(),
      ' paid in ',
      Data.currency,
      mint.toString()
    )

    return uncheckedSendLamportsIx({
      from: Data.user,
      to: Data.nsRevenue,
      mint,
      amountLamports: Data.amount,
    })
  }
  const currencyToName = (currencyPk?: string): AcceptedCurrency => {
    switch (currencyPk) {
      case process.env.REACT_APP_SOLANA_USDCMINT!:
        return 'usdc'
      case process.env.REACT_APP_SOLANA_ATLASMINT!:
        return 'atlas'
      default:
        return 'native'
    }
  }
  const nameToCurrencyData = (currency: AcceptedCurrency, amount?: number) => {
    let token = tokens[currency]
    let mint = new PublicKey(token.mint)
    let decimals = token.decimals
    let amountLamports = amount ? Math.ceil(amount * 10 ** decimals) : 0

    return {
      mint,
      amountLamports,
      decimals,
    }
  }

  const checkPrice = async (
    skinSelected: AcceptedSkin,
    feesLamport: number,
    mintData?: Order
  ): Promise<PriceFeedback> => {
    try {
      if (!mintData) mintData = await getMintData(mapName(skinSelected))

      if (!mintData) throw new Error('No mint data found')

      return {
        name: skinSelected,
        success: true,
        price: mintData.uiPrice + feesLamport / 10 ** mintData.currencyDecimals,
        priceLamports: mintData.price.toNumber() + feesLamport,
      } as PriceFeedback
    } catch (error) {
      console.log('error', error)
      return {
        name: skinSelected,
        success: false,
        error: String(error),
      } as PriceFeedback
    }
  }

  const getMintData = async (skinToBuy: SkinDetailsType, connection?: Connection) => {
    try {
      if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
      return (
        await new GmClientService().getOpenOrdersForAsset(
          connection,
          new PublicKey(skinToBuy.mint),
          new PublicKey(process.env.REACT_APP_SOLANA_STARATLAS_PROGRAM!)
        )
      )
        .filter(
          (order) =>
            order.uiPrice !== 0 &&
            order.orderType === 'sell' &&
            order.orderMint === skinToBuy.mint &&
            order.currencyMint === skinToBuy.currencyMint
        )
        .sort((orderA, orderB) => orderA.uiPrice - orderB.uiPrice)[0]
    } catch (error) {
      console.log('error', error)
      return
    }
  }

  const mapName = (skinSelected: AcceptedSkin): SkinDetailsType => {
    return SkinDetails[skinSelected]
  }

  const checkTokenBalance = async (Data: {
    owner: PublicKey
    mint: PublicKey
    maxPriceLamport: number
    connection?: Connection
  }) => {
    if (!Data.connection) Data.connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let ata = (
      await nS.UTILS.findOrCreateAta({
        mint: Data.mint,
        owner: Data.owner,
        signer: Data.owner,
        connection: Data.connection,
      })
    ).mintAta

    let balanceLamport
    try {
      let balance = (await Data.connection.getTokenAccountBalance(ata)).value
      balanceLamport = parseInt(balance.amount)
    } catch (error) {
      console.log('error getting ata balanceLamport', error)
    }

    if (
      balanceLamport === null ||
      balanceLamport === undefined ||
      balanceLamport < Data.maxPriceLamport
    )
      return {
        success: false,
        error: 'not enough funds',
        priceLamports: Data.maxPriceLamport,
        balance: balanceLamport,
      } as BuySkinFeedback
    return { balanceLamport }
  }
  const SkinDetails: { [name: string]: SkinDetailsType } = {
    jup: {
      name: 'jup', // JUP
      mint: 'RJUPJAFj9d3YmGpGTsGGyKdFPAmzUhiikwH9a9Y495s',
      primaryPrice: 30, // 30 USDC
      currencyMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // USDC
      currencyDecimals: 6,
      paidMint: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN', // JUP
      paidDecimals: 6,
      // mint: "ammoK8AkX2wnebQb35cDAZtTkvsXQbi82cGeTnUvvfK", // skin
      // primaryPrice: 0.003, // ATLAS
      // currencyMint: process.env.REACT_APP_SOLANA_ATLASMINT!, // Paying in GM
      // currencyDecimals: 8,
      // paidMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", // Paying from Wallet
      // paidDecimals: 6,
    },
    puri1: {
      name: 'puri1', // PURI PTP
      mint: 'RUPPjCR3TvBRn8dRptJ7s1GHagxXN4eMLkWeDcsAX23',
      primaryPrice: 20, // 20 USDC
      currencyMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // USDC
      currencyDecimals: 6,
      paidMint: 'CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB', // PURI
      paidDecimals: 9,
      // mint: "foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG",
      // primaryPrice: 0.002, // ATLAS
      // currencyMint: process.env.REACT_APP_SOLANA_ATLASMINT!, // Paying in GM
      // currencyDecimals: 8,
      // paidMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", // Paying from Wallet
      // paidDecimals: 6,
    },
    puri2: {
      name: 'puri2', // PURI PTT
      mint: 'RUPTq1qUS7t2u4D6CUzUgpeEtDanXZCJvP7HR9MXo1S',
      primaryPrice: 20, // 20 USDC
      currencyMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // USDC
      currencyDecimals: 6,
      paidMint: 'CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB', // PURI
      paidDecimals: 9,
      // mint: "fueL3hBZjLLLJHiFH9cqZoozTG3XQZ53diwFPwbzNim",
      // primaryPrice: 0.002, // ATLAS
      // currencyMint: process.env.REACT_APP_SOLANA_ATLASMINT!, // Paying in GM
      // currencyDecimals: 8,
      // paidMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", // Paying from Wallet
      // paidDecimals: 6,
    },
  }

  ///
  /// Buy multiple mints on tensor
  ///

  const buyAllTensor = async (
    tensorBundle: TensorBundle
  ): Promise<{
    items: {
      hash: string
      mint: string
    }[]
    fees: string
  }> => {
    let retryData = tensorBundle
    let successful: { hash: string; mint: string }[] = []
    let feehash = ''
    let retry = 3
    while (retryData.tensorListToBuy.length > 0 && retry > 0) {
      try {
        const buyOneResponse = await buyOneAllTensor(retryData) //, retry <= 2);
        let feee: {
          address: string
          amount: number
        }
        if (!(buyOneResponse.fees === '')) {
          feehash = buyOneResponse.fees
          feee = { address: tensorBundle.neoswapFee.address, amount: 0 }
          console.log('feehash', feee)
        } else feee = tensorBundle.neoswapFee
        retryData = {
          tensorListToBuy: [],
          neoswapFee: feee, //tensorBundle.neoswapFee,
        } as TensorBundle
        let isRetry = false
        // if (buyOneResponse.fees)
        buyOneResponse.item.map((buyOne) => {
          if (!!!buyOne.valid) {
            isRetry = true
            retryData.tensorListToBuy.push({ ...buyOne })
            console.log('retrying this mint', buyOne.mint)
          } else {
            successful.push({ hash: buyOne.hash, mint: buyOne.mint })
            console.log('successful', buyOne.mint)
          }
        })
        if (!isRetry) {
          retry = 0
        } else retry--
      } catch (error) {
        let txt = errorThatShoudThrowTensor(error)
        if (!!txt) {
          retry = 0
          throw txt
        }
        toast('Transaction failed. Will retry.', {
          duration: 9000,
        });
        console.warn('retrying all after the following error \n', error)
        retry--
      }
    }
    return { items: successful, fees: feehash }
  }

  const buyOneAllTensor = async (
    // mintData: MintData[]
    tensorBundle: TensorBundle
    // retry: boolean
  ): Promise<{
    item: (MintData & {
      hash: string
      valid: boolean
      error?: string
    })[]
    fees: string
  }> => {
    if (!connected) await solanaWallet.connect()

    if (!solanaWallet.publicKey) throw new Error('please connect wallet')
    let walletPk = solanaWallet.publicKey

    const connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    let i = 0
    let allDatas = await Promise.all(
      tensorBundle.tensorListToBuy.map(async (mintData) => {
        // await sleep(i * 400);

        if (!!!mintData.isCnft) {
          // NFT
          console.log('NFT', mintData.mint)
          return await tensorBuyNftIx(mintData, walletPk, connection)
        } else {
          // cNFT
          console.log('cNFT', mintData.mint)
          return await tensorBuycNftIx(mintData, walletPk)
        }
      })
    )
    let hasFees = false
    if (tensorBundle.neoswapFee.amount > 0) {
      hasFees = true
      allDatas.push(
        await sendSol({
          from: walletPk,
          to: new PublicKey(tensorBundle.neoswapFee.address),
          amount: tensorBundle.neoswapFee.amount,
          connection,
        })
      )
    }
    await validateSimulateTx(allDatas, connection)
    // throw "stop here"
    // let signedTx = await solanaWallet.signAllTransactions(allDatas);
    let sendTxs = allDatas.map((tx) => {
      return { tx }
    })

    console.log('sendTxs', sendTxs)

    let provider = await getProvider()
    if (!provider.sendAll) throw 'no provider.sendAll'
    let hashes = await provider.sendAll(sendTxs, {
      skipPreflight: true,
      commitment: 'singleGossip',
      preflightCommitment: 'singleGossip',
      maxRetries: 3,
    })
    console.log('hashes', hashes)
    // await Promise.all(
    //     signedTx.map(
    //         async (tx) =>
    //             await connection.sendRawTransaction(tx.serialize(), {
    //                 skipPreflight: true,
    //             })
    //     )
    // );
    let returnData: (MintData & { hash: string })[] = []

    let feeHash = ''
    if (hasFees) {
      const elem = hashes.pop()
      const successData = await validateHashV2(
        returnData.map((data) => data.hash),
        connection
      )
      if (successData === true) {
        feeHash = elem!
      }
    }

    while (hashes.length > 0) {
      const element = hashes.pop()
      const mintDta = tensorBundle.tensorListToBuy.pop()
      returnData.push({ ...mintDta!, hash: element! })
    }
    const successData = await validateHashV2(
      returnData.map((data) => data.hash),
      connection
    )
    console.log('successData', successData)

    if (successData === true) {
      return {
        item: returnData.map((data) => {
          return { ...data, valid: true }
        }),
        fees: feeHash,
      }
    } else {
      return {
        item: returnData.map((data) => {
          return {
            ...data,
            ...successData.filter((success) => success.hash === data.hash)[0],
          }
        }),
        fees: feeHash,
      }
    }
  }
  const tensorBuyNftIx = async (
    mintData: MintData,
    buyer: PublicKey,
    connection: Connection
  ): Promise<VersionedTransaction> => {
    return await getTensorBuyData(await getTensorListing(mintData, connection), buyer, mintData)
  }
  const getTensorListing = async (mintData: MintData, connection: Connection) => {
    const swapSdk = new TensorSwapSDK({
      //@ts-ignore
      provider: new AnchorProvider(connection, solanaWallet.wallet, {}),
    })

    const [listingPdaPk] = findSingleListingPDA({
      nftMint: new PublicKey(mintData.mint),
    })
    return await swapSdk.fetchSingleListing(listingPdaPk)
  }
  const getTensorBuyData = async (
    listing: SingleListingAnchor,
    buyer: PublicKey,
    mintData: MintData
  ): Promise<VersionedTransaction> => {
    console.log(mintData.mint, listing.price.toNumber())

    if (!!mintData.maxPrice && mintData.maxPrice < listing.price.toNumber())
      throw `maxPrice is lower than listing price ${listing.price.toNumber()} / ${
        mintData.maxPrice
      }`

    const connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    const swapSdk = new TensorSwapSDK({
      //@ts-ignore
      provider: new AnchorProvider(connection, solanaWallet.wallet, {}),
    })
    // const buyer = solanaWallet.publicKey;
    // to find the associated token account linked to MINT and BUYER
    const ataData = await nS.UTILS.findOrCreateAta({
      connection,
      mint: listing.nftMint,
      owner: buyer,
      signer: buyer,
    })
    // console.log("ataData", ataData.mintAta.toBase58());

    const buyData = await swapSdk.buySingleListing({
      buyer: buyer,
      nftMint: listing.nftMint,
      nftBuyerAcc: ataData.mintAta,
      maxPrice: listing.price,
      owner: listing.owner,
    })

    console.log(listing.nftMint.toBase58(), 'buyData ix length', buyData.tx.ixs.length)
    let tx = new Transaction().add(...buyData.tx.ixs)
    tx.feePayer = buyer
    tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
    return new VersionedTransaction(tx.compileMessage())
  }
  const tensorBuycNftIx = async (
    mintData: MintData,
    buyer: PublicKey
    // connection: Connection
  ): Promise<VersionedTransaction> => {
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'X-TENSOR-API-KEY': 'f3ee8c5e-55a9-4b33-baee-659e3205fc09',
      },
    }
    return await getcTensorBuyData(await getcTensorListing(mintData, options), buyer, options)
  }

  const getcTensorListing = async (mintData: MintData, options: any) => {
    let slugMint = await getSlugMint([mintData.mint], options)
    // console.log("slugMint", slugMint);

    const getActivebids = `query ActiveListingsV2(
                $slug: String!
                $sortBy: ActiveListingsSortBy!
                $filters: ActiveListingsFilters
                $limit: Int
                $cursor: ActiveListingsCursorInputV2
              ) {
                activeListingsV2(
                  slug: $slug
                  sortBy: $sortBy
                  filters: $filters
                  limit: $limit
                  cursor: $cursor
                ) {
                  page {
                    endCursor {
                      str
                    }
                    hasMore
                  }
                  txs {
                    mint {
                      onchainId
                    }
                    tx {
                      sellerId
                      grossAmount
                      grossAmountUnit
                    }
                  }
                }
              }
              `
    let activeBidData = await (
      await fetch('https://api.tensor.so/graphql', {
        body: JSON.stringify({
          query: getActivebids,
          variables: {
            slug: slugMint,
            sortBy: 'PriceAsc',
            filters: {
              sources: ['TCOMP'],
            },
            limit: 250,
            cursor: null,
          },
        }),
        ...options,
      })
    ).json()
    type Activedata = {
      mint: { onchainId: string }
      grossAmount: string //amount
      grossAmountUnit: string
      sellerId: string
    }
    console.log('activeBidData', activeBidData)
    const tcompBids = activeBidData.data.activeListingsV2.txs
      .filter((bid: Activedata) => bid.mint.onchainId === mintData.mint)

      .sort((a: Activedata, b: Activedata) => Number(b.grossAmount) - Number(a.grossAmount))
    console.log('tcompBids', tcompBids)

    const selectedTcompBid = tcompBids[0] as {
      mint: { onchainId: string }
      tx: {
        sellerId: string
        grossAmount: string
        grossAmountUnit: string
      }
    }
    console.log('selectedTcompBid', selectedTcompBid)
    if (!!mintData.maxPrice && Number(selectedTcompBid.tx.grossAmount) > mintData.maxPrice)
      throw `maxPrice is lower than listing price ${selectedTcompBid.tx.grossAmount} / ${mintData.maxPrice}`
    return selectedTcompBid
  }
  const getcTensorBuyData = async (
    selectedTcompBid: {
      mint: { onchainId: string }
      tx: {
        sellerId: string
        grossAmount: string
        grossAmountUnit: string
      }
    },
    buyer: PublicKey,
    options: any
  ): Promise<VersionedTransaction> => {
    const getTcompBuyTx = `query TcompBuyTx(
            $buyer: String!
            $maxPrice: Decimal!
            $mint: String!
            $owner: String!
          ) {
            tcompBuyTx(buyer: $buyer, maxPrice: $maxPrice, mint: $mint, owner: $owner) {
              txs {
                tx
                txV0 # use this if present!
                lastValidBlockHeight
              }
            }
          }`
    let tcompTxDataRaw = await (
      await fetch('https://api.tensor.so/graphql/', {
        body: JSON.stringify({
          query: getTcompBuyTx,
          variables: {
            buyer: buyer.toString(),
            maxPrice: selectedTcompBid.tx.grossAmount,
            mint: selectedTcompBid.mint.onchainId,
            owner: selectedTcompBid.tx.sellerId,
          },
        }),
        ...options,
      })
    ).json()
    // console.log("tcompTxDataRaw", tcompTxDataRaw);
    if (!!!tcompTxDataRaw.data.tcompBuyTx.txs)
      throw `Couldn,t get the transaction data from API ${tcompTxDataRaw}`

    const tcompTxData = tcompTxDataRaw.data.tcompBuyTx.txs

    return tcompTxData.map((tx: any) => VersionedTransaction.deserialize(tx.txV0.data))[0]
  }

  const getSlugMint = async (tokenMints: string[], options: any) => {
    const getslugForMint = `query Mints($tokenMints: [String!]!) {
            mints(tokenMints: $tokenMints) {
              slug
            }
          }`
    let slugData = await (
      await fetch('https://api.tensor.so/graphql/', {
        body: JSON.stringify({
          query: getslugForMint,
          variables: {
            tokenMints,
          },
        }),
        ...options,
      })
    ).json()
    const slugMint = slugData.data.mints[0].slug
    // console.log("slugMint", slugMint);
    return String(slugMint)
  }
  const sendSol = async (Data: {
    to: PublicKey
    from: PublicKey
    amount: number
    connection: Connection
  }) => {
    const tx = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: Data.from,
        toPubkey: Data.to,
        lamports: Data.amount,
      })
    )
    tx.feePayer = Data.from
    tx.recentBlockhash = (await Data.connection.getLatestBlockhash()).blockhash
    console.log('sendSol', tx)

    return new VersionedTransaction(tx.compileMessage())
  }
  const errorThatShoudThrowTensor = (error: any) => {
    if (
      String(error).includes(tensorBundleError.walletDisconnected) ||
      String(error).includes(tensorBundleError.userRejects)
    ) {
      return error
    } else if (
      String(error).includes(tensorBundleError.noOrdercNFT) ||
      String(error).includes(tensorBundleError.noOrderNFT)
    ) {
      return 'No order found for this mint'
    }
  }

  const tensorBundleError = {
    userRejects: 'WalletSignTransactionError',
    noOrdercNFT: 'Account does not exist or has no data',
    walletDisconnected: 'please connect wallet',
    noOrderNFT: "Cannot read properties of undefined (reading 'tx')",
  }

  const getColTransactions = async (Data: Act): Promise<nCT.BundleTransaction[]> => {
    let envOpts = {
      prioritizationFee: PRIORITIZATION_FEE,
      clusterOrUrl: process.env.REACT_APP_SOLANA_NETWORK!,
      // programId: '2vumtPDSVo3UKqYYxMVbDaQz1K4foQf6A31KiUaii1M7',
      // idl?: Idl | true,
    } as EnvOpts
    Data = { ...Data, ...envOpts }

    if (TYPES.isMakeSArg(Data)) return (await makeSwap(Data)).bTxs
    if (TYPES.isTakeSArg(Data)) return await takeAndCloseSwap(Data)
    if (TYPES.isClaimSArg(Data)) return await cancelSwap(Data)
    if (TYPES.isUpdateSArg(Data)) return [await colIx.createAddBidBt(Data)]
    if (TYPES.isRmBidsArgs(Data)) return [await colIx.createRmBidBt(Data)]
    throw 'invalid Data'
  }

  const makeSwap = async (Data: MakeSArg & EnvOpts) => {
    // throw 'Creating a swap is disabled for maintenance. We will be back soon.'
    return await colIx.createMakeSwapInstructions({
      ...Data,
      // programId: '2vumtPDSVo3UKqYYxMVbDaQz1K4foQf6A31KiUaii1M7',
    })
  }
  const retryColSwap = async (Data: MakeSArg) => {
    let clusterOrUrl = process.env.REACT_APP_SOLANA_NETWORK!
    let { maker, nftMintMaker, bids } = Data
    try {
      let programId = (await UTILS.getProgram({ clusterOrUrl })).programId.toString()

      let sda = UTILS.getSda(maker, nftMintMaker, programId)
      let sdaData = await UTILS.getSdaData({ swapDataAccount: sda, clusterOrUrl })

      sdaData?.bids.forEach((sdaBid) => {
        bids = bids.filter((argBid) => argBid.collection !== sdaBid.collection)
      })
      if (bids.length > 0)
        return [
          await colIx.createAddBidBt({
            bids,
            maker,
            swapDataAccount: sda,
            clusterOrUrl,
            programId,
          }),
        ]
    } catch (error) {
      console.log('error', error)
    }
    return await getColTransactions(Data)
  }
  const takeAndCloseSwap = async (Data: TakeSArg & EnvOpts) => {
    return await colIx.createTakeAndCloseSwapInstructions({
      ...Data,
      verifyTaker: true,
    })
  }
  const cancelSwap = async (Data: ClaimSArg & EnvOpts) => {
    return [
      await colIx.createCancelSwapInstructions({
        ...Data,
      }),
    ]
  }
  const getMultipleCollectionSwapData = async (
    Data: EnvOpts & {
      ignoreList?: string[]
    }
  ): Promise<
    {
      sda: string
      data: SwapData
    }[]
  > => {
    if (!Data) Data = { ignoreList: [] }
    if (!Data.clusterOrUrl) Data.clusterOrUrl = process.env.REACT_APP_SOLANA_NETWORK!

    return await UTILS.getOpenSda({
      ...Data,
      // programId: '2vumtPDSVo3UKqYYxMVbDaQz1K4foQf6A31KiUaii1M7',
    })
  }

  const signColTransactions = async (
    Data: nCT.BundleTransaction[],
    connection?: Connection,
    is_simulate?: boolean
  ): Promise<nCT.BundleTransaction[] | BundleError> => {
    if (!!!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)
    if (!connected) await solanaWallet.connect()
    if (!solanaWallet.publicKey)
      return {
        error: 'not connected',
      }

    if (!isConnectedWalletSigner(Data))
      return {
        error: 'wallet error',
        detail: `Connected wallet is not the signer. Please switch wallet to ${solanaWallet.publicKey.toString()} to sign the transaction`,
      }

    if (!solanaWallet.signAllTransactions)
      return {
        error: 'wallet error',
        detail: 'no signAllTransactions',
      }
    let vData = Data.map((tx) => {
      if (!isVersionedTransaction(tx.tx)) tx.tx = new VersionedTransaction(tx.tx.compileMessage())
      return tx as BTv
    })
    let { vTxs: txs, blockheight } = await refreshVTxBlockhash(
      vData.map((tx) => tx.tx),
      connection
    )
    let simu = await connection!.simulateTransaction(vData[0].tx!)
    console.log('simu', simu)
    if (simu.value.err) simu.value.logs?.map((v) => console.log(v))
    console.log('txs tosign', txs)
    let signed = await solanaWallet.signAllTransactions(txs)
    signed.forEach((stx, i) => {
      vData[i].stx = stx
      vData[i].hash = bs58.encode(stx.signatures[0])
    })

    return await Promise.all(
      vData.map(async (txData, i) => {
        await sleep(i * 150)
        txData.blockheight = blockheight
        try {
          if (is_simulate) {
            let simu = await connection!.simulateTransaction(txData.stx!)
            txData.status = 'broadcast'
            console.log('simu', simu)
            if (simu.value.err) simu.value.logs?.map(console.log)
          } else {
            let hash = await connection!.sendTransaction(txData.stx!)
            console.log(txData.description, 'hash', hash)

            txData.status = 'broadcast'
            txData.hash = hash
          }

          // Data[i].hash = hash;
        } catch (error) {
          txData.status = 'failed'
          txData.failedReason = 'failed to send' + JSON.stringify(error)
        }
        return txData
      })
    )
  }

  const checkColTransactions = async (
    Data: nCT.BundleTransaction[],
    connection?: Connection,
    rebroadcast?: boolean,
    commitment?: 'finalized' | 'confirmed' | 'dynamic'
  ): Promise<nCT.BundleTransaction[] | BundleError> => {
    if (!connection) connection = new Connection(process.env.REACT_APP_SOLANA_NETWORK!)

    let hashesToConfirm = Data.filter((tx) => tx.status === 'broadcast' && !!tx.hash).map(
      (tx) => tx.hash!
    )
    let hasSubsequentTxs = Data.some((tx) => tx.status === 'pending')
    if (commitment == 'dynamic' || commitment == undefined)
      commitment = hasSubsequentTxs ? 'finalized' : 'confirmed'

    await connection
      .getTransactions(hashesToConfirm, {
        commitment,
        maxSupportedTransactionVersion: 0,
      })
      .then((resu) =>
        resu.map((tx) => {
          if (tx) {
            let hash = tx!.transaction.signatures[0]
            let txIndex = Data.findIndex((t) => t.hash === hash)

            if (txIndex != -1) {
              if (!!tx?.meta?.err) {
                Data[txIndex].status = 'failed'
                Data[txIndex].failedReason = JSON.stringify(tx.meta.logMessages) /// to be improved
              } else Data[txIndex].status = 'success'

              hashesToConfirm = hashesToConfirm.filter((h) => h !== hash)
            } else console.log('txIndex not found', Data, hash, txIndex)
          } else console.log('tx not found', tx)
        })
      )

    if (hashesToConfirm.length > 0) {
      let latestBheight = (await connection.getLatestBlockhash()).lastValidBlockHeight
      await Promise.all(
        hashesToConfirm.map(async (hash) => {
          let txIndex = Data.findIndex((t) => t.hash === hash)
          let txBcht = Data[txIndex].blockheight
          if (txBcht && latestBheight > txBcht + 151) {
            console.log(latestBheight, ' > ', txBcht + 151, 'unconfirmed failed', hash)
            Data[txIndex].status = 'Timeout'
          } else {
            console.log('too early for unconfirmed', hash)
            if (rebroadcast)
              console.log(
                'rebroadcasted',
                await connection!
                  //@ts-ignore
                  .sendTransaction(Data[txIndex].stx!)
                  .catch((error) => console.log('error rebroadcasting', error))
              )
          }
        })
      )
    }

    return Data
  }

  const createAtaIdempotent = async (Data: { owner: string; signer: string; mint: string }) => {
    let { owner, signer, mint } = Data
    const userAta = PublicKey.findProgramAddressSync(
      [
        new PublicKey(owner).toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        new PublicKey(mint).toBuffer(),
      ],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )[0].toString()
    console.log(
      'createAssociatedTokenAccountIdempotentInstruction',
      createAssociatedTokenAccountIdempotentInstruction
    )

    let instruction = createAssociatedTokenAccountIdempotentInstruction(
      new PublicKey(signer),
      new PublicKey(userAta),
      new PublicKey(owner),
      new PublicKey(mint),
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
    return { instruction, userAta }
  }
  const updateIxToIdempotent = async (Data: {
    transaction: Transaction
    order: Order
    user: string
  }) => {
    let { transaction, order, user } = Data
    let { instruction, userAta } = await createAtaIdempotent({
      mint: order.orderMint,
      owner: user,
      signer: user,
    })
    transaction.instructions = transaction.instructions.map((ix) => {
      if (ix.programId.toString() == instruction?.programId.toString())
        if (ix.keys.map((x) => x.pubkey.toString()).includes(userAta)) return instruction
      return ix
    })
    return transaction
  }
  const isConnectedWalletSigner = (txs: nCT.BundleTransaction[]) => {
    let user = solanaWallet.publicKey!

    let isSignerArray = txs.map((btx, i) => {
      if (isVersionedTransaction(btx.tx)) {
        let signerIndex = btx.tx.message
          .getAccountKeys()
          .staticAccountKeys.findIndex((k) => k.equals(user))

        if (btx.tx.message.isAccountSigner(signerIndex)) return true
        console.log(
          'not signer vTX',
          i,
          'feesigner',
          btx.tx.message.getAccountKeys().get(i),
          'user',
          user.toString()
        )
      } else {
        if (btx.tx.feePayer && btx.tx.feePayer.equals(user)) return true
        console.log(
          'not signer Tx',
          i,
          'feepayer',
          btx.tx.feePayer?.toString(),
          'user',
          user.toString()
        )
      }
      if (window?.solana?.publicKey)
        console.log('window.solana.publicKey', window.solana.publicKey.toString())

      return false
    })
    return !isSignerArray.some((isSigner) => isSigner == false)
  }
  return {
    address: address,
    connect: handleConnect,
    signIn,
    deposit,
    getStarAtlasBundle,
    errorThatShoudGenerateAgain,
    signOut: handleSignout,
    connected,
    connecting: signingIn,
    getStatementUrl,
    wallet: solanaWallet.wallet,
    buyAllTensor,
    buySkin,
    // checkPrice,
    checkPrices,
    getTransactions,
    signTransactions,
    currencyToName,
    checkTransactions,
    uncheckedSendLamportsIx,
    getRetryTransactions,
    getOffers,

    /// COLLECTION SWAP
    getMultipleCollectionSwapData,
    signColTransactions,
    checkColTransactions,
    getColTransactions,
    cancelSwap,
    retryColSwap,
  }
}
