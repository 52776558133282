import React, { useCallback, useEffect, useMemo, useState } from 'react'
import modalMachine from './modalMachine'
import { useMachine } from '@xstate/react'
import { formatToTwoSignificantDigitsAfterDecimal } from '../../../../utils'
import useSolana from '../../hooks/useSolana'
import { Pack, PackTransaction } from './types'
import TransactionModal from '../../../ui/TransactionModal'
import TransactionTable, { Transaction } from '../../../ui/TransactionTable'
import { CurrencyDollarIcon, ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import PendingImg from '../../../../assets/pending.jpg'
import { getJupPackTransaction } from '../../utils/jupiter'
import { useAppContext } from '../../../../contexts/appContext'
import toast, { Toaster } from 'react-hot-toast'
import LoadingSpinner from '../../../ui/LoadingSpinner'

interface GridItem {
  name: string;
  image: string;
  quantity?: number;
}

interface BuyingModalProps {
  partnerToken: string
  packId?: string
  packDetails?: Pack
  twitterMessageUrl?: string
  twitterMessageText?: string
  isOpen: boolean
  onClose: () => void
}

const SOL_LAMPORTS = 10 ** 9
const USDC_LAMPORTS = 10 ** 6
const ATLAS_LAMPORTS = 10 ** 8

const SOL_MINT = 'So11111111111111111111111111111111111111112'
const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
const ATLAS_MINT = 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx'

const BuyingModal: React.FC<BuyingModalProps> = ({ partnerToken, packId, packDetails, twitterMessageUrl, twitterMessageText, isOpen, onClose }): JSX.Element => {
  const { uid } = useAppContext()
  const [state, send] = useMachine(modalMachine, {
    input: {
      partnerToken,
      packId,
      packDetails,
      twitterMessageUrl,
      twitterMessageText,
      mode: 'buy',
      salePack: undefined
    }
  })
  const { connected, connect, walletAddress, signAndSendTransactions, checkTransactions } = useSolana()
  const [isExchanging, setIsExchanging] = useState(false)
  const [exchangeComplete, setExchangeComplete] = useState(false)
  const [solToUsdcRate, setSolToUsdcRate] = useState<number | null>(null)
  const [atlasToUsdcRate, setAtlasToUsdcRate] = useState<number | null>(null)

  // Fetch rates from CoinGecko with caching
  useEffect(() => {
    const CACHE_KEY = 'exchange_rates'
    const CACHE_DURATION = 60 * 1000 // 1 minute

    const getCachedRates = () => {
      const cached = localStorage.getItem(CACHE_KEY)
      if (!cached) return null

      const { rates, timestamp } = JSON.parse(cached)
      if (Date.now() - timestamp > CACHE_DURATION) {
        localStorage.removeItem(CACHE_KEY)
        return null
      }
      return rates
    }

    const fetchRates = async () => {
      // Check cache first
      const cachedRates = getCachedRates()
      if (cachedRates) {
        setSolToUsdcRate(cachedRates.solana)
        setAtlasToUsdcRate(cachedRates.atlas)
        return
      }

      try {
        const [solResponse, atlasResponse] = await Promise.all([
          fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'),
          fetch('https://api.coingecko.com/api/v3/simple/price?ids=star-atlas&vs_currencies=usd')
        ])

        if (!solResponse.ok || !atlasResponse.ok) {
          throw new Error('Failed to fetch rates')
        }

        const solData = await solResponse.json()
        const atlasData = await atlasResponse.json()

        const rates = {
          solana: solData.solana.usd,
          atlas: atlasData['star-atlas'].usd
        }

        // Cache the rates
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          rates,
          timestamp: Date.now()
        }))

        setSolToUsdcRate(rates.solana)
        setAtlasToUsdcRate(rates.atlas)
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error)
        // Use fallback rates if API fails
        setSolToUsdcRate(23) // Approximate SOL/USD rate
        setAtlasToUsdcRate(0.02) // Approximate ATLAS/USD rate
      }
    }

    fetchRates()
  }, [])

  const getUserBalance = useCallback(() => {
    if (!state.context.balances) return { usdc: 0, sol: 0, atlas: 0 }

    const usdc = state.context.balances.find(balance => balance.token.symbol === 'USDC')
    const sol = state.context.balances.find(balance => balance.token.symbol === 'SOL')
    const atlas = state.context.balances.find(balance => balance.token.symbol === 'ATLAS')

    return {
      usdc: (usdc?.amount ?? 0) / USDC_LAMPORTS,
      sol: (sol?.amount ?? 0) / SOL_LAMPORTS,
      atlas: (atlas?.amount ?? 0) / ATLAS_LAMPORTS,
    }
  }, [state.context.balances])

  const getPackPrice = useCallback(() => {
    // Try to get price from items first
    const items = state.context.packDetails?.items || packDetails?.items || []
    if (items.length > 0) {
      const totalPrices = {
        usdc: 0,
        sol: 0,
        atlas: 0
      }

      items.forEach(item => {
        if (item.price) {
          const amount = item.price.amount / (10 ** item.price.token.decimals)
          switch (item.price.token.symbol.toUpperCase()) {
            case 'USDC':
              totalPrices.usdc += amount
              break
            case 'SOL':
              totalPrices.sol += amount
              break
            case 'ATLAS':
              totalPrices.atlas += amount
              break
          }
        }
      })

      console.log('Price from items:', totalPrices)
      return totalPrices
    }

    // Fallback to direct price if available
    const priceData = state.context.packDetails?.price || packDetails?.price
    if (!priceData) {
      console.log('No price data found')
      return { usdc: 0, sol: 0, atlas: 0 }
    }

    const result = {
      usdc: (priceData.find(price => price.token.symbol === 'USDC')?.amount ?? 0) / USDC_LAMPORTS,
      sol: (priceData.find(price => price.token.symbol === 'SOL')?.amount ?? 0) / SOL_LAMPORTS,
      atlas: (priceData.find(price => price.token.symbol === 'ATLAS')?.amount ?? 0) / ATLAS_LAMPORTS,
    }

    console.log('Price from direct price:', result)
    return result
  }, [state.context.packDetails, packDetails])

  const calculateExchangeInfo = useCallback(() => {
    if (!state.context.balances || solToUsdcRate === null || atlasToUsdcRate === null) {
      return null;
    }

    const balance = getUserBalance();
    const price = getPackPrice();

    // Calculate what's needed without buffer, but add 0.02 SOL for fees (multiple txs)
    const exchangeNeeded = {
      usdc: Math.max(0, price.usdc - balance.usdc),
      sol: Math.max(0, (price.sol + 0.02) - balance.sol),  // Add 0.02 SOL for fees (swap + purchase + setup)
      atlas: Math.max(0, price.atlas - balance.atlas),
    };

    const hasEnough = balance.usdc >= price.usdc &&
      balance.sol >= (price.sol + 0.02) &&  // Check if we have enough including fees
      balance.atlas >= price.atlas;

    return {
      balance,
      price,
      exchangeNeeded,
      hasEnough
    };
  }, [state.context.balances, solToUsdcRate, atlasToUsdcRate, getUserBalance, getPackPrice]);

  const generateQuoteData = (fromToken: 'SOL' | 'USDC' | 'ATLAS', toToken: 'SOL' | 'USDC' | 'ATLAS') => {
    const info = calculateExchangeInfo();
    if (!info) return [];

    const { exchangeNeeded, balance } = info;
    const tokenMints = {
      SOL: SOL_MINT,
      USDC: USDC_MINT,
      ATLAS: ATLAS_MINT
    };

    let outputAmount = 0;

    // Calculate exact output amount needed based on what we're missing
    if (toToken === 'SOL' && exchangeNeeded.sol > 0) {
      outputAmount = Math.ceil(exchangeNeeded.sol * SOL_LAMPORTS);  // Fee already included in exchangeNeeded
    } else if (toToken === 'USDC' && exchangeNeeded.usdc > 0) {
      outputAmount = Math.ceil(exchangeNeeded.usdc * USDC_LAMPORTS);
    } else if (toToken === 'ATLAS' && exchangeNeeded.atlas > 0) {
      outputAmount = Math.ceil(exchangeNeeded.atlas * ATLAS_LAMPORTS);
    }

    if (outputAmount > 0) {
      // Format amounts for display
      const toAmount = outputAmount / (toToken === 'SOL' ? SOL_LAMPORTS : toToken === 'USDC' ? USDC_LAMPORTS : ATLAS_LAMPORTS);

      return [{
        from: tokenMints[fromToken],
        to: tokenMints[toToken],
        amount: outputAmount,
        slippage: 0.5, // 0.5% slippage
        description: `Exchange ${fromToken} for ${toAmount.toFixed(4)} ${toToken}`
      }];
    }

    return [];
  };

  const canBuy = useMemo(() => {
    const info = calculateExchangeInfo();
    return info?.hasEnough ?? false;
  }, [calculateExchangeInfo])

  const handleExchange = async (fromToken: 'SOL' | 'USDC' | 'ATLAS', toToken: 'SOL' | 'USDC' | 'ATLAS') => {
    if (!uid) {
      console.log('No uid found')
      toast.error('Please connect your wallet to proceed with the exchange.', {
        duration: 4000,
      });
      return
    }

    const balance = getUserBalance()
    console.log('Current balances before exchange:', {
      SOL: balance.sol.toFixed(4),
      USDC: balance.usdc.toFixed(2),
      ATLAS: balance.atlas.toFixed(2)
    })

    setIsExchanging(true)
    const userPublicKey = uid.split('-')[1]
    console.log('Starting exchange with:', { fromToken, toToken, userPublicKey })

    const quoteData = generateQuoteData(fromToken, toToken)
    console.log('Generated quote data:', quoteData)

    if (quoteData.length > 0) {
      try {
        console.log('Fetching Jupiter transaction with quote:', quoteData)
        const pTxs = await getJupPackTransaction(quoteData, userPublicKey)
        console.log('Got transactions:', pTxs)

        console.log('Signing and sending transactions...')
        await signAndSendTransactions(pTxs)
        console.log('Transaction sent successfully')

        setExchangeComplete(true)
        send({ type: 'UPDATE_BALANCES' })
        setTimeout(() => {
          send({ type: 'UPDATE_BALANCES' })
        }, 2000)
      } catch (e) {
        console.error('Transaction failed:', e)
        toast.error('Failed to complete the token exchange. Please try again.', {
          duration: 5000,
        })
      }
    } else {
      console.log('No quote data generated')
      toast.error('Could not calculate exchange amounts. Please try again.', {
        duration: 5000,
      })
    }

    setIsExchanging(false)
  }

  // Debug current state
  useEffect(() => {
    console.log('Current State:', state.value)
    console.log('Context:', state.context)
  }, [state])

  // Show toast on message
  useEffect(() => {
    if (state.context.message) {
      toast(state.context.message, {
        duration: 9000,
      })
    }
  }, [state.context.message])

  // Show toast on error
  useEffect(() => {
    if (state.context.error) {
      const error = state.context.error as Error
      toast.error(error.message, {
        duration: 9000,
      })
    }
  }, [state.context.error])

  // Connect wallet
  useEffect(() => {
    if (state.matches('connect')) {
      if (connected) {
        send({ type: 'CONNECT', walletAddress })
      } else {
        connect()
      }
    }
  }, [connect, connected, state.value, walletAddress])

  // Sign and send transactions
  useEffect(() => {
    if (state.matches('signTxs')) {
      signAndSendTransactions(state.context.txs)
        .then((results) => {
          send({ type: 'SIGN', txs: results })
        })
        .catch((error) => {
          send({ type: 'REJECT', error })
        })
    }
  }, [signAndSendTransactions, state.value, state.context.txs])

  // Check transactions
  useEffect(() => {
    const checkTxs = async () => {
      if (state.matches('checkTxs')) {
        try {
          const updatedTransactions = await checkTransactions(state.context.txs, true)
          send({ type: 'UPDATE', txs: updatedTransactions })
        } catch (error) {
          send({ type: 'ERROR', error })
        }
      }
    }

    let intervalId: NodeJS.Timer
    if (state.matches('checkTxs')) {
      intervalId = setInterval(checkTxs, 2000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [checkTransactions, state.value, state.context.txs])

  const uiPrice = useMemo(() => {
    return state.context.packDetails?.price
      ?.map((price) => {
        return `${formatToTwoSignificantDigitsAfterDecimal(
          price.amount / 10 ** price.token.decimals
        )} ${price.token.symbol}`
      })
      .join(' + ')
  }, [state.context.packDetails?.price])

  const handleBuyClick = () => {
    if (state.matches('success')) {
      if (twitterMessageUrl) {
        window.open(twitterMessageUrl, '_blank');
      }
      send({ type: 'SHARE' });
    } else {
      send({ type: 'BUY' });
    }
  }

  const handleRetry = (transaction: Transaction) => {
    send({ type: 'RETRY', transaction })
  }

  const renderExchangeContent = () => {
    if (state.matches('loadBalances') || state.matches('init')) {
      return (
        <div className="flex items-center space-x-2">
          <LoadingSpinner className="h-24" text="Loading exchange information..." />
        </div>
      );
    }

    if (solToUsdcRate === null || atlasToUsdcRate === null || !state.context.balances || state.context.balances.length === 0) {
      return (
        <div className="flex items-center space-x-2">
          <LoadingSpinner className="h-24" text="Loading exchange rates..." />
        </div>
      );
    }

    const info = calculateExchangeInfo();
    if (!info) return null;

    const { exchangeNeeded } = info;
    const walletAddress = state.context.walletAddress;

    if (walletAddress) {
      return (
        <div className="space-y-4">
          {exchangeNeeded.sol > 0 && (
            <div className="space-y-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-lg">
              <div className="text-sm text-gray-700 dark:text-gray-300">
                <p>You need <span className="font-medium">{exchangeNeeded.sol.toFixed(4)} SOL</span></p>
                <button
                  onClick={() => handleExchange('USDC', 'SOL')}
                  disabled={isExchanging}
                  className="w-full mt-2 px-4 py-2 text-sm font-medium text-gray-900 bg-[#ffbe4d] hover:bg-[#ffc107] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isExchanging ? 'Exchanging...' : `Exchange USDC for SOL`}
                </button>
                <button
                  onClick={() => handleExchange('ATLAS', 'SOL')}
                  disabled={isExchanging}
                  className="w-full mt-2 px-4 py-2 text-sm font-medium text-gray-900 bg-[#ffbe4d] hover:bg-[#ffc107] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isExchanging ? 'Exchanging...' : `Exchange ATLAS for SOL`}
                </button>
              </div>
            </div>
          )}

          {exchangeNeeded.usdc > 0 && (
            <div className="space-y-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-lg">
              <div className="text-sm text-gray-700 dark:text-gray-300">
                <p>You need <span className="font-medium">{exchangeNeeded.usdc.toFixed(2)} USDC</span></p>
                <button
                  onClick={() => handleExchange('SOL', 'USDC')}
                  disabled={isExchanging}
                  className="w-full mt-2 px-4 py-2 text-sm font-medium text-gray-900 bg-[#ffbe4d] hover:bg-[#ffc107] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isExchanging ? 'Exchanging...' : `Exchange SOL for USDC`}
                </button>
                <button
                  onClick={() => handleExchange('ATLAS', 'USDC')}
                  disabled={isExchanging}
                  className="w-full mt-2 px-4 py-2 text-sm font-medium text-gray-900 bg-[#ffbe4d] hover:bg-[#ffc107] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isExchanging ? 'Exchanging...' : `Exchange ATLAS for USDC`}
                </button>
              </div>
            </div>
          )}

          {exchangeNeeded.atlas > 0 && (
            <div className="space-y-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-lg">
              <div className="text-sm text-gray-700 dark:text-gray-300">
                <p>You need <span className="font-medium">{exchangeNeeded.atlas.toFixed(2)} ATLAS</span></p>
                <button
                  onClick={() => handleExchange('USDC', 'ATLAS')}
                  disabled={isExchanging}
                  className="w-full mt-2 px-4 py-2 text-sm font-medium text-gray-900 bg-[#ffbe4d] hover:bg-[#ffc107] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isExchanging ? 'Exchanging...' : `Exchange USDC for ATLAS`}
                </button>
                <button
                  onClick={() => handleExchange('SOL', 'ATLAS')}
                  disabled={isExchanging}
                  className="w-full mt-2 px-4 py-2 text-sm font-medium text-gray-900 bg-[#ffbe4d] hover:bg-[#ffc107] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isExchanging ? 'Exchanging...' : `Exchange SOL for ATLAS`}
                </button>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const renderPaymentSection = () => {
    // Wait for state to be ready
    if (state.matches('loadBalances') || state.matches('init')) {
      return <LoadingSpinner className="h-24" text="Loading exchange information..." />;
    }

    // Wait for rates and balances
    if (solToUsdcRate === null || atlasToUsdcRate === null || !state.context.balances || state.context.balances.length === 0) {
      return <LoadingSpinner className="h-24" text="Loading exchange rates..." />;
    }

    const info = calculateExchangeInfo();
    if (!info) {
      return <LoadingSpinner className="h-24" />;
    }

    const { hasEnough } = info;

    // Show success message if user can buy or exchange is complete
    if (hasEnough || exchangeComplete) {
      return (
        <div className="flex items-start space-x-2 bg-green-50 dark:bg-green-900/20 p-3 rounded-lg">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-400" />
          </div>
          <div className="flex-1">
            <p className="text-sm text-green-700 dark:text-green-300">
              You have sufficient funds to purchase this pack.
            </p>
          </div>
        </div>
      )
    }

    // Show exchange UI if user can't buy and exchange is not complete
    return (
      <div className="flex items-start space-x-2 bg-blue-50 dark:bg-blue-900/20 p-3 rounded-lg">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="flex-1">
          {renderExchangeContent()}
        </div>
      </div>
    )
  }

  // Add debug logging for state changes
  useEffect(() => {
    console.log('Debug - State Changed:', {
      value: state.value,
      balances: state.context.balances,
      packDetails: state.context.packDetails,
      txs: state.context.txs,
      error: state.context.error
    })
  }, [state])

  // Add debug logging at the start
  useEffect(() => {
    console.log('Debug - Pack Details:', {
      packDetails,
      contextPackDetails: state.context.packDetails,
      price: state.context.packDetails?.price,
      rawPrice: packDetails?.price
    })
  }, [packDetails, state.context.packDetails])

  const renderContent = () => {
    if (state.matches('init')) {
      return <LoadingSpinner className="h-32" />;
    }

    const items: GridItem[] = (state.context.packDetails?.items || []).map(item => ({
      name: item.details.name || 'Unnamed Item',
      image: item.details.image || PendingImg,
      quantity: item.quantity
    }))

    const hasTransactions = state.context.txs.length > 0

    return (
      <div className="flex flex-col gap-6">
        {/* Items grid */}
        <div className="w-full">
          <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-base font-medium text-gray-900 dark:text-white">
                Items Included
              </h3>
              <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400">
                <CurrencyDollarIcon className="h-4 w-4" />
                <span>{uiPrice}</span>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {items.map((item, index) => (
                <div
                  key={item.name}
                  className={`${index === 0 ? 'col-span-1 sm:col-span-2 md:col-span-2' : 'col-span-1'}`}
                >
                  <div className="bg-gray-100 dark:bg-gray-700/50 rounded-lg overflow-hidden">
                    <div className="p-2">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white">{item.name}</h4>
                    </div>
                    <div className="relative h-48">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-full object-cover"
                      />
                      {item.quantity && (
                        <div className="absolute bottom-0 right-0 p-2 bg-black/75">
                          <span className="text-sm font-bold text-[#ffbe4d]">
                            x {item.quantity}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Payment section */}
        <div className="w-full">
          {hasTransactions ? (
            <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
              <div className="flex items-center justify-between mb-3">
                <h3 className="text-base font-medium text-gray-900 dark:text-white">
                  Transaction Status
                </h3>
                {state.matches('checkTxs') && (
                  <button
                    onClick={() => send({ type: 'UPDATE_BALANCES' })}
                    className="inline-flex items-center space-x-1 text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
                  >
                    <ArrowPathIcon className="h-4 w-4" />
                    <span>Refresh</span>
                  </button>
                )}
              </div>
              <TransactionTable
                transactions={state.context.txs}
                isLoading={state.matches('fetchTxs')}
                onRetry={handleRetry}
              />
            </div>
          ) : (
            <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
              <div className="flex items-center justify-between mb-3">
                <h3 className="text-base font-medium text-gray-900 dark:text-white">
                  Payment Details
                </h3>
                <button
                  onClick={() => {
                    send({ type: 'UPDATE_BALANCES' })
                    setExchangeComplete(false)
                  }}
                  className="inline-flex items-center space-x-1 text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
                >
                  <ArrowPathIcon className="h-4 w-4" />
                  <span>Refresh Balance</span>
                </button>
              </div>
              <div className="mt-2">
                {renderPaymentSection()}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    if (state.matches('init')) return null;

    const isProcessing = state.matches('signTxs') || state.matches('checkTxs') || state.matches('fetchTxs') || state.matches('retryTxs');
    const buttonDisabled = (!canBuy && !exchangeComplete) || isProcessing;

    return (
      <div className="flex justify-between w-full">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
        >
          {state.matches('success') || state.matches('shared') ? 'Close' : 'Cancel'}
        </button>

        <button
          type="button"
          onClick={handleBuyClick}
          disabled={buttonDisabled && !state.matches('success')}
          className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg transition-colors ${buttonDisabled && !state.matches('success') ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
            }`}
        >
          {state.matches('success')
            ? 'Share'
            : state.matches('shared')
              ? 'Shared'
              : isProcessing
                ? 'Processing...'
                : 'Buy Now'}
        </button>
      </div>
    );
  }

  return (
    <>
      <Toaster position="top-right" />
      <TransactionModal
        isOpen={isOpen}
        onClose={onClose}
        title={state.context.packDetails?.name}
        subtitle={state.context.packDetails?.description}
        size="2xl"
        footer={renderFooter()}
        className="dark:bg-gray-900"
        headerClassName="dark:bg-gray-800/50"
        contentClassName="dark:bg-gray-900/50"
      >
        {renderContent()}
      </TransactionModal>
    </>
  )
}

export default BuyingModal
