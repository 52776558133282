import Talk from "talkjs";
import { useEffect, useState, useRef, MutableRefObject } from "react";
import { useAppContext } from "../contexts/appContext";

interface Props {
  chatId: string;
  isAdmin?: boolean;
  isAiTrades: boolean;
}

const UnAuthedChat = ({ chatId }: Props) => {
  const chatboxEl = useRef() as MutableRefObject<HTMLInputElement>;
  const [talkLoaded, markTalkLoaded] = useState(false);

  useEffect(() => {
    Talk.ready.then(() => markTalkLoaded(true));
  }, []);

  useEffect(() => {
    if (!talkLoaded) return;
    const id = "guest";
    const currentUser = new Talk.User({
      id: id,
      email: null,
      name: "Guest",
      photoUrl: undefined,
      role: "default",
    });

    const session = new Talk.Session({
      appId: process.env.REACT_APP_TALKJS_APP_ID!,
      me: currentUser,
    });

    const conversation = session.getOrCreateConversation(chatId);
    conversation.setParticipant(currentUser, {
      access: "Read",
    });

    const chatbox = session.createChatbox({
      theme: "neoswapSol",
      showChatHeader: false,
    });

    chatbox.select(conversation);
    chatbox.mount(chatboxEl.current);

    return () => {
      session.destroy();
    };
  }, [talkLoaded]);

  return <div className="chat-panel" ref={chatboxEl} />;
};

const AuthedChat = ({ chatId, isAdmin, isAiTrades }: Props) => {
  const { uid, displayName, avatar } = useAppContext();
  const chatboxEl = useRef() as MutableRefObject<HTMLInputElement>;
  const [talkLoaded, markTalkLoaded] = useState(false);

  useEffect(() => {
    Talk.ready.then(() => markTalkLoaded(true));
  }, []);

  useEffect(() => {
    if (!talkLoaded) return;
    const currentUser = new Talk.User({
      id: uid!,
      email: null,
      name: displayName || uid!,
      photoUrl: avatar,
      role: isAdmin ? "roomAdmin" : "default",
    });

    const session = new Talk.Session({
      appId: process.env.REACT_APP_TALKJS_APP_ID!,
      me: currentUser,
    });

    const conversation = session.getOrCreateConversation(chatId);

    if (!isAiTrades) {
      conversation.setAttributes({
        welcomeMessages: [
          "Welcome to Tulle! Please set your spending limit, and the limit of Items you'd like to receive. This will allow you to bid on ALL items you're interested in without overspending.",
          "An additional 60 seconds will be added to the auction for each new high bid. The solver will continue to run every 60 seconds until the auction is complete.",
        ],
      });
    }

    conversation.setParticipant(currentUser, {
      access: uid ? "ReadWrite" : "Read",
    });

    const chatbox = session.createChatbox({
      theme: "neoswapSol",
      showChatHeader: false,
    });

    chatbox.select(conversation);
    chatbox.mount(chatboxEl.current);

    return () => {
      session.destroy();
    };
  }, [talkLoaded]);

  return <div className="chat-panel" ref={chatboxEl} />;
};

function Chat({ chatId, isAdmin = false, isAiTrades }: Props) {
  const { uid } = useAppContext();

  useEffect(() => {
    document.body.classList.add("chat-active");
    return () => {
      document.body.classList.remove("chat-active");
    };
  }, []);

  return uid ? (
    <AuthedChat chatId={chatId} isAdmin={isAdmin} isAiTrades={isAiTrades} />
  ) : (
    <UnAuthedChat chatId={chatId} isAiTrades={isAiTrades} />
  );
}

export default Chat;
