import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/appContext";
import SignInButton from "../components/SignInButton";
import { ChainSwitcher } from "../components/navbar";
import PartnerGroup from "../components/PartnerGroup";

// Appeared In
import yahoo from "../assets/appearedin/yahoo.png";
import crunchbase from "../assets/appearedin/crunchbase.png";
import cointelegraph from "../assets/appearedin/cointelegraph.png";
import benzinga from "../assets/appearedin/benzinga.png";
import alexablockchain from "../assets/appearedin/alexablockchain.png";
import aithority from "../assets/appearedin/aithority.png";

type ImageProps = {
  name: string;
  image: string;
  link?: string;
};

export default function Connect() {
  const { user, returnUrl } = useAppContext();

  if (user && returnUrl) {
    return <Navigate to={returnUrl} replace={true} />;
  }

  const appearedin = [
    {
      name: "yahoo",
      image: yahoo,
      link: "https://finance.yahoo.com/news/neoswap-introduces-ai-proposed-trades-175100825.html",
    },
    {
      name: "crunchbase",
      image: crunchbase,
      link: "https://www.crunchbase.com/organization/neoswap",
    },
    {
      name: "cointelegraph",
      image: cointelegraph,
      link: "https://cointelegraph.com/news/on-combining-ai-and-blockchains-qa-with-mattereum-memriio-and-neoswap",
    },
    {
      name: "benzinga",
      image: benzinga,
      link: "https://www.benzinga.com/pressreleases/23/05/n32392739/neoswap-ai-conducts-worlds-first-smart-auction-of-ordinals-for-bitcoin-unveiling-a-new-era-in-the-",
    },
    {
      name: "alexablockchain",
      image: alexablockchain,
      link: "https://alexablockchain.com/neoswap-ai-makes-history-with-worlds-first-bitcoin-nfts-smart-auction/",
    },
    {
      name: "aithority",
      image: aithority,
      link: "https://aithority.com/machine-learning/neoswap-introduces-ai-proposed-trades-for-personalized-nft-trading-experience/",
    },
  ];

  return (
    <>
      <Helmet>
        <title>NeoSwap | Connect Wallet</title>
      </Helmet>
      <div className="container mx-auto max-w-[550px]">
        <div className="flex flex-col mt-[50px] lg:mt-[50px] base:mt-[10px] text-center">
          <h2 className="text-4xl lg:text-5xl mb-5 px-0 md:px-[70px]">
            Connect Wallet
          </h2>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center rounded-[28px] new-bg-transparent p-8 w-fit mx-auto">
          <p className="mb-5 md:mb-5 base:mb-0 text-md">
            Select a chain and connect your wallet
          </p>
          <div className="flex justify-center mb-[10px]">
            <ChainSwitcher type="tile" />
          </div>
          <SignInButton version="connect-page" />

          <div className="max-w-[600px]">
            <div className="flex items-center p-4 bg-gradient-to-r from-[rgba(102,100,100,0.13)] to-[rgba(255,255,255,0.024)] rounded-[4px] info-alert">
              <svg className="w-5 h-5 text-[#6C60FF]" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
              <p className="ml-3">
                This product is still in beta. If you encounter any problems, please use the feedback form on the left.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PartnerGroup />
    </>
  );
}
