import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { BsChatLeft } from 'react-icons/bs';
import { IoSend } from "react-icons/io5";
import { useAppContext } from "../contexts/appContext";
import axios from 'axios';
import { doc, setDoc, collection, addDoc, query, orderBy, getDocs, Timestamp, serverTimestamp } from 'firebase/firestore';
import Firebase from '../services/firebase';
import { formatDistanceToNow } from 'date-fns';

const EXAMPLE_PROMPTS = [
    { title: "Buy NFTs", prompt: "I want to buy some NFTs from the DeGods collection" },
    { title: "Sell NFTs", prompt: "Help me list my NFT for sale" },
    { title: "Swap NFTs", prompt: "I want to swap my NFT for another one" },
    { title: "Market Analysis", prompt: "What's the current floor price for DeGods?" }
];

interface Chat {
    id: string;
    title: string;
    lastMessage: string;
    timestamp: Timestamp;
}

export default function TheoLanding() {
    const navigate = useNavigate();
    const { displayName, uid, setChatHistory } = useAppContext();
    const [message, setMessage] = useState('');
    const [recentChats, setRecentChats] = useState<Chat[]>([]);
    const [loading, setLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const db = Firebase.getDBApp();

    // Load recent chats
    useEffect(() => {
        if (!uid) return;

        const loadRecentChats = async () => {
            try {
                const chatsRef = collection(db, 'theo', uid, 'chats');
                const q = query(chatsRef, orderBy('timestamp', 'desc'));
                const snapshot = await getDocs(q);
                const loadedChats = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                } as Chat));
                setRecentChats(loadedChats);
                setLoading(false);
            } catch (error) {
                console.error("Error loading chats:", error);
                setLoading(false);
            }
        };

        loadRecentChats();
    }, [uid]);

    const startNewChat = async (initialMessage?: string) => {
        if (!uid) return;

        const content = initialMessage || message;
        if (!content.trim()) return;

        setIsSending(true);

        try {
            // Create new chat
            const chatId = uuidv4();
            const chatDocRef = doc(db, 'theo', uid, 'chats', chatId);

            // Save initial message
            const newMessage = {
                role: 'user',
                content: content.trim(),
                timestamp: serverTimestamp(),
            };

            // Create chat document with metadata
            await setDoc(chatDocRef, {
                title: content.slice(0, 50) + (content.length > 50 ? '...' : ''),
                lastMessage: content,
                timestamp: serverTimestamp(),
            });

            // Add message to chat
            const messagesRef = collection(chatDocRef, 'messages');
            await addDoc(messagesRef, newMessage);

            // Make API call to THEO backend
            const response = await axios.post(process.env.REACT_APP_THEO_API_URL || '', {
                message: content,
                chatId,
                userId: uid
            });
            console.log(response.data.text, "response.data.reply");
            // Add THEO's response to chat
            if (response.data.text) {
                await addDoc(messagesRef, {
                    role: 'assistant',
                    content: response.data.text,
                    timestamp: serverTimestamp(),
                });
            }

            // Store the conversation in context
            setChatHistory([
                { role: 'user', content: content, timestamp: Timestamp.now() },
                { role: 'assistant', content: response.data.text, timestamp: Timestamp.now() }
            ]);

            navigate(`/theo/chat/${chatId}`);
        } catch (error) {
            console.error("Error starting chat:", error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className="max-w-[1200px] mx-auto min-h-[calc(100vh-64px)] py-12 px-8 space-y-12">
            {/* Header */}
            <h1 className="text-3xl font-semibold dark:text-white">
                ✨ Good evening, {displayName || 'User'}
            </h1>

            {/* Input Box */}
            <div className="p-6 rounded-xl border dark:border-gray-700 bg-white dark:bg-gray-800 shadow-sm">
                <p className="text-lg font-medium mb-6 dark:text-white">
                    How can THEO help you today?
                </p>
                <div className="relative">
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Message THEO..."
                        disabled={isSending}
                        className="w-full min-h-[60px] max-h-[200px] p-4 rounded-xl border dark:border-gray-700 bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white resize-none disabled:opacity-50"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                if (message.trim() && !isSending) startNewChat();
                            }
                        }}
                    />
                    <button
                        onClick={() => startNewChat()}
                        disabled={isSending}
                        className={`absolute right-4 bottom-4 h-8 flex items-center justify-center bg-blue-500 hover:bg-blue-600 rounded-full transition-all duration-200 hover:scale-110 px-4 gap-2 disabled:opacity-50 disabled:hover:bg-blue-500 disabled:hover:scale-100 ${message.trim() ? 'opacity-100 visible' : 'opacity-0 invisible'
                            }`}
                    >
                        {isSending ? (
                            <>
                                <span className="text-white text-sm font-medium">Sending...</span>
                                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                            </>
                        ) : (
                            <>
                                <span className="text-white text-sm font-medium">Send</span>
                                <IoSend className="text-white w-4 h-4 -rotate-45 -translate-x-px -translate-y-px" />
                            </>
                        )}
                    </button>
                </div>
            </div>

            {/* Examples */}
            <div>
                <h2 className="text-xl font-semibold mb-6 dark:text-white flex items-center gap-2">
                    <span className="w-6 h-6 flex items-center justify-center bg-blue-100 dark:bg-blue-900 rounded-lg text-blue-500">
                        →
                    </span>
                    Examples
                </h2>
                <div className="flex flex-wrap gap-4">
                    {EXAMPLE_PROMPTS.map((example, i) => (
                        <div
                            key={i}
                            onClick={() => startNewChat(example.prompt)}
                            className="flex-1 basis-[calc(50%-8px)] p-4 rounded-lg border dark:border-gray-700 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors shadow-sm hover:shadow"
                        >
                            <p className="font-medium dark:text-white mb-2">{example.title}</p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">{example.prompt}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Recent Chats */}
            <div>
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-xl font-semibold dark:text-white flex items-center gap-2">
                        <span className="w-6 h-6 flex items-center justify-center bg-blue-100 dark:bg-blue-900 rounded-lg text-blue-500">
                            <BsChatLeft className="w-3 h-3" />
                        </span>
                        Recent chats
                    </h2>
                    {recentChats.length > 0 && (
                        <button className="text-sm text-blue-500 hover:text-blue-600 font-medium">
                            View all →
                        </button>
                    )}
                </div>
                <div className="space-y-3">
                    {loading ? (
                        <div className="animate-pulse space-y-3">
                            {[1, 2, 3].map((_, i) => (
                                <div key={i} className="h-20 bg-gray-100 dark:bg-gray-800 rounded-lg"></div>
                            ))}
                        </div>
                    ) : recentChats.length > 0 ? (
                        recentChats.map((chat) => (
                            <div
                                key={chat.id}
                                onClick={() => navigate(`/theo/chat/${chat.id}`)}
                                className="group p-4 rounded-lg border dark:border-gray-700 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors shadow-sm hover:shadow"
                            >
                                <div className="flex items-center gap-3">
                                    <div className="w-8 h-8 flex items-center justify-center bg-blue-100 dark:bg-blue-900 rounded-lg">
                                        <BsChatLeft className="w-4 h-4 text-blue-500" />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="font-medium dark:text-white truncate">{chat.title}</p>
                                        <div className="flex items-center gap-2">
                                            <p className="text-sm text-gray-500 truncate flex-1">{chat.lastMessage}</p>
                                            <p className="text-xs text-gray-400 whitespace-nowrap">
                                                {formatDistanceToNow(chat.timestamp.toDate(), { addSuffix: true })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                            No conversations yet. Start a new chat!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
} 