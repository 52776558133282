import React from 'react';

const ThinkingIndicator: React.FC = () => {
    return (
        <div className="flex items-center gap-2">
            <div className="w-8 h-8">
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/neoswap-production.appspot.com/o/tulle_images%2FFrame%207.png?alt=media&token=8293bd4e-4a8e-4dbd-ac4f-fdedf4cb1235"
                    alt="THEO thinking"
                    className="rounded-full w-full h-full object-contain"
                />
            </div>
            <div className="flex items-center gap-1">
                <div className="w-2 h-2 rounded-full bg-[#ffbe4d] animate-[bounce_1s_infinite]"></div>
                <div className="w-2 h-2 rounded-full bg-[#ffbe4d] animate-[bounce_1s_infinite_0.2s]"></div>
                <div className="w-2 h-2 rounded-full bg-[#ffbe4d] animate-[bounce_1s_infinite_0.4s]"></div>
            </div>
        </div>
    );
};

export default ThinkingIndicator; 