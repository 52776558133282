import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../navbar";
import { ReactNode } from "react";
import backgroundImage from '../../assets/bg-sa.png';
import { useTheme } from '../../contexts/ThemeContext';

export default function Layout() {
  const location = useLocation();
  const { isDarkMode } = useTheme();

  interface StyleMap {
    [key: string]: string;
  }

  const swooshStyles: StyleMap = {
    "/ai-trades/*": "opacity-5",
    default: "opacity-5",
  };

  const getSwooshStyle = (pathname: string): string => {
    if (swooshStyles[pathname]) return swooshStyles[pathname];
    for (const key in swooshStyles) {
      if (key.endsWith("/*") && pathname.startsWith(key.slice(0, -2))) {
        return swooshStyles[key];
      }
    }
    return swooshStyles.default;
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
      <div id="top-bar"></div>

      {window.location.pathname === '/star-atlas' ||
        window.location.pathname === '/genopets' ||
        window.location.pathname === '/star-atlas-packs' ? (
        <div
          style={{ backgroundImage: `url(${backgroundImage})` }}
          className={`fixed inset-0 -z-10 bg-no-repeat bg-cover bg-center ${getSwooshStyle(location.pathname)}`}
        />
      ) : (
        <div className="fixed inset-0 -z-10 pattern-bg dark:opacity-50" />
      )}

      <div className="container mx-auto">
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <main className="flex-grow px-4 page-spacing">
            <Outlet />
          </main>
          <footer className="py-4 text-gray-600 dark:text-gray-400">
            {/* Footer content */}
          </footer>
        </div>
      </div>
    </div>
  );
}

export function HomeLayout({ children }: { children: ReactNode }) {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 overflow-auto">
      <Navbar />
      <div className="page-spacing ">
        {children}
      </div>
    </div>
  );
}
