import { useTheme } from '../contexts/ThemeContext';
import { FiSun, FiMoon } from 'react-icons/fi';

export function ThemeToggle() {
    const { isDarkMode, toggleDarkMode } = useTheme();

    return (
        <button
            onClick={toggleDarkMode}
            className="p-2 rounded-lg hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover transition-colors"
            aria-label="Toggle theme"
        >
            {isDarkMode ? (
                <FiSun className="w-5 h-5 text-light-text dark:text-dark-text" />
            ) : (
                <FiMoon className="w-5 h-5 text-light-text dark:text-dark-text" />
            )}
        </button>
    );
} 