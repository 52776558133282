import { useCallback, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import { getAbbr, getTruncate, roundValue, trucPublicKey } from "../utils";
import { useChain } from "../contexts/chainsContext";
import { useUA } from "../contexts/userTracking";
import useNeoChain from "../hooks/useNeoChain";
import { Link } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { BiLogOut, BiRefresh } from "react-icons/bi";
import IconSolana from "./Icons/IconSolana";

export default function SignInButton({ version }: { version?: string }) {
  const { gaConnectWalletClick } = useUA();
  const { chain } = useChain();
  const chainFns = useNeoChain() as any;
  const { uid, publicKey, avatar, balance, activeChainBalance, tokenBalances, getTokenBalance, isFetchingBalance } = useAppContext();
  const { signIn, connecting, signOut } = chainFns?.[chain?.name?.toLowerCase() || "not_found"];
  const [copied, setCopied] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const abbr = getAbbr(tokenBalances, activeChainBalance);
  const truncate = getTruncate(tokenBalances, activeChainBalance);

  const copyAddress = useCallback(async () => {
    if (publicKey) {
      await navigator.clipboard.writeText(publicKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 400);
    }
  }, [publicKey]);

  const refreshBalance = async () => {
    if (uid) {
      await getTokenBalance(uid);
    }
  };

  if (uid)
    return (
      <div className="flex items-center gap-2">
        <div className="relative">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="flex items-center gap-3 px-4 py-2 rounded-lg bg-light-bg-secondary dark:bg-dark-bg-secondary 
                       hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover transition-all duration-200
                       border border-light-border dark:border-dark-border shadow-sm"
          >
            <img
              src={avatar}
              alt="Profile"
              className="w-8 h-8 rounded-full object-cover ring-2 ring-primary/20"
            />
            <div className="flex items-center gap-2 text-light-text dark:text-dark-text font-medium">
              <span className={`${isFetchingBalance ? 'opacity-50' : ''}`}>
                {isFetchingBalance ? "..." : roundValue(balance ? balance : 0, truncate)}
              </span>
              {chain?.name === "Solana" && <IconSolana className="w-4 h-4" />}
            </div>
          </button>

          {isMenuOpen && (
            <div className="absolute right-0 mt-2 w-56 bg-light-bg-secondary dark:bg-dark-bg-secondary rounded-xl 
                          shadow-lg border border-light-border dark:border-dark-border backdrop-blur-lg">
              <div className="p-3 border-b border-light-border dark:border-dark-border">
                <button
                  onClick={copyAddress}
                  className="w-full px-3 py-2 text-left rounded-lg hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                           flex items-center gap-2 text-light-text dark:text-dark-text"
                >
                  <FaCopy className="w-4 h-4 opacity-60" />
                  <span className="font-medium">{trucPublicKey(publicKey || "")}</span>
                  {copied && <span className="ml-auto text-sm text-primary">Copied!</span>}
                </button>
              </div>

              <div className="py-2">
                {[
                  { to: "/my-items", label: "My Items" },
                  { to: "/my-swaps", label: "My Swaps" },
                  { to: "/rooms", label: "My Rooms" },
                  { to: "/profile", label: "Edit Profile" },
                  { to: "/points", label: "My Points" },
                ].map((item) => (
                  <Link
                    key={item.to}
                    to={item.to}
                    className="block px-3 py-2 hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                             text-light-text dark:text-dark-text transition-colors"
                  >
                    {item.label}
                  </Link>
                ))}
              </div>

              <div className="p-2 border-t border-light-border dark:border-dark-border">
                <button
                  onClick={signOut}
                  className="w-full px-3 py-2 text-left text-red-500 hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                           rounded-lg flex items-center gap-2 transition-colors"
                >
                  <BiLogOut className="w-4 h-4" />
                  <span>Sign Out</span>
                </button>
              </div>
            </div>
          )}
        </div>

        <button
          onClick={refreshBalance}
          disabled={isFetchingBalance}
          className="p-2 rounded-lg hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                   transition-colors disabled:opacity-50"
        >
          <BiRefresh className={`w-5 h-5 text-light-text dark:text-dark-text ${isFetchingBalance ? 'animate-spin' : ''}`} />
        </button>
      </div>
    );

  return (
    <button
      disabled={connecting}
      onClick={() => {
        gaConnectWalletClick();
        signIn();
      }}
      className={`px-6 py-2 ${version === "connect-page" ? 'text-base' : 'text-sm'}
                 bg-primary hover:bg-primary-hover text-white rounded-lg
                 transition-all duration-200 disabled:opacity-50 font-medium
                 hover:shadow-md hover:scale-[1.02]`}
    >
      {connecting ? "Connecting..." : (version === "connect-page" ? "Connect Wallet" : "Connect")}
    </button>
  );
}
