import React, { useEffect, useState, useMemo } from 'react'
import usePackGroups from './hooks/usePackGroups'
import { Pack, PackGroup, SalePack } from './types/packs'

import { useToast, Select } from '@chakra-ui/react'

import PackGroupTabs, { CHATBOT_GROUP_ID, CRAFTING_GROUP_ID, HOW_IT_WORKS_GROUP_ID } from './components/PackGroupTabs'
import PackCard from './components/PackCard'
import PartnerLogo from './components/PartnerLogo'
import PartnerFooter from './components/PartnerFooter'
import BuyingModal from './components/BuyingModal/BuyingModal'
import { FaDiscord, FaTwitter } from 'react-icons/fa'

import TalkJSChatbox from './components/TalkJSChatbox'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// import required modules
import { Pagination } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { userAnalyticsClient } from './clients/userAnalyticsClient'
import { PacksClient } from './clients/packsClient'
import { CraftingHelper } from './components/StarAtlas/CraftingCalculator/CraftingHelper'
import HowItWorksCard from './components/StarAtlas/HowItWorks'

import CustomChat from './components/CustomChat/CustomChat'
import SellModal from './components/SellModal/SellModal'
import LoadingSpinner from '../ui/LoadingSpinner'

const HOW_IT_WORKS_PACK_GROUP: PackGroup = {
  id: HOW_IT_WORKS_GROUP_ID,
  name: "How it works",
  packs: [],
}

const CHATBOT_PACK_GROUP: PackGroup = {
  id: CHATBOT_GROUP_ID,
  name: 'THEO Chatbot',
  packs: [],
}

const CRAFTING_PACK_GROUP: PackGroup = {
  id: CRAFTING_GROUP_ID,
  name: 'Crafting Helper',
  packs: [],
}


interface PacksWidgetProps {
  partnerToken: string,
  loadDinamicCSS?: boolean,
  maintenance?: boolean
}

interface TheoProps {
  handleOpenModal: ({ pack, packId }: { pack?: Pack, packId?: string }) => void,
  handleOpenSellModal: (salePack: SalePack) => void,
  partner: string
}



const Theo: React.FC<TheoProps> = ({ handleOpenModal, handleOpenSellModal, partner }) => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full max-w-3xl">
        <CustomChat
          onOpenModal={handleOpenModal}
          onOpenSellModal={handleOpenSellModal}
          partner={partner}
        />
      </div>

      <div className="bg-yellow-50 dark:bg-yellow-900/20 rounded-lg p-3 max-w-[450px] mt-5">
        <div className="flex items-start space-x-2">
          <div className="flex-shrink-0">
            <svg className="h-4 w-4 text-yellow-600" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div>
            <p className="text-sm text-yellow-700 dark:text-yellow-300">
              THEO Chatbot is still in beta. If you encounter any problems, please use the feedback form on the left.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PacksWidget: React.FC<PacksWidgetProps> = ({
  partnerToken,
  loadDinamicCSS = true,
  maintenance = false
}) => {
  const packsClient = useMemo(() => {
    return new PacksClient({ jwtToken: partnerToken })
  }, [partnerToken])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [logoImageAlt, setLogoImageAlt] = useState('');
  const [logoSubtitle, setLogoSubtitle] = useState('');
  const [footerText, setFooterText] = useState('');
  const [footerSubText, setFooterSubText] = useState('');
  const [footerSocialTwitter, setFooterSocialTwitter] = useState('');
  const [footerSocialDiscord, setFooterSocialDiscord] = useState('');
  const [twitterMessageUrl, setTwitterMessageUrl] = useState('');
  const [twitterMessageText, setTwitterMessageText] = useState('');
  const [partner, setPartner] = useState<string>('staratlas');
  const [selectedSalePack, setSelectedSalePack] = useState<SalePack | undefined>(undefined)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    packsClient.fetchPartnerConfiguration().then((config) => {
      if (config.cssUrl && loadDinamicCSS) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = config.cssUrl;
        document.head.appendChild(link);
      }
      setLogoImageAlt(config.logoImageAlt || '');
      setLogoSubtitle(config.logoSubtitle || '');
      setFooterText(config.footerText || '');
      setFooterSubText(config.footerSubText || '');
      setFooterSocialTwitter(config.footerSocialTwitter || '');
      setFooterSocialDiscord(config.footerSocialDiscord || '');
      setTwitterMessageUrl(config.twitterMessageUrl || '');
      setTwitterMessageText(config.twitterMessageText || '');
      setPartner(config.partnerName);
      setIsConfigLoaded(true);

      userAnalyticsClient.packsLaunch(config.partnerName);
    });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Threshold at 1024px for mobile or tablet vs. larger screens
  const isMobileOrTablet = useMemo(() => {
    return windowWidth <= 1024;
  }, [windowWidth]);

  const toast = useToast()

  const {
    packGroups: fetchedPackGroups,
    isLoadingPackGroups,
    errorPackGroups,
    fetchPackGroups,
  } = usePackGroups(partnerToken)

  const packGroups = useMemo(() => {
    if (partner === "staratlas")
      return [HOW_IT_WORKS_PACK_GROUP, ...fetchedPackGroups, CRAFTING_PACK_GROUP, CHATBOT_PACK_GROUP]

    return [...fetchedPackGroups, CHATBOT_PACK_GROUP]

  }, [fetchedPackGroups])

  const [activePackGroupId, setActivePackGroupId] = useState<string>('')
  const [selectedPackId, setSelectedPackId] = useState<string | undefined>(undefined)
  const [selectedPack, setSelectedPack] = useState<Pack | undefined>(undefined)

  const handleOpenModal = ({ pack, packId }: { pack?: Pack; packId?: string }) => {
    if (pack) {
      setSelectedPackId(pack.id)
      setSelectedPack(pack)
    } else if (packId) {
      setSelectedPackId(packId)
      setSelectedPack(undefined)
    } else {
      console.error('No pack or packId provided')
    }
  }

  const handleCloseModal = () => {
    setSelectedPackId(undefined)
    setSelectedPack(undefined)
  }

  const handleOpenSellModal = (salePack: SalePack) => {
    setSelectedSalePack(salePack)
  }

  const handleCloseSellModal = () => {
    setSelectedSalePack(undefined)
  }

  // Show toast on error
  useEffect(() => {
    if (errorPackGroups) {
      toast({
        title: 'Error fetching packs',
        description: errorPackGroups.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [errorPackGroups, toast])

  useEffect(() => {
    fetchPackGroups()
  }, [fetchPackGroups])

  useEffect(() => {
    const chatbotGroup = packGroups.find(group => group.id === CHATBOT_GROUP_ID);
    const initialActiveTab = chatbotGroup ? CHATBOT_GROUP_ID : packGroups[0]?.id || '';
    setActivePackGroupId(initialActiveTab);
  }, [packGroups]);

  const activePackGroup = useMemo(() => {
    return packGroups.find((group) => group.id === activePackGroupId)
  }, [packGroups, activePackGroupId])

  return (
    <>
      <div className="packs-background"></div>
      <div className="packs-content mt-20">
        <PartnerLogo
          logoImageAlt={logoImageAlt}
          logoSubtitle={logoSubtitle}
        />
        {maintenance ? (
          <div className="mx-auto mt-10 mb-10 max-w-[450px] rounded bg-yellow-50/10 dark:bg-yellow-900/20 p-4 flex items-start space-x-3">
            <svg className="h-5 w-5 text-[#ffbe4d] mt-0.5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2h.01a1 1 0 100-2H9z" clipRule="evenodd" />
            </svg>
            <p className="text-sm text-[#ffbe4d]">
              We're currently making improvements! Please check back in a little while.
              <br />
              Thank you for your patience! 🚀
            </p>
          </div>
        ) : (
          <>
            <PackGroupTabs
              partner={partnerToken}
              packGroups={packGroups}
              onTabChange={setActivePackGroupId}
            />
            <div className="packs-container">
              {isLoadingPackGroups ? (
                <LoadingSpinner />
              ) : errorPackGroups ? (
                <div className="packs-error">Error: {errorPackGroups?.message}</div>
              ) : activePackGroupId == CRAFTING_GROUP_ID ? (
                <CraftingHelper partner={partner} onOpenModal={handleOpenModal} />
              ) : activePackGroupId == HOW_IT_WORKS_GROUP_ID ? (
                <HowItWorksCard />
              ) : activePackGroupId == CHATBOT_GROUP_ID ? (
                <Theo
                  handleOpenModal={handleOpenModal}
                  handleOpenSellModal={handleOpenSellModal}
                  partner={partner}
                />
              )
                : !isMobileOrTablet ? (
                  activePackGroup &&
                  activePackGroup.packs.map((pack) => (
                    <PackCard key={pack.id} pack={pack} onOpenModal={handleOpenModal} />
                  ))
                ) : (
                  <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                    {activePackGroup &&
                      activePackGroup.packs.map((pack) => (
                        <SwiperSlide>
                          <PackCard key={pack.id} pack={pack} onOpenModal={handleOpenModal} />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                )}
            </div>
          </>
        )}
        <PartnerFooter>
          <ul className="social-icons">
            <li>
              <a
                href={footerSocialTwitter}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter fontSize={"20px"} />
              </a>

              <a
                href={footerSocialDiscord}
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord fontSize={"20px"} />
              </a>
            </li>
          </ul>

          <div className="footer-section-sub-text">
            <p>{footerText}</p>
            <p>{footerSubText}</p>
          </div>
          <div className="footer-tulle-logo">
            <a href="https://tulle.ai" target="_blank" rel="noreferrer">
              <img
                src={require('./assets/tulle-flat-white.png')}
                alt="Tulle AI Logo"
                style={{ display: 'block', margin: '0 auto' }}
              />
            </a>
          </div>
        </PartnerFooter>
      </div>
      {(selectedPackId || selectedPack) && isConfigLoaded && (
        <BuyingModal
          packId={selectedPackId}
          packDetails={selectedPack}
          isOpen={Boolean(selectedPackId) || Boolean(selectedPack)}
          onClose={handleCloseModal}
          partnerToken={partnerToken}
          twitterMessageUrl={twitterMessageUrl}
          twitterMessageText={twitterMessageText}
        />
      )}
      {selectedSalePack && (
        <SellModal
          isOpen={Boolean(selectedSalePack)}
          onClose={handleCloseSellModal}
          salePack={selectedSalePack}
          partnerToken={partnerToken}
        />
      )}
    </>
  )
}

export default PacksWidget
